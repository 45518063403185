import { Range } from "@onpreo/components";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialReduxState } from "../models";
import { TasksArray } from "../models/task.state";

export const taskSlice = createSlice({
    name: "task",
    initialState: initialReduxState.task,
    reducers: {
        setTask: (state, action: PayloadAction<TasksArray>) => {
            // @ts-ignore
            state.tasks = action.payload;
            return state;
        },
        setType: (state, action: PayloadAction<(typeof initialReduxState.task)["type"]>) => void (state.type = action.payload),
        setTotal: (state, action: PayloadAction<(typeof initialReduxState.task)["total"]>) => void (state.total = action.payload),
        setTasksByRange: (state, action: PayloadAction<{ range: Range; tasksByRange: TasksArray }>) => {
            // we need to sort in this users according to the range
            const [from, to] = action.payload.range;
            // make sure state.contacts is at least **to** long
            if (state.tasksByRange.length < to) {
                state.tasksByRange = state.tasksByRange.concat(Array(to - state.tasksByRange.length).fill(undefined));
            }
            // and then splice the new values into it
            // @ts-ignore
            state.tasksByRange.splice(from, to - from, ...action.payload.tasksByRange);
            // and set the active range
            state.activeRange = action.payload.range;
            state.type = "loaded";
        },
        setActiveSort: (state, action) => {
            state.activeSort = action.payload;
        }
    }
});

export const { setTask, setType, setTotal, setTasksByRange, setActiveSort } = taskSlice.actions;

export default taskSlice.reducer;
