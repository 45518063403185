import { createApi } from "@reduxjs/toolkit/query/react";
import { onpreoClientBaseQuery } from "../utils";
import { setActivityById } from "./activities.slice";
import { setRealEstateById, setCurrentRealEstate } from "./real-estate.slice";

const activityApi = createApi({
    reducerPath: "activity",
    baseQuery: onpreoClientBaseQuery({ baseUrl: `/api/activity/` }),
    tagTypes: ["Done", "NotDone", "note", "email", "document", "activity"],
    endpoints: build => ({
        getDoneActivities: build.query({
            query: ({ id, start, end, type, variant }) =>
                id
                    ? {
                          url: `${id}/is-done?start=${start}&end=${end}&type=${type}${variant ? `&variant=${variant}` : ""}`,
                          method: "GET"
                      }
                    : undefined,
            providesTags: result => (result?.type === "all" ? ["Done"] : [result?.type ?? "Done"])
        }),
        getTotal: build.query({
            query: ({ id, type, variant }) => ({
                url: `${id}/is-done/total?type=${type}${variant ? `&variant=${variant}` : ""}`,
                method: "GET"
            }),
            providesTags: result => (result?.type === "all" ? ["Done"] : [result?.type ?? "Done"])
        }),
        getNotDoneActivities: build.query({
            query: ({ id, variant }) => ({
                url: `${id}/not-done${variant ? `?variant=${variant}` : ""}`,
                method: "GET"
            }),
            providesTags: ["NotDone"]
        }),
        setActivityStatus: build.mutation({
            query: ({ id, activityId }) => ({
                url: `${id}/${activityId}/is-done`,
                method: "PATCH"
            }),
            async onQueryStarted({ id, activityId }, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    await dispatch(setActivityById({ id: activityId, value: data.currentActivity }));
                } catch {
                    console.log("error");
                }
            },
            invalidatesTags: result =>
                result.currentActivity.activityType === "call" ||
                result.currentActivity.activityType === "meeting" ||
                result.currentActivity.activityType === "task"
                    ? ["Done", "NotDone", "activity"]
                    : ["Done", "NotDone", result.currentActivity.activityType]
        }),
        addActivity: build.mutation({
            query: activity => ({
                url: `${
                    activity.variant === "networkPartner" ||
                    activity.variant === "prospectiveBuyer" ||
                    activity.variant === "lead" ||
                    activity.variant === "contact"
                        ? activity.activityFields.owner
                        : activity.realEstate
                }`,
                method: "POST",
                data: activity
            }),
            invalidatesTags: ({ newActivity }) =>
                newActivity.activityType === "email" || newActivity.activityType === "document" || newActivity.activityType === "note"
                    ? ["Done", newActivity.activityType]
                    : newActivity.isDone
                    ? ["Done", "activity"]
                    : ["NotDone"]
        }),
        createActivityOrUpdateDate: build.mutation({
            query: activity => ({
                url:
                    activity.variant === "networkPartner" ||
                    activity.variant === "prospectiveBuyer" ||
                    activity.variant === "lead" ||
                    activity.variant === "contact"
                        ? `${activity.activityFields.owner}/date`
                        : `${activity.realEstate}/date`,
                method: "PUT",
                data: activity
            }),
            invalidatesTags: result =>
                result.activity.activityType === "call" || result.activity.activityType === "meeting" || result.activity.activityType === "task"
                    ? ["Done", "NotDone", "activity"]
                    : ["Done", "NotDone", result.activity.activityType]
        }),
        updateActivity: build.mutation({
            query: ({ id, activityId, activity }) => ({
                url: `${id}/${activityId}`,
                method: "PUT",
                data: activity
            }),
            async onQueryStarted({ activityId }, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    await dispatch(setActivityById({ id: activityId, value: data.activity }));
                } catch {
                    console.log("error");
                }
            },
            invalidatesTags: ["NotDone"]
        }),
        deleteActivity: build.mutation({
            query: ({ id, activityId }) => ({
                url: `${id}/${activityId}`,
                method: "DELETE"
            }),
            invalidatesTags: result =>
                result.wasStatus
                    ? ["Done", result.wasType === "call" || result.wasType === "meeting" || result.wasType === "task" ? "activity" : result.wasType]
                    : ["NotDone"]
        }),
        createActivitiesByPipelineStep: build.mutation({
            query: ({ step, realEstateId }) => ({
                url: `${realEstateId}/by-step`,
                method: "POST",
                data: { step, realEstateId }
            }),
            invalidatesTags: ["Done", "NotDone", "activity", "note"]
        }),
        createActivitiesByNetworkStep: build.mutation({
            query: ({ step, contactId, variant }) => ({
                url: `${contactId}/by-network-step`,
                method: "POST",
                data: { step, contactId, variant }
            }),
            invalidatesTags: ["Done", "NotDone", "activity", "note"]
        })
    })
});

export const {
    useGetDoneActivitiesQuery,
    useGetTotalQuery,
    useGetNotDoneActivitiesQuery,
    useSetActivityStatusMutation,
    useAddActivityMutation,
    useCreateActivityOrUpdateDateMutation,
    useUpdateActivityMutation,
    useDeleteActivityMutation,
    useCreateActivitiesByPipelineStepMutation,
    useCreateActivitiesByNetworkStepMutation
} = activityApi;
export default activityApi;
