import { TaskDocument } from "@onpreo/database";
import { Range } from "@onpreo/components";

export type TasksArray = TaskDocument[];

export type TaskState = {
    tasks: TasksArray;
    tasksByRange: TasksArray;
    type: "loaded" | "loading" | "error";
    total: Number;
    activeRange: any;
    activeSort: any;
};
export const initialTaskState = {
    tasks: [] as TasksArray,
    tasksByRange: [] as TasksArray,
    type: "loading" as "loaded" | "loading" | "error",
    total: -1,
    activeRange: undefined as Range | undefined,
    activeSort: null
};
