import { useEffect, useRef } from "react";
import { hotjar } from "react-hotjar";
import { useReduxSelector } from "../../store/selectors";
import { getUser, getUserId } from "../../store/selectors/user.selectors";
import { NODE_ENV } from "../../utils/secrets";
import { getWorkspaceBilling, getWorkspaceId } from "../../store/selectors/workspace.selectors";

// hotjar snippet version
const hjsv = 6;
// hotjar id
const hjid = 3854569;

const HotjarHelper = () => {
    const userId = useReduxSelector(getUserId);
    const workspaceId = useReduxSelector(getWorkspaceId);
    const user = useReduxSelector(getUser);
    const billing = useReduxSelector(getWorkspaceBilling);
    const hjInit = useRef(false);

    useEffect(() => {
        if (typeof window !== "undefined" && NODE_ENV !== "development" && !hjInit.current) {
            hotjar.initialize(hjid, hjsv);

            if (userId) {
                hotjar.identify(userId, { email: user.email, company: billing.company, workspaceId: workspaceId });
            }

            hjInit.current = true;
        }

        return () => {
            hjInit.current = false;
        };
    }, []);

    return <div id="hotjar-helper" />;
};

export default HotjarHelper;
