import { createApi } from "@reduxjs/toolkit/query/react";
import { onpreoClientBaseQuery } from "../utils";
import { onpreoClient } from "@onpreo/upsy-daisy/client";
const baseUrl = `/api/sales-statistics`;

export const salesStatisticsApi = createApi({
    reducerPath: "salesStatistics",
    baseQuery: onpreoClientBaseQuery({ baseUrl }),
    tagTypes: [""],
    endpoints: builder => ({
        getStatisticsByPipelineSteps: builder.query({
            queryFn: async ({ searchQuery }) => {
                try {
                    const result = await onpreoClient.post(`${baseUrl}/by-pipeline`, { searchQuery });
                    return { data: result.data };
                } catch (error) {
                    return { error };
                }
            }
        }),
        getStatisticsForFunnel: builder.query({
            queryFn: async ({ pipeline, searchQuery }) => {
                try {
                    const result = await onpreoClient.post(`${baseUrl}`, { pipeline, searchQuery });
                    return { data: result.data };
                } catch (error) {
                    return { error };
                }
            }
        }),
        getStatisticsByStep: builder.query({
            queryFn: async ({ searchQuery, isLost }) => {
                try {
                    const result = await onpreoClient.post(`${baseUrl}/by-step`, { searchQuery, isLost });
                    return { data: result.data };
                } catch (error) {
                    return { error };
                }
            }
        }),
        getSpecificLeadStatistics: builder.query({
            queryFn: async ({ realEstateIds }) => {
                try {
                    const result = await onpreoClient.post(`${baseUrl}/by-leads`, { realEstateIds });
                    return { data: result.data };
                } catch (error) {
                    return { error };
                }
            }
        })
    })
});

export const { useGetStatisticsByPipelineStepsQuery, useGetStatisticsForFunnelQuery, useGetStatisticsByStepQuery, useGetSpecificLeadStatisticsQuery } =
    salesStatisticsApi;

export default salesStatisticsApi;
