import { Location, LocationDocument, UserDocument } from "@onpreo/database";
import { createApi } from "@reduxjs/toolkit/query/react";
import { onpreoClientBaseQuery } from "../utils";
import { addLocation } from "./locations.slice";
import { setWorkspace } from "./workspace.slice";

export const locationApi = createApi({
    reducerPath: "location",
    baseQuery: onpreoClientBaseQuery({ baseUrl: `/api/location/` }),
    tagTypes: ["Location", "User"],
    endpoints: builder => ({
        getLocations: builder.query<LocationDocument[], void>({
            query: () => ({ url: "", method: "GET" }),
            providesTags: ["Location"]
        }),
        createLocation: builder.mutation<any, Omit<Location, "workspace">>({
            query: location => ({ url: "", method: "POST", data: location }),
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    await dispatch(addLocation(data.location));
                } catch {
                    console.log("error");
                }
            },
            invalidatesTags: ["Location"]
        }),
        patchLocation: builder.mutation<void, Partial<Location> & { _id: string }>({
            query: ({ _id, ...location }) => ({ url: _id + "/", method: "PATCH", data: location }),
            invalidatesTags: ["Location"]
        }),
        deleteLocation: builder.mutation<void, Partial<Location> & { _id: string }>({
            query: ({ _id, ...location }) => ({ url: "/", method: "DELETE", data: { _id } }),
            invalidatesTags: ["Location"]
        }),
        getLocationUsers: builder.query<UserDocument[], string>({
            query: loc => ({ url: `${loc}/user`, method: "GET" }),
            providesTags: (result, _, arg) => [{ type: "User", id: arg }]
        }),
        addLocationUser: builder.mutation<any, { location: string; userId: string }>({
            query: ({ location, userId }) => ({ url: `${location}/user`, method: "POST", data: { userId } }),
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    await dispatch(setWorkspace(data.workspace));
                } catch {
                    console.log("error");
                }
            },
            invalidatesTags: (result, _, arg) => ["User"]
        }),
        updateUserLocations: builder.mutation<any, { location?: string; userId: string; additionalLocations?: string[] }>({
            query: ({ userId, location, additionalLocations }) => ({ url: `user`, method: "PATCH", data: { userId, location, additionalLocations } }),
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    await dispatch(setWorkspace(data.workspace));
                } catch {
                    console.log("error");
                }
            },
            invalidatesTags: (result, _, arg) => ["User"]
        }),

        deleteLocationUser: builder.mutation<any, { location: string; userId: string }>({
            query: ({ location, userId }) => ({ url: `${location}/user`, method: "DELETE", data: { userId } }),
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    await dispatch(setWorkspace(data.workspace));
                } catch {
                    console.log("error");
                }
            },
            invalidatesTags: (result, _, arg) => [{ type: "User", id: arg.location }]
        })
    })
});

export const {
    useGetLocationsQuery,
    useCreateLocationMutation,
    usePatchLocationMutation,
    useGetLocationUsersQuery,
    useAddLocationUserMutation,
    useUpdateUserLocationsMutation,
    useDeleteLocationUserMutation,
    useDeleteLocationMutation
} = locationApi;
