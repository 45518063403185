import { ContactDocument } from "@onpreo/database";
import { Range } from "@onpreo/components";

export type ContactsArray = ContactDocument[];

export type ContactState = {
    contactsByRange: ContactsArray;
    networkPartners: ContactsArray;
    type: "loaded" | "loading" | "error";
    total: Number;
    activeRange: any;
    activeSort: any;
    currentContact: ContactDocument;
    activeQuery: {
        emailValue?: string;
        newsletterTypes?: string[];
        withOpenTasks?: boolean;
        withoutOpenTasks?: boolean;
        start?: string;
        end?: string;
        activeBuyerFilter?: boolean;
        buyerPreferencesQuery?: {
            categories?: string[];
            livingArea?: number;
            investment?: number;
            potentialBuying?: string[];
            equipment?: string[];
        };
    };
    contactsBySteps: any;
    totalByStep: any;
    loadingByStep: any;
};
export const initialContactState = {
    contactsByRange: [] as ContactsArray,
    networkPartners: [] as ContactsArray,
    type: "loading" as "loaded" | "loading" | "error",
    total: -1,
    activeRange: undefined as Range | undefined,
    activeSort: null,
    currentContact: null,
    activeQuery: {
        emailValue: "",
        newsletterTypes: [],
        withOpenTasks: true,
        withoutOpenTasks: true,
        start: "",
        end: "",
        activeBuyerFilter: false,
        buyerPreferencesQuery: {
            categories: undefined,
            livingArea: undefined,
            investment: undefined,
            potentialBuying: [],
            equipment: []
        }
    },
    contactsBySteps: {},
    totalByStep: {},
    loadingByStep: {}
};
