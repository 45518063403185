import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ContactsListDocument } from "@onpreo/database";
import { initialReduxState } from "../models";

export const automationSlice = createSlice({
    name: "automation",
    initialState: initialReduxState.automation,
    reducers: {
        // contacts lists
        setContactsLists: (state, action: PayloadAction<typeof initialReduxState.automation["contactsLists"]>) => {
            state.contactsLists = action.payload as [];
        },
        setContactsListById: (state, action: PayloadAction<{ id: string; updated: ContactsListDocument }>) => {
            state.contactsLists = state.contactsLists.map(list => (list._id === action.payload.id ? action.payload.updated : list)) as [];
        },
        setNewContactsList: (state, action) => {
            state.contactsLists = [...state.contactsLists, action.payload];
        },
        filterContactsList: (state, action) => {
            state.contactsLists = state.contactsLists.filter(list => list._id !== action.payload);
        },
        // automation templates
        setAutomationTemplates: (state, action: PayloadAction<typeof initialReduxState.automation["automationTemplates"]>) => {
            state.automationTemplates = action.payload as [];
        },
        setNewAutomationTemplate: (state, action) => {
            state.automationTemplates = [...state.automationTemplates, action.payload];
        },
        filterAutomationTemplates: (state, action) => {
            state.automationTemplates = state.automationTemplates.filter(t => t._id !== action.payload);
        },
        setAutomationTemplateById: (state, action: PayloadAction<{ id: string; template: any }>) => {
            state.automationTemplates = state.automationTemplates.map(t => (t?._id === action.payload.id ? (t = { ...t, ...action.payload.template }) : t));
        },
        // automation workflows
        setAutomationWorkflows: (state, action: PayloadAction<typeof initialReduxState.automation["automationWorkflows"]>) => {
            state.automationWorkflows = action.payload as [];
        },
        setNewAutomationWorkflow: (state, action) => {
            state.automationWorkflows = [...state.automationWorkflows, action.payload];
        },
        setUpdatedAutomationWorkflow: (state, action: PayloadAction<{ id: string; workflow: any }>) => {
            state.automationWorkflows = state.automationWorkflows.map(w => (w._id === action.payload.id ? action.payload.workflow : w));
        },
        filterAutomationWorkflows: (state, action) => {
            state.automationWorkflows = state.automationWorkflows.filter(w => w._id !== action.payload);
        },
        // automation
        setAutomations: (state, action: PayloadAction<typeof initialReduxState.automation["automations"]>) => {
            state.automations = action.payload as [];
        },
        setNewAutomation: (state, action) => {
            state.automations = [...state.automations, { ...action.payload, status: "draft" }];
        },
        setUpdatedAutomation: (state, action: PayloadAction<{ id: string; automation: any }>) => {
            state.automations = state.automations.map(a => (a._id === action.payload.id ? { ...action.payload.automation, status: "draft" } : a));
        },
        filterAutomation: (state, action) => {
            state.automations = state.automations.filter(a => a._id !== action.payload);
        },
        setNewCampaign: (state, action) => {
            state.automations = state.automations.map(a =>
                a._id === action.payload.automation
                    ? { ...a, listId: action.payload.contactList, status: action.payload.status === "running" ? "active" : "archive" }
                    : a
            );
        },
        updateStatus: (state, action) => {
            state.automations = state.automations.map(a =>
                a._id === action.payload.automation
                    ? {
                          ...a,
                          status: action.payload.status === "running" ? "active" : action.payload.status === "archived" ? "archive" : "draft"
                      }
                    : a
            );
        }
    }
});

export const {
    setContactsLists,
    setContactsListById,
    setNewContactsList,
    filterContactsList,
    setAutomationTemplates,
    setNewAutomationTemplate,
    filterAutomationTemplates,
    setAutomationTemplateById,
    setAutomationWorkflows,
    setNewAutomationWorkflow,
    setUpdatedAutomationWorkflow,
    filterAutomationWorkflows,
    setAutomations,
    setNewAutomation,
    setUpdatedAutomation,
    filterAutomation,
    setNewCampaign,
    updateStatus
} = automationSlice.actions;
export default automationSlice.reducer;
