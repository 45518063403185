import ReduxState from "../models";
import { createSelector } from "@reduxjs/toolkit";
import subscriptionLimits from "../../utils/subscription-limits/limits";
import { priceToLicense } from "../../api/util/stripe/stripe-secrets";

export const getWorkspace = (state: ReduxState) => state.workspace;

export const getWorkspaceId = createSelector(getWorkspace, workspace => (workspace as any)._id);
export const getWorkspaceBilling = createSelector(getWorkspace, workspace => workspace.billing);
export const getWorkspaceBillingCustomer = createSelector(getWorkspaceBilling, billing => billing?.customerId);
export const getWorkspaceBillingPlan = createSelector(getWorkspaceBilling, billing => billing?.plan);
export const getWorkspaceBillingPrice = createSelector(getWorkspaceBilling, billing => billing?.price);
export const getWorkspaceBillingOffers = createSelector(getWorkspaceBilling, billing => billing?.offers);
export const getWorkspaceBillingCoupon = createSelector(getWorkspaceBilling, billing => billing?.coupon);
export const getWorkspaceBillingSubState = createSelector(getWorkspaceBilling, billing => billing?.subscriptionState);
export const getWorkspacePlan = createSelector(getWorkspaceBilling, billing => billing.subscriptionInfo?.planTitle);
export const getWorkspaceBillingDefaultPaymentMethod = createSelector(getWorkspaceBilling, billing => billing?.defaultPaymentMethod);
export const getWorkspaceBillingSubscriptionState = createSelector(getWorkspaceBilling, billing => billing?.subscriptionState);

export const getWorkspaceFeatureLimitReLp = createSelector(
    getWorkspaceBillingPrice,
    price => subscriptionLimits.numRealEstateLandingPages[priceToLicense[price]] ?? 0
);

export const getWorkspaceFeatureLimit = createSelector(getWorkspace, workspace => workspace.featureLimitExtension);
export const getWorkspaceFeatureLimitValuations = createSelector(getWorkspaceFeatureLimit, lim => lim?.numValuationsPerMonth ?? 0);

export const getWorkspaceIntegrations = createSelector(getWorkspace, workspace => workspace?.integrations || undefined);
export const getWorkspacePreferences = createSelector(getWorkspace, workspace => workspace.customerPreferences?.preferences);

export const getWorkspaceImmoScout = createSelector(getWorkspaceIntegrations, i => i?.immoScout);
export const getWorkspaceImmoWelt = createSelector(getWorkspaceIntegrations, i => i?.immoWelt);

export const getIsImmoScoutConnected = createSelector(getWorkspaceImmoScout, i => i?.type === "connected");
export const getIsImmoWeltConnected = createSelector(getWorkspaceImmoWelt, i => i?.type === "connected");

export const getWorkspaceLeadGenerators = createSelector(getWorkspaceIntegrations, integrations => integrations?.leadGenerator || {});
export const getWorkspaceLeadGeneratorList = createSelector(getWorkspaceIntegrations, integrations =>
    Object.keys(integrations?.leadGenerator || {}).map(key => integrations.leadGenerator[key])
);

export const getAgentSettings = createSelector(getWorkspace, workspace => workspace.agentSettings);
export const getAgentImage = createSelector(getWorkspace, settings => settings.agentImage);
export const getWorkspaceCompanyInfo = createSelector(getWorkspace, settings => settings.companyInfo);
export const getCompanyTermsOfUseImage = createSelector(getWorkspaceCompanyInfo, info => info?.termsOfUse);
export const getTermsProspectiveBuyer = createSelector(getWorkspaceCompanyInfo, info => info?.termsProspectiveBuyer);
export const getTermsDeal = createSelector(getWorkspaceCompanyInfo, info => info?.termsDeal);
export const getCommissionProtection = createSelector(getWorkspaceCompanyInfo, info => info?.commissionProtection);
export const getWorkspaceContactInfo = createSelector(getWorkspace, settings => settings.contactInfo);
export const getWorkspacePublication = createSelector(getWorkspace, settings => settings.publication);
export const getWorkspaceAddons = createSelector(getWorkspace, settings => settings.addons);
export const getWorkspaceContents = createSelector(getWorkspace, workspace => workspace?.contents);
export const getProvisionRate = createSelector(getAgentSettings, settings => settings?.provisionRate);

export const getWorkspaceLandingPage = createSelector(getWorkspace, settings => settings.landingPageInfo);
export const getWorkspaceLandingPageProofLogos = createSelector(getWorkspaceLandingPage, lp => lp.proofLogos);
export const getWorkspaceLandingPageRealEstates = createSelector(getWorkspaceLandingPage, lp => lp.realEstates);
export const getWorkspacePublicationImages = createSelector(getWorkspacePublication, pp => pp.images);

export const getWorkspaceTeamUsers = createSelector(getWorkspace, workspace => workspace.team ?? []);
export const getWorkspaceActiveUsersAmount = createSelector(getWorkspace, workspace => workspace.team?.filter(user => !!user.isActivated).length ?? 0);

export const getLogoImage = createSelector(getWorkspace, workspace => workspace.extras?.logo?.src);
export const getWorkSpaceExtras = createSelector(getWorkspace, workspace => workspace.extras);
export const getWorkSpaceExtrasDangerZonePreferences = createSelector(getWorkspace, workspace => workspace.extras?.dangerZonePreferences);

export const getAdminUserInfo = createSelector(getWorkspace, w => w.adminUser);
export const getAdminUserPhoneNumber = createSelector(getAdminUserInfo, ad => ad?.meta?.phone);
export const getAdminUserGender = createSelector(getAdminUserInfo, ad => ad?.gender);
export const getAdminUserName = createSelector(getAdminUserInfo, ad => ad?.meta?.name);
export const getSalutation = createSelector(getWorkspacePublication, publication => publication?.secondary);
export const getHasFixedProvision = createSelector(getAgentSettings, settings => settings?.hasFixedProvision);
export const getFixedProvision = createSelector(getAgentSettings, settings => settings?.fixedProvision);
export const getPowerOfAttorney = createSelector(getWorkspaceCompanyInfo, info => info?.powerOfAttorney);
export const getCancellationPolicy = createSelector(getWorkspaceCompanyInfo, info => info?.cancellationPolicy);
export const getBackground = createSelector(getWorkSpaceExtras, extras => extras?.background);
export const getMailsTemplates = createSelector(getWorkspace, workspace => workspace.mailsTemplates);
export const getFilteredTags = createSelector(getWorkspace, workspace => workspace?.extras?.filteredTags);
export const getIndividualTags = createSelector(getWorkspace, workspace => workspace?.extras?.individualTags);

export const getWorkspaceValuationsMonth = createSelector(getWorkspace, workspace => workspace?.valuation?.count ?? 0);
export const getAdminUserCountry = createSelector(getWorkspace, w => w?.billing?.address?.nation);

export const getWorkspaceDomain = createSelector(getWorkspace, w => w?.integrations?.domain);

export const getWorkspaceInvitationSelected = createSelector(getWorkspace, workspace => workspace?.invitationSelected);

export const getImageLibrary = createSelector(getWorkspace, w => w.imageLibrary);
export const getLocationDrafts = createSelector(getWorkspace, w => w.drafts?.locations);
export const getMailCenter = createSelector(getWorkspace, w => w?.mailCenter);
export const getActivityCenter = createSelector(getWorkspace, w => w?.activityCenter);
export const getPipelineCenter = createSelector(getWorkspace, w => w?.pipelineCenter);

export const hasPropstackKey = createSelector(getWorkspaceIntegrations, i => i?.propstack?.hasKey);

export const getGlobalSegmentationMailsActivation = createSelector(getWorkspace, workspace => workspace.globalSegmentationMailsActivated);
export const getMMCSettings = createSelector(getWorkspace, w => w?.mmcSettings);
export const hasAreaButlerKey = createSelector(getWorkspaceIntegrations, i => i?.areaButler?.hasKey);
