import { useSession } from "next-auth/react";
import { Fragment, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useRouter } from "next/router";
import { useReduxSelector } from "../../store/selectors";
import { getUser, getUserId } from "../../store/selectors/user.selectors";
import { $fetchUserById } from "../../store/thunks/user.thunk";
import { $fetchUserWorkspaceById } from "../../store/thunks/workspace.thunk";
import { $fetchAppointments } from "../../store/thunks/appointment.thunk";
import { $fetchPriceAssistants } from "../../store/thunks/price-assistant.thunk";
import { getWorkspaceBillingSubscriptionState } from "../../store/selectors/workspace.selectors";
import { $loadLocations } from "src/store/thunks/locations.thunk";
import { $getWorkspacePipelines } from "src/store/thunks/pipeline.thunk";
import { SubscriptionState } from "@onpreo/database/src/interfaces/stripe";

const SessionChecker = () => {
    const { data: session, status } = useSession();
    const userId = useReduxSelector(getUserId);
    // const workspace = useReduxSelector(getWorkspace);
    const user = useReduxSelector(getUser);
    const subState: SubscriptionState = useReduxSelector(getWorkspaceBillingSubscriptionState);
    const dispatch = useDispatch();
    const router = useRouter();

    useEffect(() => {
        if (status === "authenticated" && !userId && !!session?.user?.name) {
            (async () => {
                await Promise.all([
                    dispatch($fetchUserById(session.user.name)),
                    dispatch($fetchUserWorkspaceById(session.user.name)),
                    dispatch($fetchPriceAssistants(dispatch)),
                    dispatch($fetchAppointments(dispatch)),
                    dispatch($loadLocations()),
                    dispatch($getWorkspacePipelines())
                ]);
            })();
        }
    }, [session?.user, userId, status, dispatch]);

    useEffect(() => {
        console.log("Session and status log");
        console.log(session, status);
        if (status === "unauthenticated") {
            (async () => await router.push("/login"))();
        } else if (status === "authenticated") {
            const isUser = user.email === session.user.email;
            if (isUser && user.isPaused) {
                (async () => await router.push("/paused"))();
            }

            if (isUser && subState === SubscriptionState.UNPAID) {
                (async () => await router.push("/unpaid"))();
            }

            if (isUser && !user.isOnboarded && router.route !== "/onboard" && router.route !== "/onboarded") {
                (async () => await router.push("/onboard"))();
            } else if (isUser && user.isOnboarded && router.route === "/onboard") {
                (async () => await router.push("/profile"))();
            }
        }
    }, [status, user.isOnboarded, router.route, session.user.email, user.email]);

    return <Fragment />;
};

export default SessionChecker;
