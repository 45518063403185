import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialReduxState } from "../models";
import { NoteState } from "../models/note.state";

export const noteSlice = createSlice({
    name: "note",
    initialState: initialReduxState.note,
    reducers: {
        setNote: (state, action: PayloadAction<NoteState>) => {
            // @ts-ignore
            state = action.payload;
            return state;
        }
    }
});

export const { setNote } = noteSlice.actions;

export default noteSlice.reducer;
