import { Range } from "@onpreo/components";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialReduxState } from "../models";
import { RealEstateArray } from "../models/real-estate.state";
import { RealEstateDocument } from "@onpreo/database";

export const realEstateSlice = createSlice({
    name: "realEstate",
    initialState: initialReduxState.realEstate,
    reducers: {
        setType: (state, action: PayloadAction<(typeof initialReduxState.realEstate)["type"]>) => void (state.type = action.payload),
        setTotal: (state, action: PayloadAction<(typeof initialReduxState.realEstate)["total"]>) => void (state.total = action.payload),
        setTotalLandingPages: (state, action: PayloadAction<typeof initialReduxState.realEstate.totalLandingPages>) =>
            void (state.totalLandingPages = action.payload),
        setRealEstateByRange: (state, action: PayloadAction<{ range: Range; realEstateByRange: RealEstateArray }>) => {
            // we need to sort in this users according to the range
            const [from, to] = action.payload.range;
            // make sure state.users is at least **to** long
            if (state.realEstateByRange.length < to) {
                state.realEstateByRange = state.realEstateByRange.concat(Array(to - state.realEstateByRange.length).fill(undefined));
            }
            // and then splice the new values into it
            // @ts-ignore
            state.realEstateByRange.splice(from, to - from, ...action.payload.realEstateByRange);
            // and set the active range
            state.activeRange = action.payload.range;
            state.type = "loaded";
        },
        setRealEstateById: (state, action: PayloadAction<{ id: number | string; value: any }>) => {
            // NOTE: there was ...the_old_one in here, but this breaks when removing a top level field (like integrations)
            // and i am not seeing the benefit of having the old one here
            const newRe = { ...action.payload.value };

            state.realEstateByRange = state.realEstateByRange.map(re => (re._id === action.payload.id ? (re = newRe) : re));
        },
        setActiveSort: (state, action: PayloadAction<typeof initialReduxState.realEstate.activeSort>) => {
            state.activeSort = action.payload;
        },
        setActiveQuery: (state, action: PayloadAction<typeof initialReduxState.realEstate.activeQuery>) => {
            state.activeQuery = { ...state.activeQuery, ...action.payload };
        },
        setCurrentRealEstate: (state, action: PayloadAction<typeof initialReduxState.realEstate.currentRealEstate>) => {
            // @ts-expect-error
            state.currentRealEstate = action.payload;
        },
        setCurrentRealEstateLp: (state, action: PayloadAction<typeof initialReduxState.realEstate.currentRealEstateLp>) => {
            // @ts-expect-error
            state.currentRealEstateLp = action.payload;
        },
        setRealEstateQueryToken: (state, action: PayloadAction<typeof initialReduxState.realEstate.tkn>) => {
            state.tkn = action.payload;
        },
        setWatermarkUpdate: (state, action: PayloadAction<typeof initialReduxState.realEstate.updateAllWatermarks>) => {
            state.updateAllWatermarks = action.payload;
        },
        setPublishedLP: (state, action: PayloadAction<typeof initialReduxState.realEstate.publishedLP>) => {
            // @ts-expect-error
            state.publishedLP = action.payload;
        },
        setREBySteps: (state, action: PayloadAction<{ step?: string; data: any }>) => {
            const { step, data } = action.payload;

            if (step) {
                state.realEstatesBySteps[step] = state.realEstatesBySteps[step] ? [...state.realEstatesBySteps[step], ...data] : data;
            } else {
                state.realEstatesBySteps = data;
            }
        },
        reorderREInStep: (state, action: PayloadAction<{ step: string; data: any }>) => {
            state.realEstatesBySteps[action.payload.step] = action.payload.data;
            state.totalByStep[action.payload.step] = action.payload.data.length;
        },
        updateREInStep: (state, action: PayloadAction<{ step: string; id: number | string; value: any }>) => {
            const newRe = { ...action.payload.value };
            state.realEstatesBySteps[action.payload.step] = state.realEstatesBySteps[action.payload.step].map(re =>
                re._id === action.payload.id ? (re = newRe) : re
            );
        },
        setTotalByStep: (state, action) => {
            state.totalByStep[action.payload.step] = action.payload.total;
        },
        setStatisticByStep: (state, action) => {
            state.statisticByStep[action.payload.step] = action.payload.stepProvision;
        },
        setLoadingByStep: (state, action) => {
            state.loadingByStep[action.payload.step] = action.payload.loading;
        },
        setPartnerTags: (state, action) => {
            state.partnerTags = action.payload;
        },
        setRealEstateChanges: (state, action: PayloadAction<typeof initialReduxState.realEstate.realEstateChanges>) => {
            // @ts-expect-error
            state.realEstateChanges = action.payload;
        }
    }
});

export const {
    setType,
    setTotal,
    setTotalLandingPages,
    setRealEstateByRange,
    setRealEstateById,
    setActiveSort,
    setActiveQuery,
    setCurrentRealEstate,
    setCurrentRealEstateLp,
    setRealEstateQueryToken,
    setWatermarkUpdate,
    setPublishedLP,
    setREBySteps,
    reorderREInStep,
    setTotalByStep,
    setLoadingByStep,
    updateREInStep,
    setStatisticByStep,
    setPartnerTags,
    setRealEstateChanges
} = realEstateSlice.actions;
export default realEstateSlice.reducer;
