import { loadable, Loadable } from "@onpreo/slices";
import { isNone } from "@onpreo/upsy-daisy";
import { ActionReducerMapBuilder, AsyncThunk, createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import { findBySpecialUse, SpecialUse } from "src/components/mailing/utils";
import { initialReduxState } from "../models";
import { MailingState, Mail, defaultMailbox, Envelope } from "../models/mailing.state";
import {
    $loadMailboxes,
    $loadMailboxPage,
    $loadMailMessage,
    $loadMailingAccount,
    $deleteMailingOutlookConnection,
    $deleteMailingGoogleConnection,
    $deleteMailingImapConnection,
    $loadMessageReference
} from "../thunks/mailing.thunk";

const attachLoadableThunk = <T extends any, Arg extends any = void>(
    builder: ActionReducerMapBuilder<MailingState>,
    thunk: AsyncThunk<T, Arg, any>,
    apply: (state: Draft<MailingState>, arg: Arg, value: Loadable<Draft<T>>) => void
) => {
    builder.addCase(thunk.pending, (state, action) => {
        apply(state, action.meta.arg, loadable.load);
    });
    builder.addCase(thunk.fulfilled, (state, action) => {
        const l = loadable.loaded(action.payload as Draft<T>);
        apply(state, action.meta.arg, l);
    });
    builder.addCase(thunk.rejected, (state, action) => {
        const l = loadable.failed(action.error as Draft<Error>);
        apply(state, action.meta.arg, l);
    });
};

const mailingSlice = createSlice({
    name: "mailing",
    initialState: initialReduxState.mailing,
    reducers: {
        setMailboxes(state, action: PayloadAction<MailingState["boxes"]>) {
            state.boxes = action.payload;
        },
        // only resets the order and total fiels
        resetMailbox(state, action: PayloadAction<string>) {
            const mailbox = action.payload;
            if (isNone(state.store.pages[mailbox])) return;
            state.store.pages[mailbox] = {};
            state.boxes = loadable.uninitialized;
        },
        resetMailboxByMessage(state, action: PayloadAction<string>) {
            const id = action.payload;

            const boxes = Object.entries(state.store.pages);
            const mailbox = boxes.find(([_, pages]) =>
                Object.values(pages)
                    .map(page => {
                        if (!loadable.isLoaded(page)) return false;
                        return page.value.order.includes(id);
                    })
                    .some(Boolean)
            )?.[0];
            if (isNone(mailbox)) return;
            state.store.pages[mailbox] = {};
            state.boxes = loadable.uninitialized;
        },
        resetByMailboxId(state, action: PayloadAction<{ use: SpecialUse }>) {
            const { use } = action.payload;
            if (!loadable.isLoaded(state.boxes)) return;
            const box = findBySpecialUse(state.boxes.value, use);
            if (isNone(box)) return;
            const mailbox = box.path;
            if (isNone(state.store.pages[mailbox])) return;
            state.store.pages[mailbox] = {};
            state.boxes = loadable.uninitialized;
        },

        resetMessages(state, action: PayloadAction<{ ids: string[] }>) {
            const { ids } = action.payload;
            ids.forEach(id => {
                if (isNone(state.store.mails[id])) state.store.mails[id] = loadable.uninitialized;
                if (isNone(state.store.envelopes[id])) state.store.envelopes[id] = undefined;
            });
        },

        setEnvelopesById(state, action: PayloadAction<{ mails: Envelope[] }>) {
            const { mails } = action.payload;
            const mailboxStore = state.store;
            mails.forEach(m => {
                mailboxStore.envelopes[m.id] = { ...(mailboxStore.envelopes[m.id] ?? {}), ...m } as Draft<Mail>;
            });
        }
    },
    extraReducers(builder) {
        attachLoadableThunk(builder, $loadMailboxes, (s, _, l) => {
            s.boxes = l;
        });
        attachLoadableThunk(builder, $loadMailboxPage, (s, { index, path }, l) => {
            if (isNone(s.store.pages[path])) s.store.pages[path] = {};
            s.store.pages[path][index] = l;
        });
        attachLoadableThunk(builder, $loadMailMessage, (s, { id }, l) => {
            if (isNone(s.store.mails)) {
                s.store.mails = {};
            }
            s.store.mails[id] = l;
        });
        attachLoadableThunk(builder, $loadMailingAccount, (s, _, l) => {
            s.account = l;
        });
        attachLoadableThunk(builder, $deleteMailingOutlookConnection, (s, _, l) => {
            s.account = l;
        });
        attachLoadableThunk(builder, $deleteMailingGoogleConnection, (s, _, l) => {
            s.account = l;
        });
        attachLoadableThunk(builder, $deleteMailingImapConnection, (s, _, l) => {
            s.account = l;
        });
        attachLoadableThunk(builder, $loadMessageReference, (s, id, l) => {
            s.store.references[id] = l;
        });
    }
});

export const { setMailboxes, resetMailbox, resetMailboxByMessage, resetByMailboxId, resetMessages, setEnvelopesById } = mailingSlice.actions;

export default mailingSlice.reducer;
