import { PROTOCOL, EE_URL, EE_ACCESS_KEY } from "src/utils/secrets";
import { EmailEngineApi } from "src/utils/email-engine/api";
import { isSome } from "@onpreo/upsy-daisy";

const BASE_URL = `${PROTOCOL}${EE_URL}`;
type SecurityData = { token: string };

export const emailEngineTestClient = new EmailEngineApi<SecurityData>({
    baseURL: BASE_URL,
    securityWorker: data => {
        if (isSome(data)) return { headers: { Authorization: `Bearer ${data.token}` } };
    }
});
export const emailEngineClient = new EmailEngineApi<SecurityData>({
    baseURL: BASE_URL,
    securityWorker: data => {
        if (isSome(data)) return { headers: { Authorization: `Bearer ${data.token}` } };
    }
});
emailEngineClient.setSecurityData({ token: EE_ACCESS_KEY });
