import { Range } from "@onpreo/components";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialReduxState } from "../models";

export const marketingCampaignSlice = createSlice({
    name: "marketingCampaigns",
    initialState: initialReduxState.marketingCampaigns,
    reducers: {
        setTotal: (state, action: PayloadAction<typeof initialReduxState.marketingCampaigns["total"]>) => void (state.total = action.payload),
        setActiveRange: (state, action: PayloadAction<{ range: Range }>) => {
            state.activeRange = action.payload.range;
        },
        setActiveQuery: (state, action: PayloadAction<typeof initialReduxState.marketingCampaigns.activeQuery>) => {
            state.activeQuery = { ...action.payload };
        },
        setWasChanged: (state, action: PayloadAction<typeof initialReduxState.marketingCampaigns["wasChanged"]>) =>
            void (state.wasChanged = action.payload),

        setLoaded: (state, action: PayloadAction<{ total: number; range: Range | undefined }>) => {
            state.total = action.payload.total;
            state.activeRange = action.payload.range;
        }
    }
});

export const { setTotal, setActiveRange, setActiveQuery, setWasChanged, setLoaded } = marketingCampaignSlice.actions;
export default marketingCampaignSlice.reducer;
