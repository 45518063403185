import { loadable } from "@onpreo/slices";
import { isSome } from "@onpreo/upsy-daisy";
import { onpreoClient } from "@onpreo/upsy-daisy/client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { AsyncThunkPayloadCreator, BaseThunkAPI } from "@reduxjs/toolkit/dist/createAsyncThunk";
import axios, { AxiosError } from "axios";
import { MailingImapBody } from "src/api/mailing/types";
import { emailEngineClient } from "src/api/mailing/utils";
import { BufferJSON, mailCredentialsClient } from "src/components/integration/email/secrets/client";
import { EmailEngineApi } from "src/utils/email-engine/api";
import { AsyncThunkConfig, createAppAsyncThunk } from "..";
import ReduxState from "../models";
import { Attachment, SendMail } from "../models/mailing.state";
import { EmailClient, mailingService } from "../services/mailing.service";
import { resetByMailboxId, resetMailbox, resetMailboxByMessage, resetMessages, setEnvelopesById } from "../slices/mailing.slice";

/// Frontend to Email Client Thunks

export const $setMailingOutlookConnection = createAsyncThunk("mailing/$setMailingOutlookConnection", async (body: { code: string }, api) => {
    const response = await mailingService.outlook.post(body);
    //const { user } = response.data;
    // return api.dispatch(setUser(user));
});

export const $deleteMailingOutlookConnection = createAsyncThunk("mailing/$deleteMailingOutlookConnection", async () => {
    const response = await mailingService.outlook.delete();
    return response.data;
});

export const $setMailingGoogleConnection = createAsyncThunk("mailing/$setMailingGoogleConnection", async (body: { code: string }, api) => {
    const response = await mailingService.google.post(body);
    //const { user } = response.data;
    // return api.dispatch(setUser(user));
});

export const $deleteMailingGoogleConnection = createAsyncThunk("mailing/$deleteMailingGoogleConnection", async () => {
    const response = await mailingService.google.delete();
    return response.data;
});

export const $setMailingImapConnection = createAsyncThunk("mailing/$setMailingImapConnection", async (body: MailingImapBody, api) => {
    try {
        const response = await mailingService.imap.post(body);
        // dispatch the load of an accessToken
        await api.dispatch($loadMailingAccount());
        return "success";
    } catch (e) {
        if (axios.isAxiosError(e)) {
            const error = e as AxiosError;
            if (error.response?.status === 403) return "error";
        }
    }
});

export const mailKeyHeader = "mail-key";
function getKeyHeader(userId: string) {
    // just load from local storage and append to request
    const item = mailCredentialsClient.retrieveKey(userId);
    return {
        [mailKeyHeader]: item
    };
}

export const $setMailingMigrationConnection = createAsyncThunk("mailing/$setMailingMigrationConnection", async (args: { userId: string }, api) => {
    try {
        const response = await mailingService.migration.post(getKeyHeader(args.userId));
        // dispatch the load of an accessToken
        await api.dispatch($loadMailingAccount());
        return "success";
    } catch (e) {
        if (axios.isAxiosError(e)) {
            const error = e as AxiosError;
            if (error.response?.status === 403) return "error";
        }
    }
});

export const $deleteMailingImapConnection = createAsyncThunk("mailing/$deleteMailingImapConnection", async () => {
    const response = await mailingService.imap.delete();
    return response.data;
});

export const $loadMessageReference = createAsyncThunk("mail/$loadMessageReference", async (id: string, api) => {
    const response = await mailingService.references.get(id);
    return response.data;
});

export const $loadMailingAccount = createAsyncThunk("mail/$loadAccount", async (_, api) => {
    const response = await mailingService.auth();
    return response.data;
});

/// Frontend to Email Client Thunks
type Api = Parameters<AsyncThunkPayloadCreator<any, any, AsyncThunkConfig>>[1];
const getEmailClient = async (api: Api) => {
    let account = api.getState().mailing.account;
    // todo: these are loadables
    if (!loadable.isLoaded(account)) await api.dispatch($loadMailingAccount());

    account = api.getState().mailing.account;

    if (!loadable.isLoaded(account)) throw new Error("Account was not loaded");
    if (account.value.type === "unconnected") throw new Error("Account is not connected");
    if (account.value.reauthenticate === true) throw new Error("Account needs reauthentication");

    const emailClient = emailEngineClient;
    emailClient.setSecurityData({ token: account.value.accessToken });

    return [emailClient, account.value] as const;
};

export const $loadMailboxes = createAsyncThunk("mailing/$loadMailboxes", async (_, _api) => {
    const [api, account] = await getEmailClient(_api);
    const response = await api.account.getV1AccountAccountMailboxes(account.account, { counters: true });
    return response.data.mailboxes;
});

export const $loadMailboxPage = createAsyncThunk("mailing/$loadMailEnvelopes", async (page: { index: number; size: number; path: string }, thunkApi) => {
    const [api, account] = await getEmailClient(thunkApi);
    const response = await api.account.getV1AccountAccountMessages(account.account, { path: page.path, page: page.index, pageSize: page.size });

    // first set the mails in the store
    const envelopes = response.data.messages;
    thunkApi.dispatch(setEnvelopesById({ mails: envelopes }));
    return { order: envelopes.map(e => e.id), page: response.data.page };
});

export const $loadMailMessage = createAsyncThunk("mail/$loadMailMessage", async ({ id: id }: { id: string }, _api) => {
    const [api, account] = await getEmailClient(_api);
    const response = await api.account.getV1AccountAccountMessageMessage(account.account, id, { webSafeHtml: true, textType: "html", markAsSeen: true });
    return response.data;
});

export const $loadAttachment = createAsyncThunk("mail/$loadAttachment", async (f: Attachment, _api) => {
    const [api, account] = await getEmailClient(_api);

    // Taken from here, although we might also want to use the Content-Disposition header
    // https://stackoverflow.com/questions/41938718/how-to-download-files-using-axios

    const attachment = await api.account.getV1AccountAccountAttachmentAttachment(account.account, f.id, {
        format: "blob"
    });
    const blob = new Blob([attachment.data], { type: f.contentType }); // change resultByte to bytes
    return blob;
});

export const $sendMail = createAsyncThunk("mail/$sendMail", async (body: SendMail, api) => {
    const response = await onpreoClient.post(`/api/email-engine/send`, body);
    // const response = await emailEngine((api, account) => api.account.postV1AccountAccountSubmit(account, body));
    api.dispatch(resetByMailboxId({ use: "sent" }));
    return response.data;
});

const delteMessage = async (api: EmailEngineApi<any>, account: string, id: string) => {
    const response = await api.account.deleteV1AccountAccountMessageMessage(account, id);
    // if (response.data.deleted) return;
    // throw new Error("Message was not deleted");
};

// Editing subroutes
export const $deleteSingleMail = createAsyncThunk("mail/$deleteSingleMail", async ({ id, mailbox }: { id: string; mailbox?: string }, _api) => {
    const [api, account] = await getEmailClient(_api);
    await delteMessage(api, account.account, id);

    // just reset the whole mailbox
    if (isSome(mailbox)) _api.dispatch(resetMailbox(mailbox));
    else _api.dispatch(resetMailboxByMessage(id));
    _api.dispatch(resetByMailboxId({ use: "trash" }));
});

export const $deleteBulkMail = createAsyncThunk("mail/$deleteBulkMail", async (body: { mailbox: string; ids: string[] }, api) => {
    const [emailApi, account] = await getEmailClient(api);

    // we do the deletion sequentially, because email engine does not support bulk deletion and only handles requests sequentially
    for (const id of body.ids) {
        await delteMessage(emailApi, account.account, id);
    }

    api.dispatch(resetMailbox(body.mailbox));
    api.dispatch(resetByMailboxId({ use: "trash" }));
});

const markAsSeen = async (api: EmailEngineApi<any>, account: string, id: string) => {
    const response = await api.account.putV1AccountAccountMessageMessage(account, id, { flags: { add: ["\\Seen"] } });
    if (response.data.flags.add) return;
    throw new Error("Message was not marked as seen");
};

export const $markAsReadBulk = createAsyncThunk("mail/$markAsReadBulk", async (body: { mailbox: string; ids: string[] }, api) => {
    const [emailApi, account] = await getEmailClient(api);

    // we do the deletion sequentially, because email engine does not support bulk deletion and only handles requests sequentially
    const results = await Promise.allSettled(body.ids.map(id => markAsSeen(emailApi, account.account, id)));

    // only continue if at least one was successful
    if (results.every(r => r.status === "rejected")) return;

    api.dispatch(resetMessages({ ids: body.ids }));
    api.dispatch(resetMailbox(body.mailbox));
});

export const $changeMailStyle = createAsyncThunk("mail/$changeMailStyle", async ({ mail, variant }: { mail: string; variant: string }, thunkAPI) => {
    const response = await mailingService.ai.post(mail, variant);
    return response.data;
});

export const $getMailSummaryWithAI = createAsyncThunk("mail/$getMailSummaryWithAI", async ({ mail }: { mail: string }, thunkAPI) => {
    const response = await mailingService.ai.put(mail);
    return response.data;
});
