import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialReduxState } from "../models";

export const activityTemplateSlice = createSlice({
    name: "activityTemplate",
    initialState: initialReduxState.activityTemplate,
    reducers: {
        setActivityTemplates: (state, action: PayloadAction<(typeof initialReduxState.activityTemplate)["templates"]>) => {
            state.templates = action.payload as [];
        },
        setActivityTemplateById: (state, action: PayloadAction<{ id: string; template: any }>) => {
            state.templates = state.templates.map(t => (t?._id === action.payload.id ? (t = { ...t, ...action.payload.template }) : t));
        },
        setNewActivity: (state, action: PayloadAction<{ newTemplate: any }>) => {
            state.templates = [...state.templates, action.payload.newTemplate];
        },
        filterDeletedActivity: (state, action: PayloadAction<{ templateId: string }>) => {
            state.templates = state.templates.filter(t => t._id !== action.payload.templateId);
        }
    }
});

export const { setActivityTemplates, setActivityTemplateById, setNewActivity, filterDeletedActivity } = activityTemplateSlice.actions;
export default activityTemplateSlice.reducer;
