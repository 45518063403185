import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "@onpreo/slices";
import { createFailureNotification } from "@onpreo/slices";
import locationsService from "../services/locations.service";
import { setLocations } from "../slices/locations.slice";

export const $loadLocations = createAsyncThunk("locations/$loadLocations", async (_, thunkAPI) => {
    try {
        const response = await locationsService.loadLocations();
        thunkAPI.dispatch(setLocations(response.data));
    } catch (err) {
        thunkAPI.dispatch(enqueueSnackbar(createFailureNotification()));
    }
});
