import { FC, useEffect, useMemo } from "react";

import { AvatarGroup } from "@material-ui/lab";
import { Avatar, Tooltip, Box, CircularProgress } from "@material-ui/core";
import { useReduxSelector } from "src/store/selectors";
import { getMailboxes } from "src/store/selectors/mailing.selector";
import { Loadable, loadable } from "@onpreo/slices";
import { $loadMailboxes } from "src/store/thunks/mailing.thunk";
import { useLoadableQuery } from "src/store/utils";
import { useAppDispatch } from "src/store";
import { isNone, isSome } from "@onpreo/upsy-daisy";
import { Mailboxes, MailboxResponseItem } from "src/utils/email-engine/api";
import { useRouter } from "next/router";

const mailboxSpecialUse = {
    inbox: "\\Inbox",
    drafts: "\\Drafts",
    junk: "\\Junk",
    sent: "\\Sent",
    trash: "\\Trash"
} as const;

export type SpecialUse = keyof typeof mailboxSpecialUse;
// export const mailboxIds = Object.keys(mailboxSpecialUse) as MailboxId[];
// export type MailId = { mailbox: MailboxId; uid: string };

export const mailboxNames: { [key in SpecialUse]: string } = {
    inbox: "Posteingang",
    drafts: "Entwürfe",
    sent: "Gesendet",
    junk: "Spam E-Mail",
    trash: "Papierkorb"
};

export const findBySpecialUse = (boxes: Mailboxes, id: SpecialUse) => {
    // @ts-ignore: Unfortunatly the swagger typing for specialUse is wrong
    return boxes.find(f => f.specialUse === mailboxSpecialUse[id]);
};
export const findSpecialUse = (mailbox: MailboxResponseItem) => {
    // @ts-ignore: Unfortunatly the swagger typing for specialUse is wrong
    return Object.entries(mailboxSpecialUse).find(([_, value]) => value === mailbox.specialUse)?.[0] as MailboxId;
};

export const useMailboxes = () => useLoadableQuery(getMailboxes, $loadMailboxes, void 0);

export const useSpecialUseRouter = () => {
    const mailboxes = useMailboxes();
    const router = useRouter();
    return (specialUse: SpecialUse) => {
        const mailbox = loadable.flatMap(mailboxes, mailboxes => {
            const folder = findBySpecialUse(mailboxes, specialUse);
            return loadable.fromMaybeNone(folder, new Error(`failed to find ${specialUse} mailbox`));
        });
        if (loadable.isLoaded(mailbox)) {
            router.push(`/mailing/mailbox/${mailbox.value.path}`);
        }
    };
};

export const useMailbox = (path?: string) => {
    const mailboxes = useMailboxes();
    const mailbox = useMemo(
        () =>
            loadable.flatMap(mailboxes, mailboxes => {
                if (isNone(path)) {
                    const folder = findBySpecialUse(mailboxes, "inbox");
                    return loadable.fromMaybeNone(folder, new Error('failed to find "inbox" mailbox'));
                } else {
                    return loadable.fromMaybeNone(
                        mailboxes.find(box => box.path === path),
                        new Error(`no mailbox found for path ${path}`)
                    );
                }
            }),
        [mailboxes, path]
    );
    return mailbox;
};

export const getMailboxName = (mailbox: MailboxResponseItem) => {
    const specialUse = findSpecialUse(mailbox);
    if (isSome(specialUse)) {
        return mailboxNames[specialUse];
    } else {
        return mailbox.name;
    }
};

export const useMailboxName = (path?: string) => {
    const mailbox = useMailbox(path);
    return loadable.map(mailbox, getMailboxName);
};

export const CenterLoader: FC<{ small?: boolean }> = ({ small }) => {
    return (
        <div style={{ width: "100%", height: "100%", display: "flex" }}>
            <Box margin={"auto"}>
                <CircularProgress size={small ? 10 : undefined} />
            </Box>
        </div>
    );
};

const avatarName = (value: string) => {
    const split = value.split(" ");
    return `${split[0][0]?.toLocaleUpperCase()}${split[1]?.[0]?.toLocaleUpperCase() ?? ""}`;
};
export const FromAvatars: FC<{ from: { name?: string; address?: string }[] }> = ({ from }) => {
    return (
        <AvatarGroup max={3}>
            {from?.map(email => {
                if (isNone(email?.address)) {
                    return null;
                }
                const full = email.name ? `${email.name} <${email.address}>` : email.address;
                const initials = email.name ? avatarName(email.name) : email.address?.slice(0, 2)?.toLocaleUpperCase();
                return (
                    <Tooltip title={full} key={email.address}>
                        <Avatar>{initials}</Avatar>
                    </Tooltip>
                );
            })}
        </AvatarGroup>
    );
};

export const UnwrapLoadable = <T extends any>(props: {
    value: Loadable<T>;
    children: (value: T) => JSX.Element;
    error?: React.ReactNode;
    small?: boolean;
}): JSX.Element => {
    const { value, children, error = "Daten konnten nicht geladen werden. Probieren Sie es später erneut!", small } = props;
    return (
        <>
            {loadable.match(value, {
                loaded: value => children(value),
                loading: () => <CenterLoader small={small} />,
                uninitialized: () => <CenterLoader small={small} />,
                failure: () => (
                    <div style={{ width: "100%", height: "100%", display: "flex" }}>
                        <Box margin={"auto"}>{error}</Box>
                    </div>
                )
            })}
        </>
    );
};
