import { AutomationTemplatesDocument, AutomationWorkflowDocument, ContactsListDocument, AutomationDocument } from "@onpreo/database";

type AutomationCampaignDocument = AutomationDocument & {
    status?: "active" | "archive" | "draft";
    listId?: string;
};
type ContactsListDocumentType = ContactsListDocument & { isConnectToActiveCampaign?: boolean };

export type AutomationState = {
    contactsLists: ContactsListDocumentType[];
    automationTemplates: AutomationTemplatesDocument[];
    automationWorkflows: AutomationWorkflowDocument[];
    automations: AutomationCampaignDocument[];
};
export const initialAutomationState = {
    contactsLists: [],
    automationTemplates: [],
    automationWorkflows: [],
    automations: []
};
