import type { BaseQueryFn } from "@reduxjs/toolkit/query";
import type { AxiosRequestConfig, AxiosError } from "axios";
import { onpreoClient } from "@onpreo/upsy-daisy/client";
import { useReduxSelector } from "./selectors";
import ReduxState from "./models";
import { AsyncThunk } from "@reduxjs/toolkit";
import { loadable, Loadable } from "@onpreo/slices";
import { useCallback, useEffect, useState } from "react";
import { useAppDispatch } from ".";

/**
 * A custom base query implementation that works with the onpreoClient (so that we get the automatic user feedback)
 *
 * The implementation is based on the guide found here: https://redux-toolkit.js.org/rtk-query/usage/customizing-queries
 **/
export const onpreoClientBaseQuery =
	(
		{ baseUrl }: { baseUrl: string } = { baseUrl: "" }
	): BaseQueryFn<
		{
			url: string;
			method: AxiosRequestConfig["method"];
			headers?: AxiosRequestConfig["headers"];
			data?: AxiosRequestConfig["data"];
			params?: AxiosRequestConfig["params"];
		},
		unknown,
		{ status: number; data: any }
	> =>
	async ({ url, method, data, params, headers }) => {
		try {
			const result = await onpreoClient({ url: baseUrl + url, method, data, params, headers });
			return { data: result.data };
		} catch (axiosError) {
			let err = axiosError as AxiosError;
			return {
				error: {
					status: err.response?.status,
					data: err.response?.data || err.message
				}
			};
		}
	};

export const useLoadableQuery = <T = unknown, Arg = void>(
	selector: (state: ReduxState, arg: Arg) => Loadable<T>,
	thunk: AsyncThunk<T, Arg, {}>,
	arg: Arg,
	options?: { skip?: boolean }
) => {
	const data = useReduxSelector(s => (options?.skip ? loadable.uninitialized : selector(s, arg)));
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (loadable.isUninit(data) && !options?.skip) {
			// If not started, try to load the data
			dispatch(thunk(arg));
		}
	}, [data, dispatch, options?.skip]);

	return data ?? loadable.uninitialized;
};

// this constructs a new loadable which mirrors the old one, but once there was some data present it will never go back to the uninitialized or loading state
export const usePermanentLoadable = <T, E extends Error = Error>(old: Loadable<T, E>) => {
	const [derived, setDerived] = useState(old);
	useEffect(() => {
		setDerived(last =>
			loadable.match(old, {
				uninitialized: () => last,
				loading: () => last,
				loaded: data => loadable.loaded(data),
				failure: error => loadable.failed(error)
			})
		);
	}, [old]);

	return derived;
};
