import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dispatch } from "redux";
import { enqueueSnackbar } from "@onpreo/slices";
import { createFailureNotification } from "@onpreo/slices";
import appointmentService from "../services/appointment.service";
import { setBrokerAppointment } from "../slices/appointment.slice";
import logger from "../../utils/logger";

export const $fetchAppointments = createAsyncThunk("appointment/$fetchAppointment", async (dispatch: Dispatch<any>, thunkAPI) => {
    const response = await appointmentService.fetchAppointments();
    dispatch(setBrokerAppointment(response.data.appointment));
    return response.data;
});

interface UpdateAppointmentThunk {
    appointmentId: string;
    appointment: any;
}

export const $updateAppointment = createAsyncThunk(
    "appointment/$updateAppointment",
    async ({ appointmentId, appointment }: UpdateAppointmentThunk, thunkAPI) => {
        const response = await appointmentService.updateAppointment(appointmentId, appointment);
        thunkAPI.dispatch(setBrokerAppointment(response.data.appointment));
        return response.data;
    }
);

interface createNewAppointmentThunk {
    appointment: any;
}

export const $createNewAppointment = createAsyncThunk("appointment/$createNewAppointment", async ({ appointment }: createNewAppointmentThunk, thunkAPI) => {
    const response = await appointmentService.createNewAppointment(appointment);
    thunkAPI.dispatch(setBrokerAppointment(response.data.appointment));
    return response.data;
});

export const $deleteAppointment = createAsyncThunk("appointment/$deleteAppointment", async (appointmentId: string, thunkAPI) => {
    const response = await appointmentService.deleteAppointment(appointmentId);
    thunkAPI.dispatch(setBrokerAppointment(response.data.appointment));
    return response.data;
});
