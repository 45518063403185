import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialReduxState } from "../models";

export const buyerPreferenceSlice = createSlice({
    name: "buyerPreference",
    initialState: initialReduxState.buyerPreference,
    reducers: {
        setCurrentBuyerPreference: (state, action: PayloadAction<typeof initialReduxState.buyerPreference.currentBuyerPreference>) => {
            // @ts-ignore
            state.currentBuyerPreference = action.payload;
            return state;
        }
    }
});

export const { setCurrentBuyerPreference } = buyerPreferenceSlice.actions;

export default buyerPreferenceSlice.reducer;
