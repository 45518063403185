import { onpreoClient } from "@onpreo/upsy-daisy/client";

const loadLocations = async () => {
    const url = "/api/location";
    return await onpreoClient.get(url);
};

const locationsService = {
    loadLocations
};

export default locationsService;
