import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialReduxState } from "../models";

export const mailTemplateSlice = createSlice({
    name: "mailTemplates",
    initialState: initialReduxState.mailTemplate,
    reducers: {
        setMailTemplates: (state, action: PayloadAction<(typeof initialReduxState.mailTemplate)["templates"]>) => {
            state.templates = action.payload as [];
        },
        setMailTemplateById: (state, action: PayloadAction<{ id: string; template: any }>) => {
            state.templates = state.templates.map(t => (t?._id === action.payload.id ? (t = { ...t, ...action.payload.template }) : t));
        }
    }
});

export const { setMailTemplates, setMailTemplateById } = mailTemplateSlice.actions;
export default mailTemplateSlice.reducer;
