import { FieldCreator, LoadedTableBundle, TableBundle, TableDefinition } from "./types";

// a index range for the currently displayed items
// from is inclusive (eg. should be loaded) while to should be exclusive
export type Range = [from: number, to: number];

export type LoadOptions = {
    range: Range;

    // Indicates if this loading request should skip cache and reload from the server
    refresh: boolean;
    sort?: any;
    searchQuery?: any;
};

type LoadResult<T> = {
    /**
     * The loaded values, expected to be of length range.to - range.from
     **/
    values: T[];
    /**
     * The most recent estimation of how many objects exists used for pagination
     **/
    totalCount: number;
};

export type LoadFunction<T extends any> = (options: LoadOptions) => Promise<LoadResult<T>>;

/**
 * Create a table definition
 *
 * The definition contains the label of that column as well as a render function, that controls how a value
 * is displayed
 *
 * Example:
 *
 * Given the data:
 * type Sample = {
 *   name: string;
 *   age: number;
 * }
 *
 * One could define a table definition for that with
 * const definition = createTableDefintion<Sample>(f => (
 *   // "name" in the render function is inferred to be a string
 *   // second parameter defines if the column should be sortable
 *   name: f.field<"name">("Name", true, name => <Typography>{name}</Typograph>),
 *   age: f.field<"age">("Alter", true, age => <Typography>{age}</Typography>),
 *   // you can always add an action field, v is of type "Sample"
 *   action: f.action("Bearbeiten", v => <Button>{"something fancy"}</Button>)
 * ));
 **/
export const createTableDefinition = <T extends any>(func: (f: FieldCreator<T>) => TableDefinition<T>): TableDefinition<T> => {
    const f: FieldCreator<T> = {
        field: (label, sortable, render) => ({ type: "field", label, sortable, render }),
        action: (label, render) => ({ type: "action", label, render, sortable: false })
    };
    return func(f);
};

/**
 * "Zips" together the table definition and actual data, the resulting
 * object can be supplied to the Table component
 *
 * Example:
 *
 * // Somewhere in your component
 * const table = useMemo(() => createTable(definition, data), [data]);
 *
 * // In the render function
 * [...]
 * <Table table={table} {...otherProps} />
 * [...]
 **/
export const createSmartTable = <T extends any>(definition: TableDefinition<T>, load: LoadFunction<T>): TableBundle<T> => ({ definition, load });

export const createTable = <T extends any>(definition: TableDefinition<T>, data: T[]): LoadedTableBundle<T> => ({
    definition,
    data: { type: "loaded", data }
});

export function variantToPathMap(variant: string): string {
    switch (variant) {
        case "lead":
            return "leads";
        case "propsectiveBuyer":
            return "propsective-buyers";
        case "tipper":
            return "tippers";
        default:
            return "contacts";
    }
}
