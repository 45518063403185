import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialReduxState } from "../models";

export const locationsSlice = createSlice({
    name: "locations",
    initialState: initialReduxState.locations,
    reducers: {
        setLocations: (state, action: PayloadAction<(typeof initialReduxState.locations)["locations"]>) => {
            state.locations = action.payload as [];
            return state;
        },
        addLocation: (state, action: PayloadAction<any>) => {
            const newLocations = [...state.locations, action.payload];
            state.locations = newLocations;
        }
    }
});

export const { setLocations, addLocation } = locationsSlice.actions;
export default locationsSlice.reducer;
