import ReduxState from "../models";
import { createSelector } from "@reduxjs/toolkit";
import { getWorkspaceTeamUsers, getAdminUserInfo } from "./workspace.selectors";

export const getUser = (state: ReduxState) => state.user;

export const getUserId = createSelector(getUser, user => (user as any)._id);

export const getUserWorkspace = createSelector(getUser, user => user.workspace);

export const getUserName = createSelector(getUser, user => user.meta.name);
export const getUserEmail = createSelector(getUser, user => user.email);

export const getUserImage = createSelector(getUser, user => user.meta?.img);

export const getUserIsOnboarded = createSelector(getUser, user => user.isOnboarded);

export const getUserRole = createSelector(getUser, user => user.role);
export const getTeamUserImage = createSelector(getUser, user => (user?.role === "user" ? user?.meta?.img : null));

export const getUserEmailIntegration = createSelector(getUser, user => user.secrets?.ids);

export const getUserTracking = createSelector(getUser, w => w.tracking);
export const getUserTrackingPlan = createSelector(getUserTracking, tr => tr?.plan);
export const getUserCalendar = createSelector(getUser, user => user?.calendar);
export const getFirstLogin = createSelector(getUser, user => user?.isFirstLogin);
export const getOnboardingVariant = createSelector(getUserTracking, tr => tr?.onboardingVariant);

export const getResponsibleUserById = createSelector(
    getWorkspaceTeamUsers,
    getAdminUserInfo,
    (_s, id: string) => id,
    (workspaceTeamUsers, adminUserInfo, id) => {
        const current = [...workspaceTeamUsers, adminUserInfo].find(u => u._id === id);
        return current;
    }
);
export const getUserNotifications = createSelector(getUser, user => user?.notifications);
export const getMailsRecipients = createSelector(getUser, user => user?.recipients);

export const getIMVSearchProfiles = createSelector(getUser, user => user?.imv?.searchProfile);
