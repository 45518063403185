export enum SubscriptionState {
    TRIALING = "trialing",
    ACTIVE = "active",
    INCOMPLETE = "incomplete",
    INCOMPLETE_EXPIRED = "incomplete_expired",
    PAST_DUE = "past_due",
    CANCELED = "canceled",
    UNPAID = "unpaid",
    // used to signal that a user needs to select a subscription
    // but is not entitled for a trial period (eg. an outsourced customer)
    PENDING = "pending"
}

export enum StripePlans {
    CRM_BEGINNER = "CRM_BEGINNER",
    CRM_MASTER = "CRM_MASTER",
    CRM_ENTERPRISE = "CRM_ENTERPRISE",
    NONE = "NONE"
}

export interface Invoice {
    id: string;
    invoice_pdf: string;
    period_start: Date;
    total: number;
}

export interface BillingDetails {
    address: {
        city: string;
        county: string;
        line1: string;
        line2: string;
        postal_code: string;
        state: string;
    };
    email: string;
    name: string;
    phone: string;
}

export interface CreditCard {
    brand: string;
    exp_month: number;
    exp_year: number;
    last4: string;
}

export interface PaymentMethod {
    id: string;
    billing_details: BillingDetails;
    card: CreditCard;
}

export interface SubscriptionInfo {
    id: string;
    current_period_start: Date;
    current_period_end: Date;
    cancel_at: Date | null;
    created: Date;
    planTitle: StripePlans;
    discount?: {
        coupon: {
            percent_off: number;
        };
    };
    plan: {
        id: string;
        amount: number;
        interval: string;
    };
    items: {
        data: {
            price: {
                tiers: {
                    flat_amount: number;
                    unit_amount: number;
                    up_to: number;
                }[];
            };
        }[];
    };
    quantity: number;
    metadata?: any;
}
