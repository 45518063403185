import { Range } from "@onpreo/components";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialReduxState } from "../models";
import type { PriceAssistantArray, PriceAssistantsOfWorkspace } from "../models/price-assistant.state";

export const priceAssistantSlice = createSlice({
    name: "priceAssistant",
    initialState: initialReduxState.priceAssistant,
    reducers: {
        setPriceAssistantsByWorkspace: (state, action: PayloadAction<PriceAssistantsOfWorkspace>) => {
            state.priceAssistantsByWorkspace = action.payload;
            return state;
        },
        setType: (state, action: PayloadAction<(typeof initialReduxState.priceAssistant)["type"]>) => void (state.type = action.payload),
        setTotal: (state, action: PayloadAction<(typeof initialReduxState.priceAssistant)["total"]>) => void (state.total = action.payload),
        setPriceAssistantByRange: (state, action: PayloadAction<{ range: Range; priceAssistantByRange: PriceAssistantArray }>) => {
            // we need to sort in this users according to the range
            const [from, to] = action.payload.range;
            // make sure state.users is at least **to** long
            if (state.priceAssistantByRange.length < to) {
                state.priceAssistantByRange = state.priceAssistantByRange.concat(Array(to - state.priceAssistantByRange.length).fill(undefined));
            }
            // and then splice the new values into it
            // @ts-ignore
            state.priceAssistantByRange.splice(from, to - from, ...action.payload.priceAssistantByRange);
            // and set the active range
            state.activeRange = action.payload.range;
            state.type = "loaded";
        },
        setPriceAssistantById: (state, action: PayloadAction<{ id: number | string; value: any }>) => {
            const newPA = { ...action.payload.value };
            state.priceAssistantByRange = state.priceAssistantByRange.map(pa => (pa._id === action.payload.id ? (pa = newPA) : pa));
            state.priceAssistantsByWorkspace = state.priceAssistantsByWorkspace.map(pa => (pa._id === action.payload.id ? (pa = newPA) : pa));
        },
        setActiveSort: (state, action: PayloadAction<typeof initialReduxState.priceAssistant.activeSort>) => {
            state.activeSort = action.payload;
        },
        setCurrentPriceAssistant: (state, action: PayloadAction<typeof initialReduxState.priceAssistant.currentPriceAssistant>) => {
            // @ts-ignore
            state.currentPriceAssistant = action.payload;
        }
    }
});

export const {
    setType,
    setTotal,
    setPriceAssistantByRange,
    setPriceAssistantById,
    setActiveSort,
    setCurrentPriceAssistant,
    setPriceAssistantsByWorkspace
} = priceAssistantSlice.actions;
export default priceAssistantSlice.reducer;
