import { PriceAssistantDocument } from "@onpreo/database";
import { Range } from "@onpreo/components";

export type PriceAssistantArray = PriceAssistantDocument[];
export type PriceAssistantsOfWorkspace = Pick<PriceAssistantDocument, "_id" | "title">[];

export type PriceAssistantState = {
    priceAssistantsByWorkspace: PriceAssistantsOfWorkspace;
    priceAssistantByRange: PriceAssistantArray;
    type: "loaded" | "loading" | "error";
    total: Number;
    activeRange: any;
    activeSort: any;
    currentPriceAssistant: PriceAssistantDocument;
};
export const initialPriceAssistantState = {
    priceAssistantsByWorkspace: [] as PriceAssistantsOfWorkspace,
    priceAssistantByRange: [] as PriceAssistantArray,
    type: "loading" as "loaded" | "loading" | "error",
    total: -1,
    activeRange: undefined as Range | undefined,
    activeSort: null,
    currentPriceAssistant: null
};
