import { Workspace } from "@onpreo/database";

export interface WorkspaceState extends Workspace {
    valuation?: {
        count?: number;
    };
}

export const initialWorkspaceState = {
    adminUser: {
        id: "",
        meta: {
            name: "Mr. onpreo",
            phone: ""
        },
        workspace: {},
        email: "mr@onpreo.de",
        isActivated: false,
        isOnboarded: false,
        hasReadAgreement: false,
        role: "admin"
    },
    billing: {
        customerId: "",
        subscriptionId: "",
        subscriptionState: "trialing",
        initialTrialEnded: true,
        hasPaymentMethod: false,
        plan: "none",
        phone: "000",
        name: "Mr. onpreo",
        company: "onpreo GmbH",
        website: "www.onpreo.de"
    },
    integrations: {
        leadGenerator: {},
        zapier: {
            webhook: ""
        }
    },
    companyInfo: {},
    landingPageInfo: {},
    publication: {},
    contactInfo: {},
    contents: {},
    addons: [],
    valuation: {
        count: 0
    }
} as any;
