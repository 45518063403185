import { createSelector } from "@reduxjs/toolkit";
import createCachedSelector from "re-reselect";
import ReduxState from "../models";

export const getMailState = (state: ReduxState) => state.mailing;

export const getMailingAccount = createSelector(getMailState, mail => mail.account);
export const getMailboxes = createSelector(getMailState, mail => mail.boxes);
export const getMailStore = createSelector(getMailState, mail => mail.store);

export const getMailboxPages = createCachedSelector(
    getMailStore,
    (_s, mailbox: string) => mailbox,
    (store, mailbox) => store.pages[mailbox]
)((_s, mailbox) => mailbox);

export const getMailboxMails = createSelector(getMailStore, s => s.mails);
export const getEnvelopes = createSelector(getMailStore, s => s.envelopes);
export const getReferences = createSelector(getMailStore, s => s.references);

export const getMailboxPage = createCachedSelector(
    (s: ReduxState, id: { index: number; size: number; path: string }) => getMailboxPages(s, id.path),
    (_s: ReduxState, id: { index: number; size: number; path: string }) => id.index,
    (pages, index) => pages?.[index]
)((_s, id) => id.index.toString());

export const getEnvelopeById = createCachedSelector(
    getEnvelopes,
    (s: ReduxState, id: { messageId: string }) => id.messageId,
    (mails, messageId) => mails?.[messageId]
)((_s, id) => id.messageId);

export const getReferenceById = createCachedSelector(
    getReferences,
    (s: ReduxState, id: string) => id,
    (references, id) => references?.[id]
)((_s, id) => id);

export const getMailById = createCachedSelector(
    getMailboxMails,
    (s: ReduxState, id: { id: string }) => id.id,
    (mails, id) => mails?.[id]
)((_s, id) => id.id);
// export const getMailEnvelope = createSelector(getMailByUid, mail => mail?.envelope);
// export const getMailReference = createSelector(getMailByUid, mail => mail?.reference);
// export const getMailFlags = createSelector(getMailByUid, mail => mail?.flags);
// export const getMailParsed = createSelector(getMailByUid, mail => mail?.parsed);
