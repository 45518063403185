import { useEffect } from "react";
import { PARTNERSTACK_PUBLIC } from "../../utils/secrets";

const PartnerstackHelper = () => {
    const runPsScript = () => {
        var gs = document.createElement("script");
        gs.src = "https://my.onpreo.com/pr/js";
        gs.type = "text/javascript";
        gs.async = true;
        gs.onload = (gs as any).onreadystatechange = function () {
            var rs = this?.readyState;
            if (rs && rs != "complete" && rs != "loaded") return;
            try {
                (window as any).growsumo._initialize(PARTNERSTACK_PUBLIC);
                if (typeof (window as any).growsumoInit === "function") {
                    (window as any).growsumoInit();
                }
            } catch (e) {}
        };
        var s = document.getElementById("ps-onpreo");
        s.parentNode.insertBefore(gs, s);
    };

    useEffect(() => {
        if (typeof window !== "undefined") runPsScript();
    }, []);

    return <div id="ps-onpreo" />;
};

export default PartnerstackHelper;
