import { initialBrokerAppointmentState } from "./appointment.state";
import { BrokerAppointmentState } from "./appointment.state";
import { ContactState, initialContactState } from "./contact.state";
import { initialRealEstateState, RealEstateState } from "./real-estate.state";
import { SharedReduxState, initialSharedState } from "@onpreo/slices";
import { initialUserState, UserState } from "./user.state";
import { initialWorkspaceState, WorkspaceState } from "./workspace.state";
import { initialNoteState, NoteState } from "./note.state";
import { initialTaskState, TaskState } from "./task.state";
import { initialPriceAssistantState, PriceAssistantState } from "./price-assistant.state";
import { defaultMailState as initialMailState, MailState } from "./mail.state";
import { MailingState, defaultMailingState as initialMailingState } from "./mailing.state";
import { initialActivitiesState, ActivitiesState } from "./activities.state";
import { initialBuyerPreferenceState, BuyerPreferenceState } from "./buyer-preference.state";
import { initialMailTemplateState, MailTemplateState } from "./mail-template.state";
import { initialLocationsState, LocationsState } from "./locations.state";
import { initialActivityTemplateState, ActivityTemplateState } from "./activity-template.state";
import { initialPipelinesState, PipelinesState } from "./pipeline.state";
import { initialAutomationState, AutomationState } from "./automation.state";
import { initialMarketingCampaignState, MarketingCampaignState } from "./marketing-campaign.state";

export default interface ReduxState extends SharedReduxState {
    user: UserState;
    workspace: WorkspaceState;
    realEstate: RealEstateState;
    contact: ContactState;
    // TODO: deprecated? --> start
    appointment: BrokerAppointmentState;
    note: NoteState;
    task: TaskState;
    // <--- end
    priceAssistant: PriceAssistantState;
    mail: MailState;
    mailing: MailingState;
    activities: ActivitiesState;
    buyerPreference: BuyerPreferenceState;
    mailTemplate: MailTemplateState;
    locations: LocationsState;
    activityTemplate: ActivityTemplateState;
    pipelines: PipelinesState;
    automation: AutomationState;
    marketingCampaigns: MarketingCampaignState;
}

export const initialReduxState: ReduxState = {
    user: initialUserState,
    workspace: initialWorkspaceState,
    realEstate: initialRealEstateState,
    contact: initialContactState,
    // TODO: deprecated? --> start
    appointment: initialBrokerAppointmentState,
    note: initialNoteState,
    task: initialTaskState,
    // <--- end
    priceAssistant: initialPriceAssistantState,
    mail: initialMailState,
    mailing: initialMailingState,
    activities: initialActivitiesState,
    buyerPreference: initialBuyerPreferenceState,
    mailTemplate: initialMailTemplateState,
    locations: initialLocationsState,
    activityTemplate: initialActivityTemplateState,
    pipelines: initialPipelinesState,
    automation: initialAutomationState,
    marketingCampaigns: initialMarketingCampaignState,
    ...initialSharedState
};
