import React, { Fragment, useEffect } from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

import "../public/styles.css";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";

import Head from "next/head";
import { Provider } from "react-redux";
import { initStore } from "../src/store";
import { onpreoTheme } from "@onpreo/components";
import "../src/assets/scss/argon-dashboard-pro-material-ui.scss";
import { SessionProvider, signIn, useSession } from "next-auth/react";
import SessionChecker from "../src/components/helpers/session-checker";
import { CircularProgress, Grid, Typography } from "@material-ui/core";
import dynamic from "next/dynamic";
import { Snacker } from "@onpreo/slices";
import { GTM_CONTAINER_ID } from "../src/utils/secrets";
import { setUser } from "@sentry/nextjs";
import { Tracker } from "@onpreo/components";
import IntercomHelper from "../src/components/helpers/intercom-helper";
import UserPilot from "../src/components/helpers/userpilot-helper";
import { useRouter } from "next/router";
import PartnerstackHelper from "../src/components/helpers/partnerstack";
import HotjarHelper from "../src/components/helpers/hotjar-helper";
const Navigation = dynamic(import("../src/components/navigation"));

export const CookieBanner = dynamic(() => import("@onpreo/components/src/cookies/banner"), {
    ssr: false
});

function storePathValues() {
    const storage = localStorage;
    // Set the previous path as the value of the current path.
    const prevPath = storage.getItem("currentPath");
    storage.setItem("prevPath", prevPath);
    // Set the current path value by looking at the browser's location object.
    storage.setItem("currentPath", globalThis.location.pathname);
}

export default function OnpreoApp({ Component, pageProps: { session, ...pageProps } }) {
    const store = initStore(pageProps.initialReduxState);
    const router = useRouter();

    useEffect(() => {
        // Remove the server-side injected CSS.
        const jssStyles = document.querySelector("#jss-server-side");
        if (jssStyles && jssStyles.parentElement) jssStyles.parentElement.removeChild(jssStyles);

        // console.info("enabling google tag manager");
        // TagManager.initialize({ gtmId: GTM_CONTAINER_ID });
    }, []);

    useEffect(() => storePathValues, [router.asPath]);

    return (
        <Fragment>
            <Head>
                <title>onpreo</title>
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Head>
            <ThemeProvider theme={onpreoTheme}>
                <SessionProvider session={session}>
                    <Provider store={store}>
                        <Snacker>
                            <CssBaseline />
                            <PartnerstackHelper />
                            <IntercomHelper />
                            <UserPilot />
                            <HotjarHelper />
                            <Tracker cookieName={"__onpreo_conf"} gtmId={GTM_CONTAINER_ID} />
                            {Component.authenticated ? (
                                <Authenticator>
                                    <SessionChecker />
                                    {!Component.authenticated.ignoreNav && (
                                        <Navigation>
                                            <Component {...pageProps} />
                                        </Navigation>
                                    )}
                                    {Component.authenticated.ignoreNav && <Component {...pageProps} />}
                                </Authenticator>
                            ) : (
                                <Component {...pageProps} />
                            )}
                        </Snacker>
                    </Provider>
                </SessionProvider>
            </ThemeProvider>
        </Fragment>
    );
}

function Authenticator({ children }: any) {
    const { data: session, status } = useSession();
    const isUser = !!session?.user;

    useEffect(() => {
        if (status === "loading") return; // Do nothing while loading
        if (!session?.user) signIn().then(); // If not authenticated, force log in
    }, [session?.user, status]);

    useEffect(() => {
        if (session?.user) {
            setUser({ email: session.user?.email, id: session.user?.name });
        }
    }, [session?.user]);

    if (isUser) {
        return children;
    }

    // Session is being fetched, or no user.
    // If no user, useEffect() will redirect.
    return (
        <Grid container style={{ width: "100vw", height: "100vh" }} direction={"column"} justifyContent={"center"} alignItems={"center"}>
            <Grid container item justifyContent={"center"}>
                <CircularProgress />
            </Grid>
            <br />
            <Grid container item justifyContent="center">
                <Typography align={"center"}>Und wir drehen uns...</Typography>
            </Grid>
        </Grid>
    );
}
