import { Range } from "@onpreo/components";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialReduxState } from "../models";
import { ActivitiesArray } from "../models/activities.state";

export const activitiesSlice = createSlice({
    name: "activities",
    initialState: initialReduxState.activities,
    reducers: {
        setType: (state, action: PayloadAction<(typeof initialReduxState.activities)["type"]>) => void (state.type = action.payload),
        setTotal: (state, action: PayloadAction<(typeof initialReduxState.activities)["total"]>) => void (state.total = action.payload),
        setActivitiesByRange: (state, action: PayloadAction<{ range: Range; activitiesByRange: ActivitiesArray }>) => {
            // we need to sort in this users according to the range
            const [from, to] = action.payload.range;
            // make sure state.users is at least **to** long
            if (state.activitiesByRange.length < to) {
                state.activitiesByRange = state.activitiesByRange.concat(Array(to - state.activitiesByRange.length).fill(undefined));
            }
            // and then splice the new values into it
            // @ts-ignore
            state.activitiesByRange.splice(from, to - from, ...action.payload.activitiesByRange);
            // and set the active range
            state.activeRange = action.payload.range;
            state.type = "loaded";
        },

        setActivitiesByPeriod: (state, action) => {
            if (action.payload.period) {
                state.activitiesByPeriod = [...state.activitiesByPeriod, ...action.payload.activities];
            } else {
                state.activitiesByPeriod = action.payload.activities;
            }
        },
        setPeriod: (state, action) => {
            state.period = action.payload;
        },
        setActivityById: (state, action: PayloadAction<{ id: number | string; value: any }>) => {
            const newActivity = { ...action.payload.value };
            state.activitiesByRange = state.activitiesByRange.map(a => (a._id === action.payload.id ? (a = newActivity) : a));

            state.activitiesByPeriod = state.activitiesByPeriod?.map(a => (a._id === action.payload.id ? (a = newActivity) : a));
        },
        setActiveSort: (state, action: PayloadAction<typeof initialReduxState.activities.activeSort>) => {
            state.activeSort = action.payload;
        },
        setActiveQuery: (state, action: PayloadAction<typeof initialReduxState.activities.activeQuery>) => {
            state.activeQuery = { ...state.activeQuery, ...action.payload };
        }
    }
});

export const { setType, setTotal, setActivitiesByRange, setActivityById, setActiveSort, setActivitiesByPeriod, setPeriod, setActiveQuery } =
    activitiesSlice.actions;
export default activitiesSlice.reducer;
