import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialReduxState } from "../models";

export const pipelineSlice = createSlice({
    name: "pipelines",
    initialState: initialReduxState.pipelines,
    reducers: {
        setPipelines: (state, action: PayloadAction<typeof initialReduxState.pipelines["pipelines"]>) => {
            state.pipelines = action.payload as [];
        },
        setSelectList: (state, action: PayloadAction<typeof initialReduxState.pipelines["selectList"]>) => {
            state.selectList = action.payload as [];
        },
        setNewPipeline: (state, action) => {
            const checkIfExist = state.pipelines.find(p => p._id === action.payload._id);
            if (checkIfExist) state.pipelines = state.pipelines.map(p => (p._id === action.payload._id ? action.payload : p));
            else state.pipelines = [...state.pipelines, action.payload];
        },
        removePipeline: (state, action) => {
            state.pipelines = state.pipelines.filter(p => {
                p.subcategories = p?.subcategories?.filter(sub => sub._id !== action.payload);
                return p._id !== action.payload;
            });
        },
        setAccessed: (state, action: PayloadAction<typeof initialReduxState.pipelines["accessed"]>) => {
            state.accessed = action.payload as [];
        },
        setPipelineNames: (state, action: PayloadAction<typeof initialReduxState.pipelines["names"]>) => {
            state.names = action.payload as [];
        }
    }
});

export const { setPipelines, setSelectList, setNewPipeline, removePipeline, setAccessed, setPipelineNames } = pipelineSlice.actions;
export default pipelineSlice.reducer;
