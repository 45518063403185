/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/**
 * Account type
 * @example "outlook"
 */
export type Type = "imap" | "gmail" | "gmailService" | "outlook" | "mailRu" | "oauth2";

/**
 * Account state
 * @example "connected"
 */
export type State = "init" | "syncing" | "connecting" | "connected" | "authenticationError" | "connectError" | "unset" | "disconnected";

/**
 * Lifetime event counters
 * @example {"messageNew":30,"messageDeleted":5}
 */
export type AcountCountersEvents = object;

export interface AccountCounters {
    /** Lifetime event counters */
    events?: AcountCountersEvents;
}

/**
 * Requested grant type
 * @example "refresh_token"
 */
export type Grant = "refresh_token" | "authorization_code";

/** List of requested OAuth2 scopes */
export type OauthScopes = string[];

/**
 * Server response
 * @example {"error":"invalid_grant","error_description":"Bad Request"}
 */
export type Response = object;

/** OAuth2 error info if token request failed */
export interface TokenRequest {
    /** Requested grant type */
    grant?: Grant;
    /**
     * OAuth2 provider
     * @maxLength 256
     * @example "gmail"
     */
    provider?: string;
    /**
     * HTTP status code for the OAuth2 request
     * @example 400
     */
    status?: number;
    /**
     * OAuth2 client ID used to authenticate this request
     * @example "1023289917884-h3nu00e9cb7h252e24c23sv19l8k57ah.apps.googleusercontent.com"
     */
    clientId?: string;
    /** List of requested OAuth2 scopes */
    scopes?: OauthScopes;
    /** Server response */
    response?: Response;
}

export interface AccountErrorEntry {
    /** @example "Token request failed" */
    response?: string;
    /** @example "OauthRenewError" */
    serverResponseCode?: string;
    /** OAuth2 error info if token request failed */
    tokenRequest?: TokenRequest;
}

export interface AccountResponseItem {
    /**
     * Account ID
     * @maxLength 256
     * @example "example"
     */
    account: string;
    /**
     * Display name for the account
     * @maxLength 256
     * @example "My Email Account"
     */
    name?: string;
    /**
     * Default email address of the account
     * @example "user@example.com"
     */
    email?: string;
    /** Account type */
    type: Type;
    /**
     * OAuth2 application ID
     * @maxLength 256
     * @example "AAABhaBPHscAAAAH"
     */
    app?: string;
    /** Account state */
    state: State;
    /**
     * Account-specific webhook URL
     * @example "https://myservice.com/imap/webhooks"
     */
    webhooks?: string;
    /**
     * Proxy URL
     * @example "socks://proxy.example.com:1080"
     */
    proxy?: string;
    /**
     * Hostname to use for the SMTP EHLO/HELO greeting
     * @example "my.proxy.tld"
     */
    smtpEhloName?: string;
    counters?: AccountCounters;
    /**
     * Last sync time
     * @format date-time
     * @example "2021-02-17T13:43:18.860Z"
     */
    syncTime?: string;
    lastError?: AccountErrorEntry;
}

export type AccountEntries = AccountResponseItem[];

export interface AccountsFilterResponse {
    /**
     * How many matching entries
     * @example 120
     */
    total?: number;
    /**
     * Current page (0-based index)
     * @example 0
     */
    page?: number;
    /**
     * Total page count
     * @example 24
     */
    pages?: number;
    accounts?: AccountEntries;
}

export interface DetectedAuthenticationInfo {
    /**
     * Account username
     * @maxLength 256
     * @example "myuser@gmail.com"
     */
    user?: string;
}

export interface ResolvedServerSettings {
    auth?: DetectedAuthenticationInfo;
    /**
     * Hostname to connect to
     * @example "imap.gmail.com"
     */
    host: string;
    /**
     * Service port number
     * @min 1
     * @max 65536
     * @example 993
     */
    port: number;
    /**
     * Should connection use TLS. Usually true for port 993
     * @default false
     * @example true
     */
    secure?: boolean;
}

export interface DiscoveredServerSettings {
    auth?: DetectedAuthenticationInfo;
    /**
     * Hostname to connect to
     * @example "imap.gmail.com"
     */
    host: string;
    /**
     * Service port number
     * @min 1
     * @max 65536
     * @example 993
     */
    port: number;
    /**
     * Should connection use TLS. Usually true for port 993
     * @default false
     * @example true
     */
    secure?: boolean;
}

export interface DiscoveredEmailSettings {
    imap?: ResolvedServerSettings;
    smtp?: DiscoveredServerSettings;
    /**
     * Source for the detected info
     * @example "srv"
     */
    _source?: string;
}

export interface BlocklistsResponseItem {
    /**
     * List ID
     * @maxLength 256
     * @example "example"
     */
    listId: string;
    /**
     * Count of blocked addresses in this list
     * @example 12
     */
    count?: number;
}

export type BlocklistsEntries = BlocklistsResponseItem[];

export interface BlocklistsResponse {
    /**
     * How many matching entries
     * @example 120
     */
    total?: number;
    /**
     * Current page (0-based index)
     * @example 0
     */
    page?: number;
    /**
     * Total page count
     * @example 24
     */
    pages?: number;
    blocklists?: BlocklistsEntries;
}

export interface GatewayResponseItem {
    /**
     * Gateway ID
     * @maxLength 256
     * @example "example"
     */
    gateway: string;
    /**
     * Display name for the gateway
     * @maxLength 256
     * @example "My Email Gateway"
     */
    name?: string;
    /**
     * Count of email deliveries using this gateway
     * @example 100
     */
    deliveries?: number;
    /**
     * Last delivery time
     * @format date-time
     * @example "2021-02-17T13:43:18.860Z"
     */
    lastUse?: string;
    lastError?: AccountErrorEntry;
}

export type GatewayEntries = GatewayResponseItem[];

export interface GatewaysFilterResponse {
    /**
     * How many matching entries
     * @example 120
     */
    total?: number;
    /**
     * Current page (0-based index)
     * @example 0
     */
    page?: number;
    /**
     * Total page count
     * @example 24
     */
    pages?: number;
    gateways?: GatewayEntries;
}

export type LicenseDetails = "false";

export interface LicenseResponse {
    /**
     * Is there an active license registered
     * @example true
     */
    active?: boolean;
    /**
     * Active license type
     * @example "EmailEngine License"
     */
    type?: string;
    details?: LicenseDetails;
    /**
     * Are email connections closed
     * @example false
     */
    suspended?: boolean;
}

/**
 * OAuth2 provider
 * @example "gmail"
 */
export type Provider = "gmail" | "gmailService" | "outlook" | "mailRu";

export interface OAuth2ResponseItem {
    /**
     * OAuth2 application ID
     * @maxLength 256
     * @example "AAABhaBPHscAAAAH"
     */
    id: string;
    /**
     * Display name for the app
     * @maxLength 256
     * @example "My OAuth2 App"
     */
    name?: string;
    /**
     * OAuth2 application description
     * @maxLength 1024
     * @example "App description"
     */
    description?: string;
    /**
     * Title for the application button
     * @maxLength 256
     * @example "App title"
     */
    title?: string;
    /** OAuth2 provider */
    provider: Provider;
    /**
     * Is the application enabled
     * @example true
     */
    enabled?: boolean;
    /**
     * `true` for older OAuth2 apps set via the settings endpoint
     * @example true
     */
    legacy?: boolean;
    /**
     * The time this entry was added
     * @format date-time
     * @example "2021-02-17T13:43:18.860Z"
     */
    created: string;
    /**
     * The time this entry was updated
     * @format date-time
     * @example "2021-02-17T13:43:18.860Z"
     */
    updated?: string;
    /**
     * Is the application listed in the hosted authentication form
     * @example true
     */
    includeInListing?: boolean;
    /**
     * Client or Application ID for 3-legged OAuth2 applications
     * @example "4f05f488-d858-4f2c-bd12-1039062612fe"
     */
    clientId?: string;
    /**
     * Client secret for 3-legged OAuth2 applications. Actual value is not revealed.
     * @example "******"
     */
    clientSecret?: string;
    /**
     * Authorization tenant value for Outlook OAuth2 applications
     * @example "common"
     */
    authority?: string;
    /**
     * Redirect URL for 3-legged OAuth2 applications
     * @example "https://myservice.com/oauth"
     */
    redirectUrl?: string;
    /**
     * Service client ID for 2-legged OAuth2 applications
     * @example "9103965568215821627203"
     */
    serviceClient?: string;
    /**
     * PEM formatted service secret for 2-legged OAuth2 applications. Actual value is not revealed.
     * @example "******"
     */
    serviceKey?: string;
    lastError?: AccountErrorEntry;
}

export type OAuth2Entries = OAuth2ResponseItem[];

export interface OAuth2FilterResponse {
    /**
     * How many matching entries
     * @example 120
     */
    total?: number;
    /**
     * Current page (0-based index)
     * @example 0
     */
    page?: number;
    /**
     * Total page count
     * @example 24
     */
    pages?: number;
    apps?: OAuth2Entries;
}

/**
 * How this message was added to the queue
 * @example "smtp"
 */
export type Source = "smtp" | "api";

export type To = string[];

/** SMTP envelope */
export interface Envelope {
    /** @example "sender@example.com" */
    from?: string;
    to?: To;
}

/**
 * Current state of the sending
 * @example "queued"
 */
export type Status = "queued" | "processing" | "submitted" | "error";

/** Error information if state=error */
export interface OutboxListProgressError {
    /**
     * Error message
     * @example "Authentication failed"
     */
    message?: string;
    /**
     * Error code
     * @example "EAUTH"
     */
    code?: string;
    /**
     * SMTP response code
     * @example 502
     */
    statusCode?: string;
}

export interface OutboxListProgress {
    /** Current state of the sending */
    status?: Status;
    /**
     * Response from the SMTP server. Only if state=processing
     * @example "250 Message Accepted"
     */
    response?: string;
    /** Error information if state=error */
    error?: OutboxListProgressError;
}

export interface OutboxListItem {
    /**
     * Outbox queue ID
     * @example "1869c5692565f756b33"
     */
    queueId?: string;
    /**
     * Account ID
     * @maxLength 256
     * @example "example"
     */
    account: string;
    /** How this message was added to the queue */
    source?: Source;
    /**
     * Message ID
     * @maxLength 996
     * @example "<test123@example.com>"
     */
    messageId?: string;
    /** SMTP envelope */
    envelope?: Envelope;
    /**
     * Message subject
     * @maxLength 10240
     * @example "What a wonderful message"
     */
    subject?: string;
    /**
     * The time this message was queued
     * @format date-time
     * @example "2021-02-17T13:43:18.860Z"
     */
    created?: string;
    /**
     * When this message is supposed to be delivered
     * @format date-time
     * @example "2021-02-17T13:43:18.860Z"
     */
    scheduled?: string;
    /**
     * Next delivery attempt
     * @format date-time
     * @example "2021-02-17T13:43:18.860Z"
     */
    nextAttempt?: string;
    /**
     * How many times EmailEngine has tried to deliver this email
     * @example 3
     */
    attemptsMade?: number;
    /**
     * How many delivery attempts to make until message is considered as failed
     * @example 3
     */
    attempts?: number;
    progress?: OutboxListProgress;
}

export type OutboxListEntries = OutboxListItem[];

export interface OutboxListResponse {
    /**
     * How many matching entries
     * @example 120
     */
    total?: number;
    /**
     * Current page (0-based index)
     * @example 0
     */
    page?: number;
    /**
     * Total page count
     * @example 24
     */
    pages?: number;
    messages?: OutboxListEntries;
}

export type WebhookEvents = string[];

export interface WebhooksCustomHeader {
    /** @maxLength 1024 */
    key: string;
    /**
     * @maxLength 10240
     * @default ""
     */
    value?: string;
}

export type WebhooksCustomHeaders = WebhooksCustomHeader[];

export type NotifyHeaders = string[];

/** Gmail OAuth2 Extra Scopes (deprecated) */
export type GmailExtraScopes = string[];

/**
 * Outlook OAuth2 authority (deprecated)
 * @example "consumers"
 */
export type OutlookAuthority = "consumers" | "organizations" | "common";

/** Outlook OAuth2 Extra Scopes (deprecated) */
export type OutlookExtraScopes = string[];

/** Mail.ru OAuth2 Extra Scopes (deprecated) */
export type MailRuExtraScopes = string[];

/** OAuth2 Service Extra Scopes (deprecated) */
export type ServiceExtraScopes = string[];

export interface LogSettings {
    /**
     * Enable logs for all accounts
     * @default false
     * @example false
     */
    all?: boolean;
    /**
     * @min 0
     * @max 1000000
     * @default 10000
     */
    maxLogLines?: number;
}

/** How to select local IP address for IMAP connections */
export type ImapStrategy = "default" | "dedicated" | "random";

/** How to select local IP address for SMTP connections */
export type SmtpStrategy = "default" | "dedicated" | "random";

/** A list of pooled local IP addresses that can be used for IMAP and SMTP connections */
export type LocalAddresses = string[];

/**
 * Default locale identifier
 * @maxLength 100
 * @example "fr"
 */
export type Locale = "en" | "et" | "fr" | "de";

export interface SettingsQueryResponse {
    /** If false then do not emit webhooks */
    webhooksEnabled?: boolean;
    /**
     * Webhook URL
     * @example "https://myservice.com/imap/webhooks"
     */
    webhooks?: string;
    webhookEvents?: WebhookEvents;
    webhooksCustomHeaders?: WebhooksCustomHeaders;
    notifyHeaders?: NotifyHeaders;
    /**
     * Base URL of EmailEngine
     * @example "https://emailengine.example.com"
     */
    serviceUrl?: string;
    /** If true, then rewrite html links in sent emails to track opens and clicks */
    trackSentMessages?: boolean;
    /** If true, then resolve the category tab for incoming emails */
    resolveGmailCategories?: boolean;
    /** If true, then allow insecure certificates for IMAP/SMTP */
    ignoreMailCertErrors?: boolean;
    /** If true, then extracts reply text using OpenAI ChatGPT */
    generateEmailSummary?: boolean;
    /** (deprecated, not used) */
    generateRiskAssessment?: boolean;
    /**
     * OpenAI API key
     * @example "verysecr8t"
     */
    openAiAPIKey?: string;
    /**
     * OpenAI API Model
     * @example "gpt-3.5-turbo"
     */
    openAiModel?: string;
    /**
     * OpenAI API URL
     * @example "https://api.openai.com"
     */
    openAiAPIUrl?: string;
    /**
     * OpenAI API Model for chat
     * @example "gpt-3.5-turbo"
     */
    documentStoreChatModel?: string;
    /**
     * OpenAI Temperature
     * @min 0
     * @max 2
     * @example 0.8
     */
    openAiTemperature?: number;
    /**
     * Top-p sampling
     * @min 0
     * @max 1
     * @example 0.1
     */
    openAiTopP?: number;
    /**
     * Prompt to send to LLM for analyzing emails
     * @maxLength 6144
     * @example "You are an assistant scanning incoming emails..."
     */
    openAiPrompt?: string;
    /** If true, then generates vector embeddings for the email */
    openAiGenerateEmbeddings?: boolean;
    /** If true, then send "New Email" webhooks for incoming emails only */
    inboxNewOnly?: boolean;
    /**
     * HMAC secret for signing public requests
     * @example "verysecr8t"
     */
    serviceSecret?: string;
    /**
     * URL to fetch authentication data from
     * @example "https://myservice.com/authentication"
     */
    authServer?: string;
    /** Is the global proxy enabled or not */
    proxyEnabled?: boolean;
    /**
     * Proxy URL
     * @example "socks://proxy.example.com:1080"
     */
    proxyUrl?: string;
    /**
     * Hostname to use for the SMTP EHLO/HELO greeting
     * @example "my.proxy.tld"
     */
    smtpEhloName?: string;
    /** Include message text in webhook notification */
    notifyText?: boolean;
    /** Pre-process HTML in webhook notification to be web safe */
    notifyWebSafeHtml?: boolean;
    /** @min 0 */
    notifyTextSize?: number;
    /** Include calendar events in webhook notification */
    notifyCalendarEvents?: boolean;
    /** If true then do not show Gmail account option (deprecated) */
    gmailEnabled?: boolean;
    /**
     * Gmail OAuth2 Client ID (deprecated)
     * @maxLength 256
     */
    gmailClientId?: string;
    /**
     * Gmail OAuth2 Client Secret (deprecated)
     * @maxLength 256
     */
    gmailClientSecret?: string;
    /** Gmail OAuth2 Callback URL (deprecated) */
    gmailRedirectUrl?: string;
    /** Gmail OAuth2 Extra Scopes (deprecated) */
    gmailExtraScopes?: GmailExtraScopes;
    /** If true then do not show Outlook account option (deprecated) */
    outlookEnabled?: boolean;
    /**
     * Outlook OAuth2 Client ID (deprecated)
     * @maxLength 256
     */
    outlookClientId?: string;
    /**
     * Outlook OAuth2 Client Secret (deprecated)
     * @maxLength 256
     */
    outlookClientSecret?: string;
    /** Outlook OAuth2 Callback URL (deprecated) */
    outlookRedirectUrl?: string;
    /** Outlook OAuth2 authority (deprecated) */
    outlookAuthority?: OutlookAuthority;
    /** Outlook OAuth2 Extra Scopes (deprecated) */
    outlookExtraScopes?: OutlookExtraScopes;
    /** If true then do not show Mail.ru account option (deprecated) */
    mailRuEnabled?: boolean;
    /**
     * Mail.ru OAuth2 Client ID (deprecated)
     * @maxLength 256
     */
    mailRuClientId?: string;
    /**
     * Mail.ru OAuth2 Client Secret (deprecated)
     * @maxLength 256
     */
    mailRuClientSecret?: string;
    /** Mail.ru OAuth2 Callback URL (deprecated) */
    mailRuRedirectUrl?: string;
    /** Mail.ru OAuth2 Extra Scopes (deprecated) */
    mailRuExtraScopes?: MailRuExtraScopes;
    /**
     * OAuth2 Service Client ID (deprecated)
     * @maxLength 256
     */
    serviceClient?: string;
    /**
     * OAuth2 Secret Service Key (deprecated)
     * @maxLength 102400
     */
    serviceKey?: string;
    /** OAuth2 Service Extra Scopes (deprecated) */
    serviceExtraScopes?: ServiceExtraScopes;
    logs?: LogSettings;
    /** How to select local IP address for IMAP connections */
    imapStrategy?: ImapStrategy;
    /** How to select local IP address for SMTP connections */
    smtpStrategy?: SmtpStrategy;
    /** A list of pooled local IP addresses that can be used for IMAP and SMTP connections */
    localAddresses?: LocalAddresses;
    /** Enable SMTP Interface */
    smtpServerEnabled?: boolean;
    /**
     * SMTP Interface Port
     * @min 0
     * @max 65536
     */
    smtpServerPort?: number;
    /** SMTP Host to bind to */
    smtpServerHost?: string;
    /** Enable PROXY Protocol for SMTP server */
    smtpServerProxy?: boolean;
    /** Enable SMTP authentication */
    smtpServerAuthEnabled?: boolean;
    /**
     * SMTP client password. Set to null to disable.
     * @maxLength 1024
     */
    smtpServerPassword?: string;
    /** Enable TLS for the SMTP interface. Requires a valid certificate. */
    smtpServerTLSEnabled?: boolean;
    /** Enable IMAP Proxy Interface */
    imapProxyServerEnabled?: boolean;
    /**
     * IMAP Proxy Interface Port
     * @min 0
     * @max 65536
     */
    imapProxyServerPort?: number;
    /** IMAP Proxy Host to bind to */
    imapProxyServerHost?: string;
    /** Enable PROXY Protocol for the IMAP proxy server */
    imapProxyServerProxy?: boolean;
    /**
     * IMAP proxy client password. Set to null to disable.
     * @maxLength 1024
     */
    imapProxyServerPassword?: string;
    /** Enable TLS for the IMAP proxy interface. Requires a valid certificate. */
    imapProxyServerTLSEnabled?: boolean;
    /**
     * How many completed or failed queue entries to keep
     * @min 0
     */
    queueKeep?: number;
    /**
     * How many times to retry an email sending before it is considered as failing
     * @min 0
     */
    deliveryAttempts?: number;
    /**
     * HTML code displayed on the top of public pages like the hosted authentication form
     * @maxLength 1048576
     */
    templateHeader?: string;
    /**
     * JSON object to be used as the `env` variable in pre-processing scripts
     * @maxLength 1048576
     * @example "{"key": "value"}"
     */
    scriptEnv?: string;
    /** Enable support for reverse proxies */
    enableApiProxy?: boolean;
    /** Enable Document Store syncing */
    documentStoreEnabled?: boolean;
    /**
     * Document Store URL
     * @example "https://localhost:9200"
     */
    documentStoreUrl?: string;
    /**
     * Document Store index name
     * @maxLength 1024
     */
    documentStoreIndex?: string;
    /** Enable Document Store authentication */
    documentStoreAuthEnabled?: boolean;
    /**
     * Document Store username
     * @maxLength 1024
     */
    documentStoreUsername?: string;
    /**
     * Document Store password
     * @maxLength 1024
     */
    documentStorePassword?: string;
    /** If true, then generates vector embeddings for the email and stores these in the Document Store */
    documentStoreGenerateEmbeddings?: boolean;
    /** Enable Document Store pre-processing */
    documentStorePreProcessingEnabled?: boolean;
    /** Default locale identifier */
    locale?: Locale;
    /**
     * Default timezone identifier
     * @maxLength 100
     * @example "Europe/Tallinn"
     */
    timezone?: string;
    /**
     * Filter function for LLM pre-processing (JavaScript)
     * @maxLength 524288
     * @example "return true; // passes all emails"
     */
    openAiPreProcessingFn?: string;
    /**
     * Filter function for Document Store pre-processing (JavaScript)
     * @maxLength 524288
     * @example "return true; // passes all emails"
     */
    documentStorePreProcessingFn?: string;
    /**
     * Mapping function for Document Store pre-processing (JavaScript)
     * @maxLength 524288
     * @example "return payload; // returns unmodified data"
     */
    documentStorePreProcessingMap?: string;
}

/** Counts of accounts in different connection states */
export interface ConnectionsStats {
    /**
     * Accounts not yet initialized
     * @example 2
     */
    init?: number;
    /**
     * Successfully connected accounts
     * @example 8
     */
    connected?: number;
    /**
     * Connection is being established
     * @example 7
     */
    connecting?: number;
    /**
     * Authentication failed
     * @example 3
     */
    authenticationError?: number;
    /**
     * Connection failed due to technical error
     * @example 5
     */
    connectError?: number;
    /**
     * Accounts without valid IMAP settings
     * @example 0
     */
    unset?: number;
    /**
     * IMAP connection was closed
     * @example 1
     */
    disconnected?: number;
}

export type CounterStats = object;

export interface SettingsResponse {
    /**
     * EmailEngine version number
     * @example "2.39.0"
     */
    version?: string;
    /**
     * EmailEngine license
     * @example "LICENSE_EMAILENGINE"
     */
    license?: string;
    /**
     * Number of registered accounts
     * @example 26
     */
    accounts?: number;
    /**
     * Node.js Version
     * @example "16.10.0"
     */
    node?: string;
    /**
     * Redis Version
     * @example "6.2.4"
     */
    redis?: string;
    /** Counts of accounts in different connection states */
    connections?: ConnectionsStats;
    counters?: CounterStats;
}

/**
 * Markup language for HTML ("html", "markdown" or "mjml")
 * @default "html"
 */
export type Format = "html" | "mjml" | "markdown";

export interface AccountTemplate {
    /**
     * Template ID
     * @maxLength 256
     * @example "AAABgS-UcAYAAAABAA"
     */
    id: string;
    /**
     * Name of the template
     * @maxLength 256
     * @example "Transaction receipt"
     */
    name: string;
    /**
     * Optional description of the template
     * @maxLength 1024
     * @example "Something about the template"
     */
    description?: string;
    /** Markup language for HTML ("html", "markdown" or "mjml") */
    format?: Format;
    /**
     * The time this template was created
     * @format date-time
     * @example "2021-02-17T13:43:18.860Z"
     */
    created?: string;
    /**
     * The time this template was last updated
     * @format date-time
     * @example "2021-02-17T13:43:18.860Z"
     */
    updated?: string;
}

export type AccountTemplatesList = AccountTemplate[];

export interface AccountTemplatesResponse {
    /**
     * Account ID
     * @maxLength 256
     * @example "example"
     */
    account: string;
    /**
     * How many matching entries
     * @example 120
     */
    total?: number;
    /**
     * Current page (0-based index)
     * @example 0
     */
    page?: number;
    /**
     * Total page count
     * @example 24
     */
    pages?: number;
    templates?: AccountTemplatesList;
}

export interface RootTokensItem {
    /**
     * Account ID
     * @maxLength 256
     * @example "example"
     */
    account: string;
    /**
     * Token description
     * @maxLength 1024
     * @example "Token description"
     */
    description: string;
    /**
     * Related metadata in JSON format
     * @maxLength 1048576
     * @example "{"example": "value"}"
     */
    metadata?: string;
    /**
     * IP address of the requestor
     * @example "127.0.0.1"
     */
    ip?: string;
}

export type RootTokensEntries = RootTokensItem[];

export interface RootTokensResponse {
    tokens?: RootTokensEntries;
}

export interface WebhookRoutesListEntry {
    /**
     * Webhook ID
     * @maxLength 256
     * @example "AAABgS-UcAYAAAABAA"
     */
    id: string;
    /**
     * Name of the route
     * @maxLength 256
     * @example "Send to Slack"
     */
    name: string;
    /**
     * Optional description of the webhook route
     * @maxLength 1024
     * @example "Something about the route"
     */
    description?: string;
    /**
     * The time this route was created
     * @format date-time
     * @example "2021-02-17T13:43:18.860Z"
     */
    created?: string;
    /**
     * The time this route was last updated
     * @format date-time
     * @example "2021-02-17T13:43:18.860Z"
     */
    updated?: string;
    /**
     * Is the route enabled
     * @example true
     */
    enabled?: boolean;
    /**
     * Webhook URL
     * @example "https://myservice.com/imap/webhooks"
     */
    targetUrl?: string;
    /**
     * How many times this route has been applied
     * @example 123
     */
    tcount?: number;
}

export type WebhookRoutesList = WebhookRoutesListEntry[];

export interface WebhookRoutesListResponse {
    /**
     * How many matching entries
     * @example 120
     */
    total?: number;
    /**
     * Current page (0-based index)
     * @example 0
     */
    page?: number;
    /**
     * Total page count
     * @example 24
     */
    pages?: number;
    webhooks?: WebhookRoutesList;
}

/**
 * An array of mailbox paths. If set, then EmailEngine opens additional IMAP connections against these paths to detect changes faster. NB! connection counts are usually highly limited.
 * @example ["[Gmail]/Spam","\\Sent"]
 */
export type SubconnectionPaths = string[];

/**
 * Access token for OAuth2
 * @maxLength 16384
 * @example false
 */
export type AccessToken = "false";

/** Authentication info */
export type Authentication = "false";

/** Optional TLS configuration */
export interface TLS {
    /**
     * How to treat invalid certificates
     * @default true
     * @example true
     */
    rejectUnauthorized?: boolean;
    /**
     * Minimal TLS version
     * @maxLength 256
     * @example "TLSv1.2"
     */
    minVersion?: string;
}

/** IMAP configuration */
export interface IMAPResponse {
    /** Authentication info */
    auth?: Authentication;
    /**
     * Set to true to use authentication server instead of username/password
     * @example false
     */
    useAuthServer?: boolean;
    /**
     * Hostname to connect to
     * @example "imap.gmail.com"
     */
    host?: string;
    /**
     * Service port number
     * @min 1
     * @max 65536
     * @example 993
     */
    port?: number;
    /**
     * Should connection use TLS. Usually true for port 993
     * @default false
     * @example true
     */
    secure?: boolean;
    /** Optional TLS configuration */
    tls?: TLS;
    /**
     * Full resync delay in seconds
     * @default 900
     * @example 900
     */
    resyncDelay?: number;
    /**
     * Set to true to disable IMAP handling
     * @example false
     */
    disabled?: boolean;
    /**
     * Upload sent message to this folder. By default the account's Sent Mail folder is used. Set to `null` to unset.
     * @maxLength 1024
     * @example "Sent Mail"
     */
    sentMailPath?: string;
    /**
     * Folder for drafts. By default the account's Draft Mail folder is used. Set to `null` to unset.
     * @maxLength 1024
     * @example "Drafts"
     */
    draftsMailPath?: string;
    /**
     * Folder for spam. By default the account's Junk Mail folder is used. Set to `null` to unset.
     * @maxLength 1024
     * @example "Junk"
     */
    junkMailPath?: string;
    /**
     * Folder for deleted emails. By default the account's Trash folder is used. Set to `null` to unset.
     * @maxLength 1024
     * @example "Trash"
     */
    trashMailPath?: string;
    /**
     * Folder for archived emails. By default the account's Archive folder is used. Set to `null` to unset.
     * @maxLength 1024
     * @example "Archive"
     */
    archiveMailPath?: string;
}

/** SMTP configuration */
export interface SMTPResponse {
    /** Authentication info */
    auth?: Authentication;
    /**
     * Set to true to use authentication server instead of username/password
     * @example false
     */
    useAuthServer?: boolean;
    /**
     * Hostname to connect to
     * @example "smtp.gmail.com"
     */
    host: string;
    /**
     * Service port number
     * @min 1
     * @max 65536
     * @example 587
     */
    port: number;
    /**
     * Should connection use TLS. Usually true for port 465
     * @default false
     * @example false
     */
    secure?: boolean;
    /** Optional TLS configuration */
    tls?: TLS;
}

export interface Auth {
    /**
     * Account username
     * @maxLength 256
     * @example "user@oulook.com"
     */
    user: string;
    /**
     * Shared mailbox username (MS365 only)
     * @maxLength 256
     * @example "shared.mailbox@oulook.com"
     */
    delegatedUser?: string;
}

/** OAuth2 configuration */
export interface Oauth2Response {
    /**
     * Return a redirect link to the OAuth2 consent screen
     * @example false
     */
    authorize?: boolean;
    /**
     * The user will be redirected to this URL after returning from the OAuth2 consent screen (only valid if `authorize=true`
     * @example "https://myapp/account/settings.php"
     */
    redirectUrl?: string;
    /**
     * OAuth provider
     * @maxLength 256
     * @example "gmail"
     */
    provider?: string;
    auth?: Auth;
    /**
     * @maxLength 16384
     * @example "ya29.a0ARrdaM8a..."
     */
    accessToken?: string;
    /**
     * @maxLength 16384
     * @example "1//09Ie3CtORQYm..."
     */
    refreshToken?: string;
    /**
     * Outloook account type. Either 'consumers', 'organizations', 'common' or an organizartion ID
     * @maxLength 1024
     * @example "consumers"
     */
    authority?: string;
    /**
     * Token expiration date
     * @format date-time
     * @example "2021-03-22T13:13:31.000Z"
     */
    expires?: string;
}

/**
 * Informational account state
 * @example "connected"
 */
export type AccountInfoState = "init" | "syncing" | "connecting" | "connected" | "authenticationError" | "connectError" | "unset" | "disconnected";

/** Information about the last SMTP connection attempt */
export type SMTPInfoStatus = object;

export interface AccountResponse {
    /**
     * Account ID
     * @maxLength 256
     * @example "example"
     */
    account: string;
    /**
     * Display name for the account
     * @maxLength 256
     * @example "My Email Account"
     */
    name: string;
    /**
     * Default email address of the account
     * @example "user@example.com"
     */
    email?: string;
    /**
     * Copy submitted messages to Sent folder
     * @example true
     */
    copy?: boolean;
    /**
     * Store recent logs
     * @example false
     */
    logs?: boolean;
    /**
     * Send webhooks for messages starting from provided date. The default is the account creation date.
     * @format date-time
     * @example "2021-07-08T07:06:34.336Z"
     */
    notifyFrom?: string;
    /**
     * Sync messages to document store starting from provided date. If not set, all emails are synced.
     * @format date-time
     * @example "2021-07-08T07:06:34.336Z"
     */
    syncFrom?: string;
    /**
     * Check changes only on selected path
     * @maxLength 1024
     * @default "*"
     * @example "INBOX"
     */
    path?: string;
    /** An array of mailbox paths. If set, then EmailEngine opens additional IMAP connections against these paths to detect changes faster. NB! connection counts are usually highly limited. */
    subconnections?: SubconnectionPaths;
    /**
     * Account-specific webhook URL
     * @example "https://myservice.com/imap/webhooks"
     */
    webhooks?: string;
    /**
     * Proxy URL
     * @example "socks://proxy.example.com:1080"
     */
    proxy?: string;
    /**
     * Hostname to use for the SMTP EHLO/HELO greeting
     * @example "my.proxy.tld"
     */
    smtpEhloName?: string;
    /** IMAP configuration */
    imap?: IMAPResponse;
    /** SMTP configuration */
    smtp?: SMTPResponse;
    /** OAuth2 configuration */
    oauth2?: Oauth2Response;
    /** Informational account state */
    state?: AccountInfoState;
    /** Information about the last SMTP connection attempt */
    smtpStatus?: SMTPInfoStatus;
    /**
     * Optional locale
     * @maxLength 100
     * @example "fr"
     */
    locale?: string;
    /**
     * Optional timezone
     * @maxLength 100
     * @example "Europe/Tallinn"
     */
    tz?: string;
    /** Account type */
    type: Type;
    /**
     * OAuth2 application ID
     * @maxLength 256
     * @example "AAABhaBPHscAAAAH"
     */
    app?: string;
    counters?: AccountCounters;
    /**
     * Last sync time
     * @format date-time
     * @example "2021-02-17T13:43:18.860Z"
     */
    syncTime?: string;
    lastError?: AccountErrorEntry;
}

export interface BlocklistListResponseItem {
    /**
     * Listed email address
     * @example "user@example.com"
     */
    recipient: string;
    /**
     * Account ID
     * @maxLength 256
     * @example "example"
     */
    account: string;
    /**
     * Message ID
     * @example "<test123@example.com>"
     */
    messageId?: string;
    /**
     * Which mechanism was used to add the entry
     * @example "api"
     */
    source?: string;
    /**
     * Why this entry was added
     * @example "api"
     */
    reason?: string;
    /** Which IP address triggered the entry */
    remoteAddress?: string;
    /**
     * Which user agent triggered the entry
     * @example "Mozilla/5.0 (Macintosh)"
     */
    userAgent?: string;
    /**
     * The time this entry was added or updated
     * @format date-time
     * @example "2021-02-17T13:43:18.860Z"
     */
    created: string;
}

export type BlocklistListEntries = BlocklistListResponseItem[];

export interface BlocklistListResponse {
    /**
     * List ID
     * @maxLength 256
     * @example "example"
     */
    listId: string;
    /**
     * How many matching entries
     * @example 120
     */
    total?: number;
    /**
     * Current page (0-based index)
     * @example 0
     */
    page?: number;
    /**
     * Total page count
     * @example 24
     */
    pages?: number;
    addresses?: BlocklistListEntries;
}

export interface GatewayResponse {
    /**
     * Gateway ID
     * @maxLength 256
     * @example "example"
     */
    gateway: string;
    /**
     * Display name for the gateway
     * @maxLength 256
     * @example "My Email Gateway"
     */
    name: string;
    /**
     * Count of email deliveries using this gateway
     * @example 100
     */
    deliveries?: number;
    /**
     * Last delivery time
     * @format date-time
     * @example "2021-02-17T13:43:18.860Z"
     */
    lastUse?: string;
    /** @maxLength 1024 */
    user?: string;
    /** @maxLength 1024 */
    pass?: string;
    /**
     * Hostname to connect to
     * @example "smtp.gmail.com"
     */
    host?: string;
    /**
     * Service port number
     * @min 1
     * @max 65536
     * @example 465
     */
    port?: number;
    /**
     * Should connection use TLS. Usually true for port 465
     * @default false
     * @example true
     */
    secure?: boolean;
    lastError?: AccountErrorEntry;
}

export interface ApplicationResponse {
    /**
     * OAuth2 application ID
     * @maxLength 256
     * @example "AAABhaBPHscAAAAH"
     */
    id: string;
    /**
     * Display name for the app
     * @maxLength 256
     * @example "My OAuth2 App"
     */
    name?: string;
    /**
     * OAuth2 application description
     * @maxLength 1024
     * @example "App description"
     */
    description?: string;
    /**
     * Title for the application button
     * @maxLength 256
     * @example "App title"
     */
    title?: string;
    /** OAuth2 provider */
    provider: Provider;
    /**
     * Is the application enabled
     * @example true
     */
    enabled?: boolean;
    /**
     * `true` for older OAuth2 apps set via the settings endpoint
     * @example true
     */
    legacy?: boolean;
    /**
     * The time this entry was added
     * @format date-time
     * @example "2021-02-17T13:43:18.860Z"
     */
    created: string;
    /**
     * The time this entry was updated
     * @format date-time
     * @example "2021-02-17T13:43:18.860Z"
     */
    updated?: string;
    /**
     * Is the application listed in the hosted authentication form
     * @example true
     */
    includeInListing?: boolean;
    /**
     * Client or Application ID for 3-legged OAuth2 applications
     * @example "4f05f488-d858-4f2c-bd12-1039062612fe"
     */
    clientId?: string;
    /**
     * Client secret for 3-legged OAuth2 applications. Actual value is not revealed.
     * @example "******"
     */
    clientSecret?: string;
    /**
     * Authorization tenant value for Outlook OAuth2 applications
     * @example "common"
     */
    authority?: string;
    /**
     * Redirect URL for 3-legged OAuth2 applications
     * @example "https://myservice.com/oauth"
     */
    redirectUrl?: string;
    /**
     * Service client ID for 2-legged OAuth2 applications
     * @example "9103965568215821627203"
     */
    serviceClient?: string;
    /**
     * PEM formatted service secret for 2-legged OAuth2 applications. Actual value is not revealed.
     * @example "******"
     */
    serviceKey?: string;
    /**
     * The number of accounts registered with this application. Not available for legacy apps.
     * @example 12
     */
    accounts?: number;
    lastError?: AccountErrorEntry;
}

export interface AccountTokenResponse {
    /**
     * Account ID
     * @maxLength 256
     * @example "example"
     */
    account: string;
    /**
     * Username
     * @maxLength 256
     * @example "user@example.com"
     */
    user: string;
    /**
     * Access Token
     * @maxLength 256
     * @example "aGVsbG8gd29ybGQ="
     */
    accessToken: string;
    /**
     * OAuth2 provider
     * @maxLength 256
     * @example "gmail"
     */
    provider?: string;
}

/** @example {"name":"From Me","address":"sender@example.com"} */
export interface FromAddress {
    /**
     * @maxLength 256
     * @example "Some Name"
     */
    name?: string;
    /** @example "user@example.com" */
    address: string;
}

export interface Model1 {
    /**
     * @maxLength 256
     * @example "Some Name"
     */
    name?: string;
    /** @example "user@example.com" */
    address: string;
}

/**
 * List of addresses
 * @example [{"address":"recipient@example.com"}]
 */
export type AddressList = Model1[];

/** List of addresses */
export type Model2 = Model1[];

/** List of addresses */
export type Model3 = Model1[];

/** IMAP flags */
export type FlagList = string[];

/** Gmail labels */
export type LabelList = string[];

export interface Model4 {
    /**
     * Attachment ID
     * @example "AAAAAgAACrIyLjI"
     */
    id?: string;
    /**
     * Mime type of the attachment
     * @example "image/gif"
     */
    contentType?: string;
    /**
     * Encoded size of the attachment. Actual file size is usually smaller depending on the encoding
     * @example 48
     */
    encodedSize?: number;
    /**
     * Is this image used in HTML img tag
     * @example true
     */
    embedded?: boolean;
    /**
     * Should this file be included in the message preview somehow
     * @example true
     */
    inline?: boolean;
    /**
     * Usually used only for embedded images
     * @example "<unique-image-id@localhost>"
     */
    contentId?: string;
    /**
     * The file name of the attachment
     * @example "image.png"
     */
    filename?: string;
    /**
     * Calendar event method if this is an ical event attachment
     * @example "REQUEST"
     */
    method?: string;
}

/** List of attachments */
export type AttachmentList = Model4[];

/** Encoded message part sizes */
export interface EncodedSize {
    /**
     * How many bytes for plain text
     * @example 1013
     */
    plain?: number;
    /**
     * How many bytes for html content
     * @example 1013
     */
    html?: number;
}

export interface TextInfo {
    /**
     * Pointer to message text content
     * @example "AAAAAgAACqiTkaExkaEykA"
     */
    id?: string;
    /** Encoded message part sizes */
    encodedSize?: EncodedSize;
}

export interface MessageListEntry {
    /**
     * Message ID
     * @example "AAAAAgAACrI"
     */
    id?: string;
    /**
     * UID of the message
     * @example 12345
     */
    uid?: number;
    /**
     * Globally unique ID (if server supports it)
     * @example "1694937972638499881"
     */
    emailId?: string;
    /**
     * Thread ID (if server supports it)
     * @example "1694936993596975454"
     */
    threadId?: string;
    /**
     * Date (internal)
     * @format date-time
     * @example "2021-03-22T13:13:31.000Z"
     */
    date?: string;
    /**
     * Is this message marked as a draft
     * @example false
     */
    draft?: boolean;
    /**
     * Is this message unseen
     * @example true
     */
    unseen?: boolean;
    /**
     * Is this message marked as flagged
     * @example true
     */
    flagged?: boolean;
    /**
     * Message size in bytes
     * @example 1040
     */
    size?: number;
    /**
     * Message subject (decoded into unicode, applies to other string values as well)
     * @example "What a wonderful message"
     */
    subject?: string;
    from?: FromAddress;
    /** List of addresses */
    replyTo?: AddressList;
    /** List of addresses */
    to?: AddressList;
    /** List of addresses */
    cc?: Model2;
    /** List of addresses */
    bcc?: Model3;
    /**
     * Message ID
     * @example "<test123@example.com>"
     */
    messageId?: string;
    /**
     * Replied Message ID
     * @example "<7JBUMt0WOn+_==MOkaCOQ@mail.gmail.com>"
     */
    inReplyTo?: string;
    /** IMAP flags */
    flags?: FlagList;
    /** Gmail labels */
    labels?: LabelList;
    /** List of attachments */
    attachments?: AttachmentList;
    text?: TextInfo;
    /** Text preview for messages loaded from Document Store */
    preview?: string;
}

export type PageMessages = MessageListEntry[];

export interface MessageList {
    /**
     * How many matching entries
     * @example 120
     */
    total?: number;
    /**
     * Current page (0-based index)
     * @example 0
     */
    page?: number;
    /**
     * Total page count
     * @example 24
     */
    pages?: number;
    messages?: PageMessages;
}

/**
 * Special use flag of the mailbox
 * @example "\Sent"
 */
export type MailboxSpecialUse = "All" | "Archive" | "Drafts" | "Flagged" | "Junk" | "Sent" | "Trash" | "Inbox";

/**
 * Where did EmailEngine get the specialUse flag. The source could be `"user"` if it was set through an account creation or update API call, `"extension"` if it was provided by the email server, or `"name"` if EmailEngine determined it based on the folder's name.
 * @example "extension"
 */
export type MailboxSpecialUseSource = "user" | "extension" | "name";

/** Optional counters info */
export interface MailboxResponseStatus {
    /**
     * Count of messages in mailbox as reported by the STATUS command
     * @example 120
     */
    messages?: number;
    /**
     * Count of unseen messages in mailbox as reported by the STATUS command
     * @example 120
     */
    unseen?: number;
}

export interface MailboxResponseItem {
    /**
     * Full path to mailbox
     * @example "Kalender/S&APw-nnip&AOQ-evad"
     */
    path: string;
    /** @example "/" */
    delimiter?: string;
    /**
     * Full path to parent mailbox
     * @example "Kalender"
     */
    parentPath: string;
    /**
     * Maibox name
     * @example "Sünnipäevad"
     */
    name: string;
    /**
     * Was the mailbox found from the output of LIST command
     * @example true
     */
    listed?: boolean;
    /**
     * Was the mailbox found from the output of LSUB command
     * @example true
     */
    subscribed?: boolean;
    /** Special use flag of the mailbox */
    specialUse?: MailboxSpecialUse;
    /** Where did EmailEngine get the specialUse flag. The source could be `"user"` if it was set through an account creation or update API call, `"extension"` if it was provided by the email server, or `"name"` if EmailEngine determined it based on the folder's name. */
    specialUseSource?: MailboxSpecialUseSource;
    /**
     * If true, then adding subfolders is forbidden
     * @example false
     */
    noInferiors?: boolean;
    /**
     * Count of messages in mailbox
     * @example 120
     */
    messages?: number;
    /**
     * Next expected UID
     * @example 121
     */
    uidNext?: number;
    /** Optional counters info */
    status?: MailboxResponseStatus;
}

export type Mailboxes = MailboxResponseItem[];

export interface MailboxesFilterResponse {
    mailboxes?: Mailboxes;
}

/** DKIM results */
export type Dkim = object;

/** SPF results */
export type Spf = object;

/** DMARC results */
export type Dmarc = object;

/** BIMI results */
export type Bimi = object;

/** ARC results */
export type Arc = object;

/** Primary DKIM signature. `status.aligned` should be set, otherwise DKIM check should not be considered as passed. */
export type MainSig = object;

export interface DeliveryCheckResponse {
    /**
     * Was the test completed
     * @example true
     */
    success?: boolean;
    /** DKIM results */
    dkim?: Dkim;
    /** SPF results */
    spf?: Spf;
    /** DMARC results */
    dmarc?: Dmarc;
    /** BIMI results */
    bimi?: Bimi;
    /** ARC results */
    arc?: Arc;
    /** Primary DKIM signature. `status.aligned` should be set, otherwise DKIM check should not be considered as passed. */
    mainSig?: MainSig;
}

/**
 * Queue ID
 * @example "notify"
 */
export type Queue = "notify" | "submit" | "documents";

export interface QueueJobs {
    /**
     * Jobs that are currently being processed
     * @example 123
     */
    active?: number;
    /**
     * Jobs that are processed in the future
     * @example 123
     */
    delayed?: number;
    /**
     * Jobs that would be processed once queue processing is resumed
     * @example 123
     */
    paused?: number;
    /**
     * Jobs that should be processed, but are waiting until there are any free handlers
     * @example 123
     */
    waiting?: number;
}

export interface SettingsQueueResponse {
    /** Queue ID */
    queue: Queue;
    jobs?: QueueJobs;
    /**
     * Is the queue paused or not
     * @example false
     */
    paused?: boolean;
}

export interface RequestTemplateContent {
    /**
     * Message subject
     * @maxLength 10240
     * @example "What a wonderful message"
     */
    subject?: string;
    /**
     * Message Text
     * @maxLength 5242880
     * @example "Hello from myself!"
     */
    text?: string;
    /**
     * Message HTML
     * @maxLength 5242880
     * @example "<p>Hello from myself!</p>"
     */
    html?: string;
    /**
     * Preview text appears in the inbox after the subject line
     * @maxLength 1024
     * @example "Welcome to our newsletter!"
     */
    previewText?: string;
    /** Markup language for HTML ("html", "markdown" or "mjml") */
    format?: Format;
}

export interface AccountTemplateResponse {
    /**
     * Account ID
     * @maxLength 256
     * @example "example"
     */
    account: string;
    /**
     * Template ID
     * @maxLength 256
     * @example "AAABgS-UcAYAAAABAA"
     */
    id: string;
    /**
     * Name of the template
     * @maxLength 256
     * @example "Transaction receipt"
     */
    name: string;
    /**
     * Optional description of the template
     * @maxLength 1024
     * @example "Something about the template"
     */
    description?: string;
    /** Markup language for HTML ("html", "markdown" or "mjml") */
    format?: Format;
    /**
     * The time this template was created
     * @format date-time
     * @example "2021-02-17T13:43:18.860Z"
     */
    created?: string;
    /**
     * The time this template was last updated
     * @format date-time
     * @example "2021-02-17T13:43:18.860Z"
     */
    updated?: string;
    content?: RequestTemplateContent;
}

/**
 * HTTP referrer allowlist for API requests
 * @default false
 * @example ["*web.domain.org/*","*.domain.org/*","https://domain.org/*"]
 */
export type ReferrerAllowlist = "false";

/**
 * IP address allowlist
 * @default false
 * @example ["1.2.3.4","5.6.7.8","127.0.0.0/8"]
 */
export type AddressAllowlist = "false";

/**
 * Rate limits for the token
 * @default false
 * @example {"maxRequests":20,"timeWindow":2}
 */
export type AddressRateLimit = "false";

/** Access restrictions */
export type TokenRestrictions = "false";

export interface AccountTokensItem {
    /**
     * Account ID
     * @maxLength 256
     * @example "example"
     */
    account: string;
    /**
     * Token description
     * @maxLength 1024
     * @example "Token description"
     */
    description: string;
    /**
     * Related metadata in JSON format
     * @maxLength 1048576
     * @example "{"example": "value"}"
     */
    metadata?: string;
    /** Access restrictions */
    restrictions?: TokenRestrictions;
    /**
     * IP address of the requestor
     * @example "127.0.0.1"
     */
    ip?: string;
}

export type AccountTokensEntries = AccountTokensItem[];

export interface AccountsTokensResponse {
    tokens?: AccountTokensEntries;
}

export interface WebhookRouteContent {
    /**
     * Filter function
     * @example "return true;"
     */
    fn?: string;
    /**
     * Mapping function
     * @example "payload.ts = Date.now(); return payload;"
     */
    map?: string;
}

export interface WebhookRouteResponse {
    /**
     * Webhook ID
     * @maxLength 256
     * @example "AAABgS-UcAYAAAABAA"
     */
    id: string;
    /**
     * Name of the route
     * @maxLength 256
     * @example "Send to Slack"
     */
    name: string;
    /**
     * Optional description of the webhook route
     * @maxLength 1024
     * @example "Something about the route"
     */
    description?: string;
    /**
     * The time this route was created
     * @format date-time
     * @example "2021-02-17T13:43:18.860Z"
     */
    created?: string;
    /**
     * The time this route was last updated
     * @format date-time
     * @example "2021-02-17T13:43:18.860Z"
     */
    updated?: string;
    /**
     * Is the route enabled
     * @example true
     */
    enabled?: boolean;
    /**
     * Webhook URL
     * @example "https://myservice.com/imap/webhooks"
     */
    targetUrl?: string;
    /**
     * How many times this route has been applied
     * @example 123
     */
    tcount?: number;
    content?: WebhookRouteContent;
}

/** List of addresses */
export type Model5 = Model1[];

/** List of addresses */
export type Model6 = Model1[];

/**
 * Object where header key is object key and value is an array
 * @example {"from":["From Me <sender@example.com>"],"subject":["What a wonderful message"]}
 */
export type MessageHeaders = object;

export interface Model7 {
    /**
     * Pointer to message text content. The value is `null` for messages retrieved from Document Store.
     * @example "AAAAAgAACqiTkaExkaEykA"
     */
    id?: string;
    /** Encoded message part sizes */
    encodedSize?: EncodedSize;
    /**
     * Plaintext content of the message
     * @example "Hello from myself!"
     */
    plain?: string;
    /**
     * HTML content of the message
     * @example "<p>Hello from myself!</p>"
     */
    html?: string;
    /**
     * If partial message content was requested then this value indicates if it includes all the content or there is more
     * @example false
     */
    hasMore?: boolean;
}

export interface BounceResponse {
    /** @example "550 5.1.1 No such user" */
    message?: string;
    /** @example "5.1.1" */
    status?: string;
}

export interface BounceEntry {
    /**
     * Bounce email ID
     * @maxLength 256
     * @example "AAAAAQAACnA"
     */
    message: string;
    /** @example "recipient@example.com" */
    recipient?: string;
    /** @example "failed" */
    action?: string;
    response?: BounceResponse;
    /**
     * Time the bounce was registered by EmailEngine
     * @format date-time
     * @example "2021-03-22T13:13:31.000Z"
     */
    date?: string;
}

export type BounceList = BounceEntry[];

export interface Model8 {
    /**
     * Message ID
     * @example "AAAAAgAACrI"
     */
    id?: string;
    /**
     * UID of the message
     * @example 12345
     */
    uid?: number;
    /**
     * Globally unique ID (if server supports it)
     * @example "1694937972638499881"
     */
    emailId?: string;
    /**
     * Thread ID (if server supports it). Always set for messages retrieved from Document Store.
     * @example "1694936993596975454"
     */
    threadId?: string;
    /**
     * Date (internal)
     * @format date-time
     * @example "2021-03-22T13:13:31.000Z"
     */
    date?: string;
    /**
     * Is this message marked as a draft
     * @example false
     */
    draft?: boolean;
    /**
     * Is this message unseen
     * @example true
     */
    unseen?: boolean;
    /**
     * Is this message marked as flagged
     * @example true
     */
    flagged?: boolean;
    /**
     * Message size in bytes
     * @example 1040
     */
    size?: number;
    /**
     * Message subject (decoded into unicode, applies to other string values as well)
     * @example "What a wonderful message"
     */
    subject?: string;
    from?: FromAddress;
    sender?: FromAddress;
    /** List of addresses */
    to?: AddressList;
    /** List of addresses */
    cc?: Model5;
    /** List of addresses */
    bcc?: Model6;
    /** List of addresses */
    replyTo?: AddressList;
    /**
     * Message ID
     * @example "<test123@example.com>"
     */
    messageId?: string;
    /**
     * Replied Message ID
     * @example "<7JBUMt0WOn+_==MOkaCOQ@mail.gmail.com>"
     */
    inReplyTo?: string;
    /** IMAP flags */
    flags?: FlagList;
    /** Gmail labels */
    labels?: LabelList;
    /** List of attachments */
    attachments?: AttachmentList;
    /** Object where header key is object key and value is an array */
    headers?: MessageHeaders;
    text?: Model7;
    bounces?: BounceList;
    /**
     * True if this message was detected to be an autoreply email like the Out of Office notice
     * @example false
     */
    isAutoReply?: boolean;
    /** Special use flag of the mailbox */
    specialUse?: MailboxSpecialUse;
    /** Special use flag of the message. Only relevant for messages listed from the All Mail folder in Gmail. */
    messageSpecialUse?: MessageSpecialUse;
}

export interface TextResponse {
    /**
     * Plaintext content
     * @example "Hello world"
     */
    plain?: string;
    /**
     * HTML content
     * @example "<p>Hello world</p>"
     */
    html?: string;
    /**
     * Is the current text output capped or not
     * @example false
     */
    hasMore?: boolean;
}

/** IMAP configuration */
export type ImapConfiguration = "false";

/** SMTP configuration */
export type SmtpConfiguration = "false";

/** OAuth2 configuration */
export type OAuth2 = "false";

export interface CreateAccount {
    /**
     * Account ID. If the provided value is `null` then an unique ID will be autogenerated
     * @maxLength 256
     * @example "example"
     */
    account: string;
    /**
     * Display name for the account
     * @maxLength 256
     * @example "My Email Account"
     */
    name: string;
    /**
     * Default email address of the account
     * @example "user@example.com"
     */
    email?: string;
    /**
     * Check changes only on selected path
     * @maxLength 1024
     * @default "*"
     * @example "INBOX"
     */
    path?: string;
    /** An array of mailbox paths. If set, then EmailEngine opens additional IMAP connections against these paths to detect changes faster. NB! connection counts are usually highly limited. */
    subconnections?: SubconnectionPaths;
    /**
     * Account-specific webhook URL
     * @example "https://myservice.com/imap/webhooks"
     */
    webhooks?: string;
    /**
     * Copy submitted messages to Sent folder. Set to `null` to unset and use provider specific default.
     * @example null
     */
    copy?: boolean;
    /**
     * Store recent logs
     * @default false
     * @example false
     */
    logs?: boolean;
    /**
     * Send webhooks for messages starting from provided date. The default is the account creation date.
     * @format date-time
     * @default "now"
     * @example "2021-07-08T07:06:34.336Z"
     */
    notifyFrom?: string;
    /**
     * Sync messages to document store starting from provided date. If not set, all emails are synced.
     * @format date-time
     * @default null
     * @example "2021-07-08T07:06:34.336Z"
     */
    syncFrom?: string;
    /**
     * Proxy URL
     * @example "socks://proxy.example.com:1080"
     */
    proxy?: string;
    /**
     * Hostname to use for the SMTP EHLO/HELO greeting
     * @example "my.proxy.tld"
     */
    smtpEhloName?: string;
    /** IMAP configuration */
    imap?: ImapConfiguration;
    /** SMTP configuration */
    smtp?: SmtpConfiguration;
    /** OAuth2 configuration */
    oauth2?: OAuth2;
    /**
     * Optional locale
     * @maxLength 100
     * @example "fr"
     */
    locale?: string;
    /**
     * Optional timezone
     * @maxLength 100
     * @example "Europe/Tallinn"
     */
    tz?: string;
}

/**
 * Is the account new or updated existing
 * @example "new"
 */
export type Model9 = "existing" | "new";

export interface CreateAccountResponse {
    /**
     * Account ID
     * @maxLength 256
     * @example "example"
     */
    account: string;
    /** Is the account new or updated existing */
    state: Model9;
}

export interface CreateGateway {
    /**
     * Gateway ID
     * @maxLength 256
     * @default null
     * @example "sendgun"
     */
    gateway: string;
    /**
     * Account Name
     * @maxLength 256
     * @example "John Smith"
     */
    name: string;
    /**
     * @maxLength 1024
     * @default null
     */
    user?: string;
    /**
     * @maxLength 1024
     * @default null
     */
    pass?: string;
    /**
     * Hostname to connect to
     * @example "smtp.gmail.com"
     */
    host: string;
    /**
     * Service port number
     * @min 1
     * @max 65536
     * @example 465
     */
    port: number;
    /**
     * Should connection use TLS. Usually true for port 465
     * @default false
     * @example true
     */
    secure?: boolean;
}

/**
 * Is the gateway new or updated existing
 * @example "new"
 */
export type Model10 = "existing" | "new";

export interface CreateGatewayResponse {
    /**
     * Gateway ID
     * @maxLength 256
     * @example "example"
     */
    gateway: string;
    /** Is the gateway new or updated existing */
    state: Model10;
}

export interface RegisterLicense {
    /**
     * License file
     * @maxLength 10240
     * @example "-----BEGIN LICENSE-----
     * ..."
     */
    license: string;
}

/**
 * OAuth2 provider
 * @maxLength 256
 * @example "gmail"
 */
export type Model11 = "gmail" | "gmailService" | "outlook" | "mailRu";

/**
 * OAuth2 Base Scopes
 * @example "imap"
 */
export type BaseScopes = "imap";

export type ExtraScopes = string[];

export type SkipScopes = string[];

export interface CreateOAuth2App {
    /**
     * Application name
     * @maxLength 256
     * @example "My Gmail App"
     */
    name: string;
    /**
     * Application description
     * @maxLength 1024
     * @example "My cool app"
     */
    description?: string;
    /**
     * Title for the application button
     * @maxLength 256
     * @example "App title"
     */
    title?: string;
    /** OAuth2 provider */
    provider: Model11;
    /**
     * Enable this app
     * @default false
     * @example true
     */
    enabled?: boolean;
    /**
     * Client or Application ID for 3-legged OAuth2 applications
     * @maxLength 256
     * @example "52422112755-3uov8bjwlrullq122rdm6l8ui25ho7qf.apps.googleusercontent.com"
     */
    clientId?: string;
    /**
     * Client secret for 3-legged OAuth2 applications
     * @maxLength 256
     * @example "boT7Q~dUljnfFdVuqpC11g8nGMjO8kpRAv-ZB"
     */
    clientSecret?: string;
    /** OAuth2 Base Scopes */
    baseScopes?: BaseScopes;
    extraScopes?: ExtraScopes;
    skipScopes?: SkipScopes;
    /**
     * Service client ID for 2-legged OAuth2 applications
     * @maxLength 256
     * @example "7103296518315821565203"
     */
    serviceClient: string;
    /**
     * PEM formatted service secret for 2-legged OAuth2 applications
     * @maxLength 102400
     * @example "-----BEGIN PRIVATE KEY-----
     * MIIEvgIBADANBgk..."
     */
    serviceKey: string;
    /**
     * Authorization tenant value for Outlook OAuth2 applications
     * @maxLength 1024
     * @example "common"
     */
    authority: string;
    /**
     * Redirect URL for 3-legged OAuth2 applications
     * @example "https://myservice.com/oauth"
     */
    redirectUrl?: string;
}

export interface CreateAppResponse {
    /**
     * OAuth2 application ID
     * @maxLength 256
     * @example "AAABhaBPHscAAAAH"
     */
    id: string;
    /**
     * Was the app created
     * @default true
     */
    created?: boolean;
}

export interface Settings {
    /** If false then do not emit webhooks */
    webhooksEnabled?: boolean;
    /**
     * Webhook URL
     * @example "https://myservice.com/imap/webhooks"
     */
    webhooks?: string;
    webhookEvents?: WebhookEvents;
    webhooksCustomHeaders?: WebhooksCustomHeaders;
    notifyHeaders?: NotifyHeaders;
    /**
     * Base URL of EmailEngine
     * @example "https://emailengine.example.com"
     */
    serviceUrl?: string;
    /** If true, then rewrite html links in sent emails to track opens and clicks */
    trackSentMessages?: boolean;
    /** If true, then resolve the category tab for incoming emails */
    resolveGmailCategories?: boolean;
    /** If true, then allow insecure certificates for IMAP/SMTP */
    ignoreMailCertErrors?: boolean;
    /** If true, then extracts reply text using OpenAI ChatGPT */
    generateEmailSummary?: boolean;
    /** (deprecated, not used) */
    generateRiskAssessment?: boolean;
    /**
     * OpenAI API key
     * @example "verysecr8t"
     */
    openAiAPIKey?: string;
    /**
     * OpenAI API Model
     * @example "gpt-3.5-turbo"
     */
    openAiModel?: string;
    /**
     * OpenAI API URL
     * @example "https://api.openai.com"
     */
    openAiAPIUrl?: string;
    /**
     * OpenAI API Model for chat
     * @example "gpt-3.5-turbo"
     */
    documentStoreChatModel?: string;
    /**
     * OpenAI Temperature
     * @min 0
     * @max 2
     * @example 0.8
     */
    openAiTemperature?: number;
    /**
     * Top-p sampling
     * @min 0
     * @max 1
     * @example 0.1
     */
    openAiTopP?: number;
    /**
     * Prompt to send to LLM for analyzing emails
     * @maxLength 6144
     * @example "You are an assistant scanning incoming emails..."
     */
    openAiPrompt?: string;
    /** If true, then generates vector embeddings for the email */
    openAiGenerateEmbeddings?: boolean;
    /** If true, then send "New Email" webhooks for incoming emails only */
    inboxNewOnly?: boolean;
    /**
     * HMAC secret for signing public requests
     * @example "verysecr8t"
     */
    serviceSecret?: string;
    /**
     * URL to fetch authentication data from
     * @example "https://myservice.com/authentication"
     */
    authServer?: string;
    /** Is the global proxy enabled or not */
    proxyEnabled?: boolean;
    /**
     * Proxy URL
     * @example "socks://proxy.example.com:1080"
     */
    proxyUrl?: string;
    /**
     * Hostname to use for the SMTP EHLO/HELO greeting
     * @example "my.proxy.tld"
     */
    smtpEhloName?: string;
    /** Include message text in webhook notification */
    notifyText?: boolean;
    /** Pre-process HTML in webhook notification to be web safe */
    notifyWebSafeHtml?: boolean;
    /** @min 0 */
    notifyTextSize?: number;
    /** Include calendar events in webhook notification */
    notifyCalendarEvents?: boolean;
    /** If true then do not show Gmail account option (deprecated) */
    gmailEnabled?: boolean;
    /**
     * Gmail OAuth2 Client ID (deprecated)
     * @maxLength 256
     */
    gmailClientId?: string;
    /**
     * Gmail OAuth2 Client Secret (deprecated)
     * @maxLength 256
     */
    gmailClientSecret?: string;
    /** Gmail OAuth2 Callback URL (deprecated) */
    gmailRedirectUrl?: string;
    /** Gmail OAuth2 Extra Scopes (deprecated) */
    gmailExtraScopes?: GmailExtraScopes;
    /** If true then do not show Outlook account option (deprecated) */
    outlookEnabled?: boolean;
    /**
     * Outlook OAuth2 Client ID (deprecated)
     * @maxLength 256
     */
    outlookClientId?: string;
    /**
     * Outlook OAuth2 Client Secret (deprecated)
     * @maxLength 256
     */
    outlookClientSecret?: string;
    /** Outlook OAuth2 Callback URL (deprecated) */
    outlookRedirectUrl?: string;
    /** Outlook OAuth2 authority (deprecated) */
    outlookAuthority?: OutlookAuthority;
    /** Outlook OAuth2 Extra Scopes (deprecated) */
    outlookExtraScopes?: OutlookExtraScopes;
    /** If true then do not show Mail.ru account option (deprecated) */
    mailRuEnabled?: boolean;
    /**
     * Mail.ru OAuth2 Client ID (deprecated)
     * @maxLength 256
     */
    mailRuClientId?: string;
    /**
     * Mail.ru OAuth2 Client Secret (deprecated)
     * @maxLength 256
     */
    mailRuClientSecret?: string;
    /** Mail.ru OAuth2 Callback URL (deprecated) */
    mailRuRedirectUrl?: string;
    /** Mail.ru OAuth2 Extra Scopes (deprecated) */
    mailRuExtraScopes?: MailRuExtraScopes;
    /**
     * OAuth2 Service Client ID (deprecated)
     * @maxLength 256
     */
    serviceClient?: string;
    /**
     * OAuth2 Secret Service Key (deprecated)
     * @maxLength 102400
     */
    serviceKey?: string;
    /** OAuth2 Service Extra Scopes (deprecated) */
    serviceExtraScopes?: ServiceExtraScopes;
    logs?: LogSettings;
    /** How to select local IP address for IMAP connections */
    imapStrategy?: ImapStrategy;
    /** How to select local IP address for SMTP connections */
    smtpStrategy?: SmtpStrategy;
    /** A list of pooled local IP addresses that can be used for IMAP and SMTP connections */
    localAddresses?: LocalAddresses;
    /** Enable SMTP Interface */
    smtpServerEnabled?: boolean;
    /**
     * SMTP Interface Port
     * @min 0
     * @max 65536
     */
    smtpServerPort?: number;
    /** SMTP Host to bind to */
    smtpServerHost?: string;
    /** Enable PROXY Protocol for SMTP server */
    smtpServerProxy?: boolean;
    /** Enable SMTP authentication */
    smtpServerAuthEnabled?: boolean;
    /**
     * SMTP client password. Set to null to disable.
     * @maxLength 1024
     */
    smtpServerPassword?: string;
    /** Enable TLS for the SMTP interface. Requires a valid certificate. */
    smtpServerTLSEnabled?: boolean;
    /** Enable IMAP Proxy Interface */
    imapProxyServerEnabled?: boolean;
    /**
     * IMAP Proxy Interface Port
     * @min 0
     * @max 65536
     */
    imapProxyServerPort?: number;
    /** IMAP Proxy Host to bind to */
    imapProxyServerHost?: string;
    /** Enable PROXY Protocol for the IMAP proxy server */
    imapProxyServerProxy?: boolean;
    /**
     * IMAP proxy client password. Set to null to disable.
     * @maxLength 1024
     */
    imapProxyServerPassword?: string;
    /** Enable TLS for the IMAP proxy interface. Requires a valid certificate. */
    imapProxyServerTLSEnabled?: boolean;
    /**
     * How many completed or failed queue entries to keep
     * @min 0
     */
    queueKeep?: number;
    /**
     * How many times to retry an email sending before it is considered as failing
     * @min 0
     */
    deliveryAttempts?: number;
    /**
     * HTML code displayed on the top of public pages like the hosted authentication form
     * @maxLength 1048576
     */
    templateHeader?: string;
    /**
     * JSON object to be used as the `env` variable in pre-processing scripts
     * @maxLength 1048576
     * @example "{"key": "value"}"
     */
    scriptEnv?: string;
    /** Enable support for reverse proxies */
    enableApiProxy?: boolean;
    /** Enable Document Store syncing */
    documentStoreEnabled?: boolean;
    /**
     * Document Store URL
     * @example "https://localhost:9200"
     */
    documentStoreUrl?: string;
    /**
     * Document Store index name
     * @maxLength 1024
     */
    documentStoreIndex?: string;
    /** Enable Document Store authentication */
    documentStoreAuthEnabled?: boolean;
    /**
     * Document Store username
     * @maxLength 1024
     */
    documentStoreUsername?: string;
    /**
     * Document Store password
     * @maxLength 1024
     */
    documentStorePassword?: string;
    /** If true, then generates vector embeddings for the email and stores these in the Document Store */
    documentStoreGenerateEmbeddings?: boolean;
    /** Enable Document Store pre-processing */
    documentStorePreProcessingEnabled?: boolean;
    /** Default locale identifier */
    locale?: Locale;
    /**
     * Default timezone identifier
     * @maxLength 100
     * @example "Europe/Tallinn"
     */
    timezone?: string;
    /**
     * Filter function for LLM pre-processing (JavaScript)
     * @maxLength 524288
     * @example "return true; // passes all emails"
     */
    openAiPreProcessingFn?: string;
    /**
     * Filter function for Document Store pre-processing (JavaScript)
     * @maxLength 524288
     * @example "return true; // passes all emails"
     */
    documentStorePreProcessingFn?: string;
    /**
     * Mapping function for Document Store pre-processing (JavaScript)
     * @maxLength 524288
     * @example "return payload; // returns unmodified data"
     */
    documentStorePreProcessingMap?: string;
}

/** List of updated setting keys */
export type Updated = string[];

export interface Model12 {
    /** List of updated setting keys */
    updated?: Updated;
}

export type Model13 = "api" | "smtp" | "imap-proxy";

/** @default ["api"] */
export type Scopes = Model13[];

export interface CreateToken {
    /**
     * Account ID
     * @maxLength 256
     * @example "example"
     */
    account: string;
    /**
     * Token description
     * @maxLength 1024
     * @example "Token description"
     */
    description: string;
    scopes: Scopes;
    /**
     * Related metadata in JSON format
     * @maxLength 1048576
     * @example "{"example": "value"}"
     */
    metadata?: string;
    /** Access restrictions */
    restrictions?: TokenRestrictions;
    /**
     * IP address of the requestor
     * @example "127.0.0.1"
     */
    ip?: string;
}

export interface CreateTokenResponse {
    /**
     * Access token
     * @example "123456"
     */
    token: string;
}

export interface VerifyAccount {
    /**
     * Include mailbox listing in response
     * @default false
     * @example false
     */
    mailboxes?: boolean;
    /** IMAP configuration */
    imap?: ImapConfiguration;
    /** SMTP configuration */
    smtp?: SmtpConfiguration;
    /**
     * Proxy URL
     * @example "socks://proxy.example.com:1080"
     */
    proxy?: string;
    /**
     * Hostname to use for the SMTP EHLO/HELO greeting
     * @example "my.proxy.tld"
     */
    smtpEhloName?: string;
}

export interface Imap {
    /**
     * Was IMAP account verified
     * @example true
     */
    success?: boolean;
    /**
     * Error messages for IMAP verification. Only present if success=false
     * @example "Something went wrong"
     */
    error?: string;
    /**
     * Error code. Only present if success=false
     * @example "ERR_SSL_WRONG_VERSION_NUMBER"
     */
    code?: string;
}

export interface Smtp {
    /**
     * Was SMTP account verified
     * @example true
     */
    success?: boolean;
    /**
     * Error messages for SMTP verification. Only present if success=false
     * @example "Something went wrong"
     */
    error?: string;
    /**
     * Error code. Only present if success=false
     * @example "ERR_SSL_WRONG_VERSION_NUMBER"
     */
    code?: string;
}

export interface Model14 {
    /**
     * Full path to mailbox
     * @example "Kalender/S&APw-nnip&AOQ-evad"
     */
    path: string;
    /** @example "/" */
    delimiter?: string;
    /**
     * Full path to parent mailbox
     * @example "Kalender"
     */
    parentPath: string;
    /**
     * Maibox name
     * @example "Sünnipäevad"
     */
    name: string;
    /**
     * Was the mailbox found from the output of LIST command
     * @example true
     */
    listed?: boolean;
    /**
     * Was the mailbox found from the output of LSUB command
     * @example true
     */
    subscribed?: boolean;
    /** Special use flag of the mailbox */
    specialUse?: MailboxSpecialUse;
}

export type Model15 = Model14[];

export interface VerifyAccountResponse {
    imap?: Imap;
    smtp?: Smtp;
    mailboxes?: Model15;
}

/**
 * Display the form for the specified account type (either "imap" or an OAuth2 app ID) instead of allowing the user to choose
 * @default false
 * @example "imap"
 */
export type Model16 = "false";

export interface RequestAuthForm {
    /**
     * Account ID. If the provided value is `null` then an unique ID will be autogenerated. Using an existing account ID will update settings for that existing account.
     * @maxLength 256
     * @default null
     * @example "example"
     */
    account?: string;
    /**
     * Display name for the account
     * @maxLength 256
     * @example "My Email Account"
     */
    name?: string;
    /**
     * Default email address of the account
     * @example "user@example.com"
     */
    email?: string;
    /**
     * If true then acts as a shared mailbox. Available for MS365 OAuth2 accounts.
     * @default false
     */
    delegated?: boolean;
    /**
     * Sync messages to document store starting from provided date. If not set, all emails are synced.
     * @format date-time
     * @example "2021-07-08T07:06:34.336Z"
     */
    syncFrom?: string;
    /**
     * Send webhooks for messages starting from provided date. The default is the account creation date.
     * @format date-time
     * @example "2021-07-08T07:06:34.336Z"
     */
    notifyFrom?: string;
    /** An array of mailbox paths. If set, then EmailEngine opens additional IMAP connections against these paths to detect changes faster. NB! connection counts are usually highly limited. */
    subconnections?: SubconnectionPaths;
    /**
     * The user will be redirected to this URL after submitting the authentication form
     * @example "https://myapp/account/settings.php"
     */
    redirectUrl: string;
    /** Display the form for the specified account type (either "imap" or an OAuth2 app ID) instead of allowing the user to choose */
    type?: Model16;
}

export interface RequestAuthFormResponse {
    /**
     * Generated URL to the hosted authentication form
     * @example "https://ee.example.com/accounts/new?data=eyJhY2NvdW50IjoiZXhh...L0W_BkFH5HW6Krwmr7c&type=imap"
     */
    url: string;
}

export interface BlocklistListAddPayload {
    /**
     * Account ID
     * @maxLength 256
     * @example "example"
     */
    account: string;
    /**
     * Email address to add to the list
     * @example "user@example.com"
     */
    recipient: string;
    /**
     * Identifier for the blocking reason
     * @default "block"
     */
    reason?: string;
}

export interface BlocklistListAddResponse {
    /**
     * Was the request successful
     * @example true
     */
    success?: boolean;
    /**
     * Was the address added to the list
     * @example true
     */
    added?: boolean;
}

export interface RequestChat {
    /**
     * Chat message to use
     * @maxLength 1024
     * @example "When did Jason last message me?"
     */
    question: string;
}

/**
 * The From address
 * @example {"name":"From Me","address":"sender@example.com"}
 */
export interface Model17 {
    /**
     * @maxLength 256
     * @example "Some Name"
     */
    name?: string;
    /** @example "user@example.com" */
    address: string;
}

/**
 * List of addresses
 * @example [{"address":"recipient@example.com"}]
 */
export type Model18 = Model1[];

/**
 * Special use flag of the message
 * @example "\Sent"
 */
export type MessageSpecialUse = "Drafts" | "Junk" | "Sent" | "Trash" | "Inbox";

/** Email that best matched the question */
export interface ChatResponseMessage {
    /**
     * Message ID
     * @example "AAAAAgAACrI"
     */
    id?: string;
    /**
     * Folder this message was found from
     * @example "INBOX"
     */
    path?: string;
    /**
     * Date of the email
     * @format date-time
     * @example "2023-09-29T10:03:49.000Z"
     */
    date?: string;
    /** The From address */
    from?: Model17;
    /** List of addresses */
    to?: Model18;
    /**
     * Message subject
     * @example "What a wonderful message"
     */
    subject?: string;
    /** Special use flag of the message */
    messageSpecialUse?: MessageSpecialUse;
}

/** Emails that best matched the question */
export type ChatResponseMessages = ChatResponseMessage[];

export interface ReturnChatResponse {
    /**
     * Was the request successful
     * @example true
     */
    success?: boolean;
    /**
     * Chat response
     * @example "Last tuesday"
     */
    answer: string;
    /** Emails that best matched the question */
    messages?: ChatResponseMessages;
}

export interface CreateTemplateContent {
    /**
     * Message subject
     * @maxLength 10240
     * @example "What a wonderful message"
     */
    subject?: string;
    /**
     * Message Text
     * @maxLength 5242880
     * @example "Hello from myself!"
     */
    text?: string;
    /**
     * Message HTML
     * @maxLength 5242880
     * @example "<p>Hello from myself!</p>"
     */
    html?: string;
    /**
     * Preview text appears in the inbox after the subject line
     * @maxLength 1024
     * @example "Welcome to our newsletter!"
     */
    previewText?: string;
}

export interface CreateTemplate {
    /**
     * Account ID. Use `null` for public templates.
     * @maxLength 256
     * @example "example"
     */
    account: string;
    /**
     * Name of the template
     * @maxLength 256
     * @example "Transaction receipt"
     */
    name: string;
    /**
     * Optional description of the template
     * @maxLength 1024
     * @example "Something about the template"
     */
    description?: string;
    /** Markup language for HTML ("html", "markdown" or "mjml") */
    format?: Format;
    content: CreateTemplateContent;
}

export interface CreateTemplateResponse {
    /** Was the template created or not */
    created?: boolean;
    /**
     * Account ID
     * @maxLength 256
     * @example "example"
     */
    account: string;
    /**
     * Template ID
     * @maxLength 256
     * @example "example"
     */
    id: string;
}

/** Optional list of account ID values */
export type UnifiedSearchAccounts = string[];

/** Optional list of mailbox folder paths or specialUse flags */
export type UnifiedSearchPaths = string[];

/** Headers to match against */
export type Headers = object;

/** Search query to filter messages */
export interface SearchQuery {
    /**
     * Sequence number range. Not allowed with `documentStore`.
     * @maxLength 8192
     */
    seq?: string;
    /** Check if message is answered or not */
    answered?: boolean;
    /** Check if message is marked for being deleted or not */
    deleted?: boolean;
    /** Check if message is a draft */
    draft?: boolean;
    /** Check if message is marked as unseen or not */
    unseen?: boolean;
    /** Check if message is flagged or not */
    flagged?: boolean;
    /** Check if message is marked as seen or not */
    seen?: boolean;
    /**
     * Match From: header
     * @maxLength 256
     */
    from?: string;
    /**
     * Match To: header
     * @maxLength 256
     */
    to?: string;
    /**
     * Match Cc: header
     * @maxLength 256
     */
    cc?: string;
    /**
     * Match Bcc: header
     * @maxLength 256
     */
    bcc?: string;
    /**
     * Match text body
     * @maxLength 256
     */
    body?: string;
    /**
     * Match message subject
     * @maxLength 2560
     */
    subject?: string;
    /**
     * Matches messages larger than value
     * @min 0
     * @max 1073741824
     */
    larger?: number;
    /**
     * Matches messages smaller than value
     * @min 0
     * @max 1073741824
     */
    smaller?: number;
    /**
     * UID range
     * @maxLength 8192
     */
    uid?: string;
    /**
     * Matches messages with modseq higher than value. Not allowed with `documentStore`.
     * @min 0
     */
    modseq?: number;
    /**
     * Matches messages received before date
     * @format date
     */
    before?: string;
    /**
     * Matches messages received after date
     * @format date
     */
    since?: string;
    /**
     * Matches messages sent before date
     * @format date
     */
    sentBefore?: string;
    /**
     * Matches messages sent after date
     * @format date
     */
    sentSince?: string;
    /**
     * Match specific Gmail unique email UD
     * @maxLength 256
     */
    emailId?: string;
    /**
     * Match specific Gmail unique thread UD
     * @maxLength 256
     */
    threadId?: string;
    /** Headers to match against */
    header?: Headers;
    /**
     * Raw Gmail search string. Will return an error if used for other account types.
     * @maxLength 1024
     * @example "has:attachment in:unread"
     */
    gmailRaw?: string;
}

/** Document Store query */
export type DocumentQuery = object;

export interface UnifiedSearchQuery {
    /** Optional list of account ID values */
    accounts?: UnifiedSearchAccounts;
    /** Optional list of mailbox folder paths or specialUse flags */
    paths?: UnifiedSearchPaths;
    /** Search query to filter messages */
    search: SearchQuery;
    /** Document Store query */
    documentQuery?: DocumentQuery;
}

/**
 * Message flags
 * @example ["\\Seen","\\Draft"]
 */
export type Flags = string[];

/**
 * @default "reply"
 * @example "reply"
 */
export type Action = "forward" | "reply";

/** Message reference for a reply or a forward. This is EmailEngine specific ID, not Message-ID header value. */
export interface MessageReference {
    /**
     * Referenced message ID
     * @maxLength 256
     * @example "AAAAAQAACnA"
     */
    message: string;
    action?: Action;
    /**
     * If true, then blockquotes the email that is being replied to
     * @default false
     */
    inline?: boolean;
    /**
     * If true, then includes attachments in forwarded message
     * @default false
     */
    forwardAttachments?: boolean;
    /**
     * If true, then processes the email even if the original message is not available anymore
     * @default false
     */
    ignoreMissing?: boolean;
    /**
     * If enabled then fetch the data from the Document Store instead of IMAP
     * @default false
     */
    documentStore?: boolean;
}

/**
 * The From address
 * @example {"name":"From Me","address":"sender@example.com"}
 */
export interface Model19 {
    /**
     * @maxLength 256
     * @example "Some Name"
     */
    name?: string;
    /** @example "user@example.com" */
    address: string;
}

/**
 * List of addresses
 * @example [{"address":"recipient@example.com"}]
 */
export type Model20 = Model1[];

/** List of addresses */
export type Model21 = Model1[];

/** List of addresses */
export type Model22 = Model1[];

export type ContentDisposition = "inline" | "attachment";

/** @default "base64" */
export type Encoding = "base64";

/**
 * Reference an existing attachment ID instead of providing attachment content. If set, then `content` option is not allowed. Otherwise `content` is required.
 * @maxLength 256
 * @example "AAAAAQAACnAcde"
 */
export type Reference = "false";

export interface UploadAttachment {
    /**
     * @maxLength 256
     * @example "transparent.gif"
     */
    filename?: string;
    /**
     * Base64 formatted attachment file
     * @maxLength 5242880
     * @example "R0lGODlhAQABAIAAAP///wAAACwAAAAAAQABAAACAkQBADs="
     */
    content: string;
    /**
     * @maxLength 256
     * @example "image/gif"
     */
    contentType?: string;
    contentDisposition?: ContentDisposition;
    /**
     * Content-ID value for embedded images
     * @maxLength 256
     * @example "unique-image-id@localhost"
     */
    cid?: string;
    encoding?: Encoding;
    /** Reference an existing attachment ID instead of providing attachment content. If set, then `content` option is not allowed. Otherwise `content` is required. */
    reference?: Reference;
}

/** List of attachments */
export type UploadAttachmentList = UploadAttachment[];

/** Custom Headers */
export type CustomHeaders = object;

export interface MessageUpload {
    /**
     * Target mailbox folder path
     * @example "INBOX"
     */
    path: string;
    /** Message flags */
    flags?: Flags;
    /**
     * Sets the internal date for this message
     * @format date-time
     * @example "2021-07-08T07:06:34.336Z"
     */
    internalDate?: string;
    /** Message reference for a reply or a forward. This is EmailEngine specific ID, not Message-ID header value. */
    reference?: MessageReference;
    /**
     * Base64 encoded email message in rfc822 format. If you provide other keys as well then these will override the values in the raw message.
     * @maxLength 5242880
     * @example "TUlNRS1WZXJzaW9uOiAxLjANClN1YmplY3Q6IGhlbGxvIHdvcmxkDQoNCkhlbGxvIQ0K"
     */
    raw?: string;
    /** The From address */
    from?: Model19;
    /** List of addresses */
    to?: Model20;
    /** List of addresses */
    cc?: Model21;
    /** List of addresses */
    bcc?: Model22;
    /**
     * Message subject
     * @maxLength 10240
     * @example "What a wonderful message"
     */
    subject?: string;
    /**
     * Message Text
     * @maxLength 5242880
     * @example "Hello from myself!"
     */
    text?: string;
    /**
     * Message HTML
     * @maxLength 5242880
     * @example "<p>Hello from myself!</p>"
     */
    html?: string;
    /** List of attachments */
    attachments?: UploadAttachmentList;
    /**
     * Message ID
     * @maxLength 996
     * @example "<test123@example.com>"
     */
    messageId?: string;
    /** Custom Headers */
    headers?: CustomHeaders;
    /**
     * Optional locale
     * @maxLength 100
     * @example "fr"
     */
    locale?: string;
    /**
     * Optional timezone
     * @maxLength 100
     * @example "Europe/Tallinn"
     */
    tz?: string;
}

/** Reference info if referencing was requested */
export interface ResponseReference {
    /**
     * Referenced message ID
     * @maxLength 256
     * @example "AAAAAQAACnA"
     */
    message: string;
    /**
     * Was the referenced message processed
     * @example true
     */
    success?: boolean;
    /**
     * If enabled then fetch the data from the Document Store instead of IMAP
     * @default false
     */
    documentStore?: boolean;
    /**
     * An error message if referenced message processing failed
     * @example "Referenced message was not found"
     */
    error?: string;
}

export interface MessageUploadResponse {
    /**
     * Message ID. NB! This and other fields might not be present if server did not provide enough information
     * @example "AAAAAgAACrI"
     */
    id?: string;
    /**
     * Folder this message was uploaded to
     * @example "INBOX"
     */
    path?: string;
    /**
     * UID of uploaded message
     * @example 12345
     */
    uid?: number;
    /**
     * UIDVALIDTITY of the target folder. Numeric value cast as string.
     * @example "12345"
     */
    uidValidity?: string;
    /**
     * Sequence number of uploaded message
     * @example 12345
     */
    seq?: number;
    /**
     * Message ID
     * @maxLength 996
     * @example "<test123@example.com>"
     */
    messageId?: string;
    /** Reference info if referencing was requested */
    reference?: ResponseReference;
}

/**
 * Mailbox path as an array or a string. If account is namespaced then namespace prefix is added by default.
 * @example ["Parent folder","Subfolder"]
 */
export type MailboxPath = string[];

export interface CreateMailbox {
    /** Mailbox path as an array or a string. If account is namespaced then namespace prefix is added by default. */
    path?: MailboxPath;
}

export interface CreateMailboxResponse {
    /**
     * Full path to mailbox
     * @example "Kalender/S&APw-nnip&AOQ-evad"
     */
    path: string;
    /**
     * Mailbox ID (if server has support)
     * @example "1439876283476"
     */
    mailboxId?: string;
    /**
     * Was the mailbox created
     * @example true
     */
    created?: boolean;
}

/** Document Store query. Only allowed with `documentStore`. */
export type Model23 = object;

export interface Model24 {
    /** Search query to filter messages */
    search: SearchQuery;
    /** Document Store query. Only allowed with `documentStore`. */
    documentQuery?: Model23;
}

export type Model25 = string[];

/** Optional SMTP envelope. If not set then derived from message headers. */
export interface SMTPEnvelope {
    /** @example "sender@example.com" */
    from?: string;
    to?: Model25;
}

/**
 * The From address
 * @example {"name":"From Me","address":"sender@example.com"}
 */
export interface Model26 {
    /**
     * @maxLength 256
     * @example "Some Name"
     */
    name?: string;
    /** @example "user@example.com" */
    address: string;
}

export interface ReplyToAddress {
    /**
     * @maxLength 256
     * @example "Some Name"
     */
    name?: string;
    /** @example "user@example.com" */
    address: string;
}

/**
 * List of Reply-To addresses
 * @example [{"name":"From Me","address":"sender@example.com"}]
 */
export type ReplyTo = ReplyToAddress[];

export interface ToAddress {
    /**
     * @maxLength 256
     * @example "Some Name"
     */
    name?: string;
    /** @example "user@example.com" */
    address: string;
}

/**
 * List of recipient addresses
 * @example [{"address":"recipient@example.com"}]
 */
export type ToAddressList = ToAddress[];

export interface CcAddress {
    /**
     * @maxLength 256
     * @example "Some Name"
     */
    name?: string;
    /** @example "user@example.com" */
    address: string;
}

/** List of CC addresses */
export type CcAddressList = CcAddress[];

export interface BccAddress {
    /**
     * @maxLength 256
     * @example "Some Name"
     */
    name?: string;
    /** @example "user@example.com" */
    address: string;
}

/** List of BCC addresses */
export type BccAddressList = BccAddress[];

/** An object of variables for the template renderer */
export type RenderValues = object;

/** Template rendering options */
export type Render = "false";

export interface MailMergeListEntry {
    to: ToAddress;
    /**
     * Message ID
     * @maxLength 996
     * @example "<test123@example.com>"
     */
    messageId?: string;
    /** An object of variables for the template renderer */
    params?: RenderValues;
    /**
     * Send message at specified time. Overrides message level `sendAt` value.
     * @format date-time
     * @example "2021-07-08T07:06:34.336Z"
     */
    sendAt?: string;
}

/**
 * Mail merge options. A separate email is generated for each recipient. Using mail merge disables `messageId`, `envelope`, `to`, `cc`, `bcc`, `render` keys for the message root.
 * @minItems 1
 */
export type MailMergeList = MailMergeListEntry[];

/** Specifies if only headers or the entire body of the message should be included in the response (RET) */
export type Return = "headers" | "full";

export type NotifyEntry = "never" | "success" | "failure" | "delay";

/** Defines the conditions under which a DSN response should be sent */
export type Notify = NotifyEntry[];

/** Request DNS notifications */
export interface DSN {
    /**
     * The envelope identifier that would be included in the response (ENVID)
     * @maxLength 256
     */
    id?: string;
    /** Specifies if only headers or the entire body of the message should be included in the response (RET) */
    return: Return;
    /** Defines the conditions under which a DSN response should be sent */
    notify?: Notify;
    /** The email address the DSN should be sent (ORCPT) */
    recipient?: string;
}

export interface SubmitMessage {
    /** Message reference for a reply or a forward. This is EmailEngine specific ID, not Message-ID header value. */
    reference?: MessageReference;
    /** Optional SMTP envelope. If not set then derived from message headers. */
    envelope?: SMTPEnvelope;
    /**
     * Base64 encoded email message in rfc822 format. If you provide other keys as well then these will override the values in the raw message.
     * @maxLength 5242880
     * @example "TUlNRS1WZXJzaW9uOiAxLjANClN1YmplY3Q6IGhlbGxvIHdvcmxkDQoNCkhlbGxvIQ0K"
     */
    raw?: string;
    /** The From address */
    from?: Model26;
    /** List of Reply-To addresses */
    replyTo?: ReplyTo;
    /** List of recipient addresses */
    to?: ToAddressList;
    /** List of CC addresses */
    cc?: CcAddressList;
    /** List of BCC addresses */
    bcc?: BccAddressList;
    /**
     * Message subject
     * @maxLength 10240
     * @example "What a wonderful message"
     */
    subject?: string;
    /**
     * Message Text
     * @maxLength 5242880
     * @example "Hello from myself!"
     */
    text?: string;
    /**
     * Message HTML
     * @maxLength 5242880
     * @example "<p>Hello from myself!</p>"
     */
    html?: string;
    /**
     * Preview text appears in the inbox after the subject line
     * @maxLength 1024
     * @example "Welcome to our newsletter!"
     */
    previewText?: string;
    /**
     * Stored template ID to load the email content from
     * @maxLength 256
     * @example "example"
     */
    template?: string;
    /** Template rendering options */
    render?: Render;
    /** Mail merge options. A separate email is generated for each recipient. Using mail merge disables `messageId`, `envelope`, `to`, `cc`, `bcc`, `render` keys for the message root. */
    mailMerge?: MailMergeList;
    /** List of attachments */
    attachments?: UploadAttachmentList;
    /**
     * Message ID
     * @maxLength 996
     * @example "<test123@example.com>"
     */
    messageId?: string;
    /** Custom Headers */
    headers?: CustomHeaders;
    /**
     * Should EmailEngine track clicks and opens for this message
     * @example false
     */
    trackingEnabled?: boolean;
    /**
     * If set then either copies the message to the Sent Mail folder or not. If not set then uses the account's default setting.
     * @example null
     */
    copy?: boolean;
    /**
     * Upload sent message to this folder. By default the account's Sent Mail folder is used.
     * @maxLength 1024
     * @example "Sent Mail"
     */
    sentMailPath?: string;
    /**
     * Optional locale
     * @maxLength 100
     * @example "fr"
     */
    locale?: string;
    /**
     * Optional timezone
     * @maxLength 100
     * @example "Europe/Tallinn"
     */
    tz?: string;
    /**
     * Send message at specified time
     * @format date-time
     * @example "2021-07-08T07:06:34.336Z"
     */
    sendAt?: string;
    /**
     * How many delivery attempts to make until message is considered as failed
     * @example 10
     */
    deliveryAttempts?: number;
    /**
     * Optional SMTP gateway ID for message routing
     * @maxLength 256
     * @example "example"
     */
    gateway?: string;
    /**
     * List ID for Mail Merge. Must use a subdomain name format. Lists are registered ad-hoc, so a new identifier defines a new list.
     * @example "test-list"
     */
    listId?: string;
    /** Request DNS notifications */
    dsn?: DSN;
    /**
     * Optional base URL for trackers. This URL must point to your EmailEngine instance.
     * @example "https://customer123.myservice.com"
     */
    baseUrl?: string;
    /**
     * If true, then EmailEngine does not send the email and returns an RFC822 formatted email file. Tracking information is not added to the email.
     * @default false
     */
    dryRun?: boolean;
}

/** Reference info if referencing was requested */
export interface Model27 {
    /**
     * Referenced message ID
     * @maxLength 256
     * @example "AAAAAQAACnA"
     */
    message: string;
    /**
     * Was the message dat aloaded from the document store
     * @example true
     */
    documentStore?: boolean;
    /**
     * Was the referenced message processed successfully
     * @example true
     */
    success?: boolean;
    /**
     * An error message if referenced message processing failed
     * @example "Referenced message was not found"
     */
    error?: string;
}

export interface ToAddressSingle {
    /**
     * @maxLength 256
     * @example "Some Name"
     */
    name?: string;
    /** @example "user@example.com" */
    address: string;
}

/** Reference info if referencing was requested */
export interface Model28 {
    /**
     * Referenced message ID
     * @maxLength 256
     * @example "AAAAAQAACnA"
     */
    message: string;
    /**
     * Was the message dat aloaded from the document store
     * @example true
     */
    documentStore?: boolean;
    /**
     * Was the referenced message processed successfully
     * @example true
     */
    success?: boolean;
    /**
     * An error message if referenced message processing failed
     * @example "Referenced message was not found"
     */
    error?: string;
}

/** Info about skipped message. If this value is set, then the message was not sent */
export interface Skipped {
    /**
     * Why this message was skipped
     * @example "unsubscribe"
     */
    reason?: string;
    /** @example "test-list" */
    listId?: string;
}

/** @example {"success":true,"to":{"name":"Andris 2","address":"andris@ethereal.email"},"messageId":"<19b9c433-d428-f6d8-1d00-d666ebcadfc4@ekiri.ee>","queueId":"1812477338914c8372a","reference":{"message":"AAAAAQAACnA","success":true},"sendAt":"2021-07-08T07:06:34.336Z"} */
export interface BulkResponseEntry {
    /**
     * Was the referenced message processed successfully
     * @example true
     */
    success?: boolean;
    to?: ToAddressSingle;
    /**
     * Message ID
     * @maxLength 996
     * @example "<test123@example.com>"
     */
    messageId?: string;
    /**
     * Queue identifier for scheduled email. Not present for bulk messages.
     * @example "d41f0423195f271f"
     */
    queueId?: string;
    /** Reference info if referencing was requested */
    reference?: Model28;
    /**
     * Send message at specified time. Overrides message level `sendAt` value.
     * @format date-time
     * @example "2021-07-08T07:06:34.336Z"
     */
    sendAt?: string;
    /** Info about skipped message. If this value is set, then the message was not sent */
    skipped?: Skipped;
}

/** Bulk message responses */
export type BulkResponseList = BulkResponseEntry[];

export interface SubmitMessageResponse {
    /** @example "Queued for delivery" */
    response?: string;
    /**
     * Message-ID header value. Not present for bulk messages.
     * @example "<a2184d08-a470-fec6-a493-fa211a3756e9@example.com>"
     */
    messageId?: string;
    /**
     * Queue identifier for scheduled email. Not present for bulk messages.
     * @example "d41f0423195f271f"
     */
    queueId?: string;
    /**
     * Scheduled send time
     * @format date
     * @example "2021-07-08T07:06:34.336Z"
     */
    sendAt?: string;
    /** Reference info if referencing was requested */
    reference?: Model27;
    /**
     * Base64 encoded RFC822 email if a preview was requested
     * @example "Q29udGVudC1UeXBlOiBtdWx0aX..."
     */
    preview?: string;
    /** Bulk message responses */
    mailMerge?: BulkResponseList;
}

/**
 * Optional gateway ID
 * @maxLength 256
 * @example false
 */
export type Gateway = "false";

export interface DeliveryStartRequest {
    /** Optional gateway ID */
    gateway?: Gateway;
}

export interface DeliveryStartResponse {
    /**
     * Was the test started
     * @example true
     */
    success?: boolean;
    /**
     * Test ID
     * @example "6420a6ad-7f82-4e4f-8112-82a9dad1f34d"
     */
    deliveryTest?: string;
}

export interface EmptyLicenseResponse {
    /** @example false */
    active?: boolean;
    /** @example false */
    details?: boolean;
    /** @example "SSPL-1.0-or-later" */
    type?: string;
}

export interface DeleteRequestResponse {
    /**
     * Account ID
     * @maxLength 256
     * @example "example"
     */
    account: string;
    /**
     * Was the account deleted
     * @default true
     */
    deleted?: boolean;
}

export interface DeleteBlocklistResponse {
    /**
     * Was the address removed from the list
     * @default true
     */
    deleted?: boolean;
}

export interface Model29 {
    /**
     * Gateway ID
     * @maxLength 256
     * @example "example"
     */
    gateway: string;
    /**
     * Was the gateway deleted
     * @default true
     */
    deleted?: boolean;
}

export interface DeleteAppRequestResponse {
    /**
     * OAuth2 application ID
     * @maxLength 256
     * @example "AAABhaBPHscAAAAH"
     */
    id: string;
    /**
     * Was the gateway deleted
     * @default true
     */
    deleted?: boolean;
    /**
     * The number of accounts registered with this application. Not available for legacy apps.
     * @example 12
     */
    accounts?: number;
}

export interface DeleteOutboxEntryResponse {
    /**
     * Was the message deleted
     * @default true
     */
    deleted?: boolean;
}

export interface DeleteTokenRequestResponse {
    /**
     * Was the account deleted
     * @default true
     */
    deleted?: boolean;
}

export interface DeleteMailboxResponse {
    /**
     * Full path to mailbox
     * @example "Kalender/S&APw-nnip&AOQ-evad"
     */
    path: string;
    /**
     * Was the mailbox deleted
     * @example true
     */
    deleted?: boolean;
}

export interface DeleteTemplateRequestResponse {
    /**
     * Was the account flushed
     * @default true
     */
    deleted?: boolean;
    /**
     * Account ID
     * @maxLength 256
     * @example "example"
     */
    account: string;
}

export interface Model30 {
    /**
     * Was the template deleted
     * @default true
     */
    deleted?: boolean;
    /**
     * Account ID
     * @maxLength 256
     * @example "example"
     */
    account: string;
    /**
     * Template ID
     * @maxLength 256
     * @example "AAABgS-UcAYAAAABAA"
     */
    id: string;
}

/** Present if message was moved to Trash */
export interface Moved {
    /**
     * Trash folder path
     * @example "Trash"
     */
    destination: string;
    /**
     * Message ID in Trash
     * @example "AAAAAwAAAWg"
     */
    message: string;
}

export interface MessageDeleteResponse {
    /**
     * Was the delete action executed
     * @example false
     */
    deleted?: boolean;
    /** Present if message was moved to Trash */
    moved?: Moved;
}

/** Optional TLS configuration */
export interface Model31 {
    /**
     * How to treat invalid certificates
     * @example true
     */
    rejectUnauthorized?: boolean;
    /**
     * Minimal TLS version
     * @maxLength 256
     * @example "TLSv1.2"
     */
    minVersion?: string;
}

/** IMAP configuration */
export type IMAPUpdate = "false";

/** Optional TLS configuration */
export interface Model32 {
    /**
     * How to treat invalid certificates
     * @example true
     */
    rejectUnauthorized?: boolean;
    /**
     * Minimal TLS version
     * @maxLength 256
     * @example "TLSv1.2"
     */
    minVersion?: string;
}

/** SMTP configuration */
export type SMTPUpdate = "false";

/** OAuth2 configuration */
export type OAuth2Update = "false";

export interface UpdateAccount {
    /**
     * Display name for the account
     * @maxLength 256
     * @example "My Email Account"
     */
    name?: string;
    /**
     * Default email address of the account
     * @example "user@example.com"
     */
    email?: string;
    /**
     * Check changes only on selected path
     * @maxLength 1024
     * @default "*"
     * @example "INBOX"
     */
    path?: string;
    /** An array of mailbox paths. If set, then EmailEngine opens additional IMAP connections against these paths to detect changes faster. NB! connection counts are usually highly limited. */
    subconnections?: SubconnectionPaths;
    /**
     * Account-specific webhook URL
     * @example "https://myservice.com/imap/webhooks"
     */
    webhooks?: string;
    /**
     * Copy submitted messages to Sent folder. Set to `null` to unset and use provider specific default.
     * @example null
     */
    copy?: boolean;
    /**
     * Store recent logs
     * @example false
     */
    logs?: boolean;
    /**
     * Send webhooks for messages starting from provided date. The default is the account creation date.
     * @format date-time
     * @example "2021-07-08T07:06:34.336Z"
     */
    notifyFrom?: string;
    /**
     * Sync messages to document store starting from provided date. If not set, all emails are synced.
     * @format date-time
     * @example "2021-07-08T07:06:34.336Z"
     */
    syncFrom?: string;
    /**
     * Proxy URL
     * @example "socks://proxy.example.com:1080"
     */
    proxy?: string;
    /**
     * Hostname to use for the SMTP EHLO/HELO greeting
     * @example "my.proxy.tld"
     */
    smtpEhloName?: string;
    /** IMAP configuration */
    imap?: IMAPUpdate;
    /** SMTP configuration */
    smtp?: SMTPUpdate;
    /** OAuth2 configuration */
    oauth2?: OAuth2Update;
    /**
     * Optional locale
     * @maxLength 100
     * @example "fr"
     */
    locale?: string;
    /**
     * Optional timezone
     * @maxLength 100
     * @example "Europe/Tallinn"
     */
    tz?: string;
}

export interface Model33 {
    /**
     * Account ID
     * @maxLength 256
     * @example "example"
     */
    account: string;
}

/**
 * Client or Application ID for 3-legged OAuth2 applications
 * @maxLength 256
 * @example "52422112755-3uov8bjwlrullq122rdm6l8ui25ho7qf.apps.googleusercontent.com"
 */
export type ClientId = "false";

/** OAuth2 Extra Scopes */
export type Model34 = string[];

/** OAuth2 scopes to skip from the base set */
export type Model35 = string[];

/**
 * Service client ID for 2-legged OAuth2 applications
 * @maxLength 256
 * @example "7103296518315821565203"
 */
export type ServiceClient = "false";

/**
 * Redirect URL for 3-legged OAuth2 applications
 * @example "https://myservice.com/oauth"
 */
export type RedirectUrl = "false";

export interface UpdateGateway {
    /**
     * Application name
     * @maxLength 256
     * @example "My Gmail App"
     */
    name: string;
    /**
     * Application description
     * @maxLength 1024
     * @example "My cool app"
     */
    description?: string;
    /**
     * Title for the application button
     * @maxLength 256
     * @example "App title"
     */
    title?: string;
    /**
     * Enable this app
     * @example true
     */
    enabled?: boolean;
    /** Client or Application ID for 3-legged OAuth2 applications */
    clientId?: ClientId;
    /**
     * Client secret for 3-legged OAuth2 applications
     * @maxLength 256
     * @example "boT7Q~dUljnfFdVuqpC11g8nGMjO8kpRAv-ZB"
     */
    clientSecret?: string;
    /** OAuth2 Base Scopes */
    baseScopes?: BaseScopes;
    /** OAuth2 Extra Scopes */
    extraScopes?: Model34;
    /** OAuth2 scopes to skip from the base set */
    skipScopes?: Model35;
    /** Service client ID for 2-legged OAuth2 applications */
    serviceClient?: ServiceClient;
    /**
     * PEM formatted service secret for 2-legged OAuth2 applications
     * @maxLength 102400
     * @example "-----BEGIN PRIVATE KEY-----
     * MIIEvgIBADANBgk..."
     */
    serviceKey?: string;
    /**
     * Authorization tenant value for Outlook OAuth2 applications
     * @maxLength 1024
     * @example "common"
     */
    authority?: string;
    /** Redirect URL for 3-legged OAuth2 applications */
    redirectUrl?: RedirectUrl;
}

export interface UpdateGatewayResponse {
    /**
     * Gateway ID
     * @maxLength 256
     * @example "example"
     */
    gateway: string;
}

export interface RequestFlush {
    /**
     * Only flush the account if true
     * @default false
     */
    flush?: boolean;
    /**
     * Send webhooks for messages starting from provided date. The default is the account creation date.
     * @format date-time
     * @default "now"
     * @example "2021-07-08T07:06:34.336Z"
     */
    notifyFrom?: string;
    /**
     * Sync messages to document store starting from provided date. If not set, all emails are synced.
     * @format date-time
     * @example "2021-07-08T07:06:34.336Z"
     */
    syncFrom?: string;
}

export interface RequestFlushResponse {
    /**
     * Flush status
     * @default false
     */
    flush?: boolean;
}

export interface RequestReconnect {
    /**
     * Only reconnect if true
     * @default false
     */
    reconnect?: boolean;
}

export interface RequestReconnectResponse {
    /**
     * Reconnection status
     * @default false
     */
    reconnect?: boolean;
}

export interface RequestSync {
    /**
     * Only sync if true
     * @default false
     */
    sync?: boolean;
}

export interface RequestSyncResponse {
    /**
     * Sync status
     * @default false
     */
    sync?: boolean;
}

/**
 * New mailbox path as an array or a string. If account is namespaced then namespace prefix is added by default.
 * @example ["Parent folder","Subfolder"]
 */
export type TargetMailboxPath = string[];

export interface RenameMailbox {
    /**
     * Mailbox folder path to rename
     * @example "Previous Mail"
     */
    path: string;
    /** New mailbox path as an array or a string. If account is namespaced then namespace prefix is added by default. */
    newPath?: TargetMailboxPath;
}

export interface RenameMailboxResponse {
    /**
     * Mailbox folder path to rename
     * @example "Previous Mail"
     */
    path: string;
    /**
     * Full path to mailbox
     * @example "Kalender/S&APw-nnip&AOQ-evad"
     */
    newPath: string;
    /**
     * Was the mailbox renamed
     * @example true
     */
    renamed?: boolean;
}

/**
 * Add new flags
 * @example ["\\Seen"]
 */
export type AddFlags = string[];

/**
 * Delete specific flags
 * @example ["\\Flagged"]
 */
export type DeleteFlags = string[];

/**
 * Override all flags
 * @example ["\\Seen","\\Flagged"]
 */
export type SetFlags = string[];

/** Flag updates */
export interface FlagUpdate {
    /** Add new flags */
    add?: AddFlags;
    /** Delete specific flags */
    delete?: DeleteFlags;
    /** Override all flags */
    set?: SetFlags;
}

/**
 * Add new labels
 * @example ["Some label"]
 */
export type AddLabels = string[];

/**
 * Delete specific labels
 * @example ["Some label"]
 */
export type DeleteLabels = string[];

/**
 * Override all labels
 * @example ["First label","Second label"]
 */
export type SetLabels = string[];

/** Label updates */
export interface LabelUpdate {
    /** Add new labels */
    add?: AddLabels;
    /** Delete specific labels */
    delete?: DeleteLabels;
    /** Override all labels */
    set?: SetLabels;
}

export interface MessageUpdate {
    /** Flag updates */
    flags?: FlagUpdate;
    /** Label updates */
    labels?: LabelUpdate;
}

export interface MessagesUpdateRequest {
    /** Search query to filter messages */
    search: SearchQuery;
    update?: MessageUpdate;
}

export interface FlagResponse {
    /** @example true */
    add?: boolean;
    /** @example false */
    delete?: boolean;
    /** @example false */
    set?: boolean;
}

export interface MessageUpdateResponse {
    flags?: FlagResponse;
    labels?: FlagResponse;
}

export interface Model36 {
    /**
     * Account Name
     * @maxLength 256
     * @example "John Smith"
     */
    name?: string;
    /** @maxLength 1024 */
    user?: string;
    /** @maxLength 1024 */
    pass?: string;
    /**
     * Hostname to connect to
     * @example "smtp.gmail.com"
     */
    host?: string;
    /**
     * Service port number
     * @min 1
     * @max 65536
     * @example 465
     */
    port?: number;
    /**
     * Should connection use TLS. Usually true for port 465
     * @example true
     */
    secure?: boolean;
}

export interface SettingsPutQueuePayload {
    /**
     * Set queue state to paused
     * @example false
     */
    paused?: boolean;
}

export interface SettingsPutQueueResponse {
    /** Queue ID */
    queue: Queue;
    /**
     * Is the queue paused or not
     * @example false
     */
    paused?: boolean;
}

export interface UpdateTemplateContent {
    /**
     * Message subject
     * @maxLength 10240
     * @example "What a wonderful message"
     */
    subject?: string;
    /**
     * Message Text
     * @maxLength 5242880
     * @example "Hello from myself!"
     */
    text?: string;
    /**
     * Message HTML
     * @maxLength 5242880
     * @example "<p>Hello from myself!</p>"
     */
    html?: string;
    /**
     * Preview text appears in the inbox after the subject line
     * @maxLength 1024
     * @example "Welcome to our newsletter!"
     */
    previewText?: string;
}

export interface UpdateTemplate {
    /**
     * Name of the template
     * @maxLength 256
     * @example "Transaction receipt"
     */
    name?: string;
    /**
     * Optional description of the template
     * @maxLength 1024
     * @example "Something about the template"
     */
    description?: string;
    /** Markup language for HTML ("html", "markdown" or "mjml") */
    format?: Format;
    content?: UpdateTemplateContent;
}

export interface UpdateTemplateResponse {
    /** Was the template updated or not */
    updated?: boolean;
    /**
     * Account ID
     * @maxLength 256
     * @example "example"
     */
    account: string;
    /**
     * Template ID
     * @maxLength 256
     * @example "example"
     */
    id: string;
}

export interface MessagesDeleteRequest {
    /** Search query to filter messages */
    search: SearchQuery;
}

export type Model37 = string[];

/**
 * An optional map of source and target ID values, if the server provided this info
 * @example [["AAAAAQAACnA","AAAAAwAAAD4"]]
 */
export type IdMap = Model37[];

/** Present if messages were moved to Trash */
export interface Model38 {
    /**
     * Trash folder path
     * @example "Trash"
     */
    destination: string;
    /** An optional map of source and target ID values, if the server provided this info */
    idMap?: IdMap;
}

export interface MessagesDeleteResponse {
    /**
     * Was the delete action executed
     * @example false
     */
    deleted?: boolean;
    /** Present if messages were moved to Trash */
    moved?: Model38;
}

export interface MessagesMoveRequest {
    /** Search query to filter messages */
    search: SearchQuery;
    /**
     * Target mailbox folder path
     * @example "INBOX"
     */
    path: string;
}

export type Model39 = string[];

/**
 * An optional map of source and target ID values, if the server provided this info
 * @example [["AAAAAQAACnA","AAAAAwAAAD4"]]
 */
export type Model40 = Model39[];

export interface MessagesMoveResponse {
    /**
     * Target mailbox folder path
     * @example "INBOX"
     */
    path: string;
    /** An optional map of source and target ID values, if the server provided this info */
    idMap?: Model40;
}

export interface MessageMove {
    /**
     * Target mailbox folder path
     * @example "INBOX"
     */
    path: string;
}

export interface MessageMoveResponse {
    /**
     * Target mailbox folder path
     * @example "INBOX"
     */
    path: string;
    /**
     * Message ID
     * @maxLength 256
     * @example "AAAAAQAACnA"
     */
    id: string;
    /**
     * UID of moved message
     * @example 12345
     */
    uid?: number;
}

import type { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from "axios";
import axios from "axios";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
    /** set parameter to `true` for call `securityWorker` for this request */
    secure?: boolean;
    /** request path */
    path: string;
    /** content type of request body */
    type?: ContentType;
    /** query params */
    query?: QueryParamsType;
    /** format of response (i.e. response.json() -> format: "json") */
    format?: ResponseType;
    /** request body */
    body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
    securityWorker?: (securityData: SecurityDataType | null) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
    secure?: boolean;
    format?: ResponseType;
}

export enum ContentType {
    Json = "application/json",
    FormData = "multipart/form-data",
    UrlEncoded = "application/x-www-form-urlencoded",
    Text = "text/plain"
}

export class HttpClient<SecurityDataType = unknown> {
    public instance: AxiosInstance;
    private securityData: SecurityDataType | null = null;
    private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
    private secure?: boolean;
    private format?: ResponseType;

    constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
        this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || "http://0.0.0.0:5678" });
        this.secure = secure;
        this.format = format;
        this.securityWorker = securityWorker;
    }

    public setSecurityData = (data: SecurityDataType | null) => {
        this.securityData = data;
    };

    protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
        const method = params1.method || (params2 && params2.method);

        return {
            ...this.instance.defaults,
            ...params1,
            ...(params2 || {}),
            headers: {
                ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
                ...(params1.headers || {}),
                ...((params2 && params2.headers) || {})
            }
        };
    }

    protected stringifyFormItem(formItem: unknown) {
        if (typeof formItem === "object" && formItem !== null) {
            return JSON.stringify(formItem);
        } else {
            return `${formItem}`;
        }
    }

    protected createFormData(input: Record<string, unknown>): FormData {
        return Object.keys(input || {}).reduce((formData, key) => {
            const property = input[key];
            const propertyContent: any[] = property instanceof Array ? property : [property];

            for (const formItem of propertyContent) {
                const isFileType = formItem instanceof Blob || formItem instanceof File;
                formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
            }

            return formData;
        }, new FormData());
    }

    public request = async <T = any, _E = any>({ secure, path, type, query, format, body, ...params }: FullRequestParams): Promise<AxiosResponse<T>> => {
        const secureParams =
            ((typeof secure === "boolean" ? secure : this.secure) && this.securityWorker && (await this.securityWorker(this.securityData))) || {};
        const requestParams = this.mergeRequestParams(params, secureParams);
        const responseFormat = format || this.format || undefined;

        if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
            body = this.createFormData(body as Record<string, unknown>);
        }

        if (type === ContentType.Text && body && body !== null && typeof body !== "string") {
            body = JSON.stringify(body);
        }

        return this.instance.request({
            ...requestParams,
            headers: {
                ...(requestParams.headers || {}),
                ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {})
            },
            params: query,
            responseType: responseFormat,
            data: body,
            url: path
        });
    };
}

/**
 * @title EmailEngine
 * @version 2.39.0
 * @baseUrl http://0.0.0.0:5678
 * @contact Postal Systems OÜ <info@emailengine.app>
 *
 * You will need an Access Token to use this API (generate one <a href="/admin/tokens" target="_parent">here</a>).
 *
 * When making API calls remember that requests against the same account are queued and not executed in parallel. If a previous request takes too much time to finish, a queued request might time out before EmailEngine can run it.
 */
export class EmailEngineApi<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
    accounts = {
        /**
         * @description Lists registered accounts
         *
         * @tags Account
         * @name GetV1Accounts
         * @summary List accounts
         * @request GET:/v1/accounts
         * @secure
         * @response `200` `AccountsFilterResponse` Successful
         */
        getV1Accounts: (
            query?: {
                /**
                 * Page number (zero indexed, so use 0 for first page)
                 * @min 0
                 * @max 1048576
                 * @default 0
                 */
                page?: number;
                /**
                 * How many entries per page
                 * @min 1
                 * @max 1000
                 * @default 20
                 */
                pageSize?: number;
                /** Filter accounts by state */
                state?: "init" | "syncing" | "connecting" | "connected" | "authenticationError" | "connectError" | "unset" | "disconnected";
                /** Filter accounts by string match */
                query?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<AccountsFilterResponse, any>({
                path: `/v1/accounts`,
                method: "GET",
                query: query,
                secure: true,
                ...params
            })
    };
    autoconfig = {
        /**
         * @description Try to discover IMAP and SMTP settings for an email account
         *
         * @tags Settings
         * @name GetV1Autoconfig
         * @summary Discover Email settings
         * @request GET:/v1/autoconfig
         * @secure
         * @response `200` `DiscoveredEmailSettings` Successful
         */
        getV1Autoconfig: (
            query: {
                /** Email address to discover email settings for */
                email: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<DiscoveredEmailSettings, any>({
                path: `/v1/autoconfig`,
                method: "GET",
                query: query,
                secure: true,
                ...params
            })
    };
    blocklists = {
        /**
         * @description List blocklists with blocked addresses
         *
         * @tags Blocklists
         * @name GetV1Blocklists
         * @summary List blocklists
         * @request GET:/v1/blocklists
         * @secure
         * @response `200` `BlocklistsResponse` Successful
         */
        getV1Blocklists: (
            query?: {
                /**
                 * Page number (zero indexed, so use 0 for first page)
                 * @min 0
                 * @max 1048576
                 * @default 0
                 */
                page?: number;
                /**
                 * How many entries per page
                 * @min 1
                 * @max 1000
                 * @default 20
                 */
                pageSize?: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<BlocklistsResponse, any>({
                path: `/v1/blocklists`,
                method: "GET",
                query: query,
                secure: true,
                ...params
            })
    };
    changes = {
        /**
         * @description Stream account state changes as an EventSource
         *
         * @tags Account
         * @name GetV1Changes
         * @summary Stream state changes
         * @request GET:/v1/changes
         * @secure
         * @response `default` `string` Successful
         */
        getV1Changes: (params: RequestParams = {}) =>
            this.request<any, string>({
                path: `/v1/changes`,
                method: "GET",
                secure: true,
                ...params
            })
    };
    gateways = {
        /**
         * @description Lists registered gateways
         *
         * @tags SMTP Gateway
         * @name GetV1Gateways
         * @summary List gateways
         * @request GET:/v1/gateways
         * @secure
         * @response `200` `GatewaysFilterResponse` Successful
         */
        getV1Gateways: (
            query?: {
                /**
                 * Page number (zero indexed, so use 0 for first page)
                 * @min 0
                 * @max 1048576
                 * @default 0
                 */
                page?: number;
                /**
                 * How many entries per page
                 * @min 1
                 * @max 1000
                 * @default 20
                 */
                pageSize?: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<GatewaysFilterResponse, any>({
                path: `/v1/gateways`,
                method: "GET",
                query: query,
                secure: true,
                ...params
            })
    };
    license = {
        /**
         * @description Get active license information
         *
         * @tags License
         * @name GetV1License
         * @summary Request license info
         * @request GET:/v1/license
         * @secure
         * @response `200` `LicenseResponse` Successful
         */
        getV1License: (params: RequestParams = {}) =>
            this.request<LicenseResponse, any>({
                path: `/v1/license`,
                method: "GET",
                secure: true,
                ...params
            }),

        /**
         * @description Set up a license for EmailEngine to unlock all features
         *
         * @tags License
         * @name PostV1License
         * @summary Register a license
         * @request POST:/v1/license
         * @secure
         * @response `200` `LicenseResponse` Successful
         */
        postV1License: (body: RegisterLicense, params: RequestParams = {}) =>
            this.request<LicenseResponse, any>({
                path: `/v1/license`,
                method: "POST",
                body: body,
                secure: true,
                type: ContentType.Json,
                ...params
            }),

        /**
         * @description Remove registered active license
         *
         * @tags License
         * @name DeleteV1License
         * @summary Remove license
         * @request DELETE:/v1/license
         * @secure
         * @response `200` `EmptyLicenseResponse` Successful
         */
        deleteV1License: (params: RequestParams = {}) =>
            this.request<EmptyLicenseResponse, any>({
                path: `/v1/license`,
                method: "DELETE",
                secure: true,
                ...params
            })
    };
    oauth2 = {
        /**
         * @description Lists registered OAuth2 applications
         *
         * @tags OAuth2 Applications
         * @name GetV1Oauth2
         * @summary List OAuth2 applications
         * @request GET:/v1/oauth2
         * @secure
         * @response `200` `OAuth2FilterResponse` Successful
         */
        getV1Oauth2: (
            query?: {
                /**
                 * Page number (zero indexed, so use 0 for first page)
                 * @min 0
                 * @max 1048576
                 * @default 0
                 */
                page?: number;
                /**
                 * How many entries per page
                 * @min 1
                 * @max 1000
                 * @default 20
                 */
                pageSize?: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<OAuth2FilterResponse, any>({
                path: `/v1/oauth2`,
                method: "GET",
                query: query,
                secure: true,
                ...params
            }),

        /**
         * @description Registers a new OAuth2 application for a specific provider
         *
         * @tags OAuth2 Applications
         * @name PostV1Oauth2
         * @summary Register OAuth2 application
         * @request POST:/v1/oauth2
         * @secure
         * @response `200` `CreateAppResponse` Successful
         */
        postV1Oauth2: (body: CreateOAuth2App, params: RequestParams = {}) =>
            this.request<CreateAppResponse, any>({
                path: `/v1/oauth2`,
                method: "POST",
                body: body,
                secure: true,
                type: ContentType.Json,
                ...params
            }),

        /**
         * @description Returns stored information about an OAuth2 application. Secrets are not included.
         *
         * @tags OAuth2 Applications
         * @name GetV1Oauth2App
         * @summary Get application info
         * @request GET:/v1/oauth2/{app}
         * @secure
         * @response `200` `ApplicationResponse` Successful
         */
        getV1Oauth2App: (app: string, params: RequestParams = {}) =>
            this.request<ApplicationResponse, any>({
                path: `/v1/oauth2/${app}`,
                method: "GET",
                secure: true,
                ...params
            }),

        /**
         * @description Delete OAuth2 application data
         *
         * @tags OAuth2 Applications
         * @name DeleteV1Oauth2App
         * @summary Remove OAuth2 application
         * @request DELETE:/v1/oauth2/{app}
         * @secure
         * @response `200` `DeleteAppRequestResponse` Successful
         */
        deleteV1Oauth2App: (app: string, params: RequestParams = {}) =>
            this.request<DeleteAppRequestResponse, any>({
                path: `/v1/oauth2/${app}`,
                method: "DELETE",
                secure: true,
                ...params
            }),

        /**
         * @description Updates OAuth2 application information
         *
         * @tags OAuth2 Applications
         * @name PutV1Oauth2App
         * @summary Update OAuth2 application
         * @request PUT:/v1/oauth2/{app}
         * @secure
         * @response `200` `UpdateGatewayResponse` Successful
         */
        putV1Oauth2App: (app: string, body: UpdateGateway, params: RequestParams = {}) =>
            this.request<UpdateGatewayResponse, any>({
                path: `/v1/oauth2/${app}`,
                method: "PUT",
                body: body,
                secure: true,
                type: ContentType.Json,
                ...params
            })
    };
    outbox = {
        /**
         * @description Lists messages in the Outbox
         *
         * @tags Outbox
         * @name GetV1Outbox
         * @summary List queued messages
         * @request GET:/v1/outbox
         * @secure
         * @response `200` `OutboxListResponse` Successful
         */
        getV1Outbox: (
            query?: {
                /**
                 * Page number (zero indexed, so use 0 for first page)
                 * @min 0
                 * @max 1048576
                 * @default 0
                 */
                page?: number;
                /**
                 * How many entries per page
                 * @min 1
                 * @max 1000
                 * @default 20
                 */
                pageSize?: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<OutboxListResponse, any>({
                path: `/v1/outbox`,
                method: "GET",
                query: query,
                secure: true,
                ...params
            }),

        /**
         * @description Remove a message from the outbox
         *
         * @tags Outbox
         * @name DeleteV1OutboxQueueid
         * @summary Remove a message
         * @request DELETE:/v1/outbox/{queueId}
         * @secure
         * @response `200` `DeleteOutboxEntryResponse` Successful
         */
        deleteV1OutboxQueueid: (queueId: string, params: RequestParams = {}) =>
            this.request<DeleteOutboxEntryResponse, any>({
                path: `/v1/outbox/${queueId}`,
                method: "DELETE",
                secure: true,
                ...params
            })
    };
    settings = {
        /**
         * @description List setting values for specific keys
         *
         * @tags Settings
         * @name GetV1Settings
         * @summary List specific settings
         * @request GET:/v1/settings
         * @secure
         * @response `200` `SettingsQueryResponse` Successful
         */
        getV1Settings: (
            query?: {
                /** @default false */
                eventTypes?: boolean;
                /** @default false */
                webhooksEnabled?: boolean;
                /** @default false */
                webhooks?: boolean;
                /** @default false */
                webhookEvents?: boolean;
                /** @default false */
                webhooksCustomHeaders?: boolean;
                /** @default false */
                notifyHeaders?: boolean;
                /** @default false */
                serviceUrl?: boolean;
                /** @default false */
                trackSentMessages?: boolean;
                /** @default false */
                resolveGmailCategories?: boolean;
                /** @default false */
                ignoreMailCertErrors?: boolean;
                /** @default false */
                generateEmailSummary?: boolean;
                /** @default false */
                generateRiskAssessment?: boolean;
                /** @default false */
                openAiAPIKey?: boolean;
                /** @default false */
                openAiModel?: boolean;
                /** @default false */
                openAiAPIUrl?: boolean;
                /** @default false */
                documentStoreChatModel?: boolean;
                /** @default false */
                openAiTemperature?: boolean;
                /** @default false */
                openAiTopP?: boolean;
                /** @default false */
                openAiPrompt?: boolean;
                /** @default false */
                openAiGenerateEmbeddings?: boolean;
                /** @default false */
                inboxNewOnly?: boolean;
                /** @default false */
                serviceSecret?: boolean;
                /** @default false */
                authServer?: boolean;
                /** @default false */
                proxyEnabled?: boolean;
                /** @default false */
                proxyUrl?: boolean;
                /** @default false */
                smtpEhloName?: boolean;
                /** @default false */
                notifyText?: boolean;
                /** @default false */
                notifyWebSafeHtml?: boolean;
                /** @default false */
                notifyTextSize?: boolean;
                /** @default false */
                notifyCalendarEvents?: boolean;
                /** @default false */
                gmailEnabled?: boolean;
                /** @default false */
                gmailClientId?: boolean;
                /** @default false */
                gmailClientSecret?: boolean;
                /** @default false */
                gmailRedirectUrl?: boolean;
                /** @default false */
                gmailExtraScopes?: boolean;
                /** @default false */
                outlookEnabled?: boolean;
                /** @default false */
                outlookClientId?: boolean;
                /** @default false */
                outlookClientSecret?: boolean;
                /** @default false */
                outlookRedirectUrl?: boolean;
                /** @default false */
                outlookAuthority?: boolean;
                /** @default false */
                outlookExtraScopes?: boolean;
                /** @default false */
                mailRuEnabled?: boolean;
                /** @default false */
                mailRuClientId?: boolean;
                /** @default false */
                mailRuClientSecret?: boolean;
                /** @default false */
                mailRuRedirectUrl?: boolean;
                /** @default false */
                mailRuExtraScopes?: boolean;
                /** @default false */
                serviceClient?: boolean;
                /** @default false */
                serviceKey?: boolean;
                /** @default false */
                serviceExtraScopes?: boolean;
                /** @default false */
                logs?: boolean;
                /** @default false */
                imapStrategy?: boolean;
                /** @default false */
                smtpStrategy?: boolean;
                /** @default false */
                localAddresses?: boolean;
                /** @default false */
                smtpServerEnabled?: boolean;
                /** @default false */
                smtpServerPort?: boolean;
                /** @default false */
                smtpServerHost?: boolean;
                /** @default false */
                smtpServerProxy?: boolean;
                /** @default false */
                smtpServerAuthEnabled?: boolean;
                /** @default false */
                smtpServerPassword?: boolean;
                /** @default false */
                smtpServerTLSEnabled?: boolean;
                /** @default false */
                imapProxyServerEnabled?: boolean;
                /** @default false */
                imapProxyServerPort?: boolean;
                /** @default false */
                imapProxyServerHost?: boolean;
                /** @default false */
                imapProxyServerProxy?: boolean;
                /** @default false */
                imapProxyServerPassword?: boolean;
                /** @default false */
                imapProxyServerTLSEnabled?: boolean;
                /** @default false */
                queueKeep?: boolean;
                /** @default false */
                deliveryAttempts?: boolean;
                /** @default false */
                templateHeader?: boolean;
                /** @default false */
                scriptEnv?: boolean;
                /** @default false */
                enableApiProxy?: boolean;
                /** @default false */
                documentStoreEnabled?: boolean;
                /** @default false */
                documentStoreUrl?: boolean;
                /** @default false */
                documentStoreIndex?: boolean;
                /** @default false */
                documentStoreAuthEnabled?: boolean;
                /** @default false */
                documentStoreUsername?: boolean;
                /** @default false */
                documentStorePassword?: boolean;
                /** @default false */
                documentStoreGenerateEmbeddings?: boolean;
                /** @default false */
                documentStorePreProcessingEnabled?: boolean;
                /** @default false */
                locale?: boolean;
                /** @default false */
                timezone?: boolean;
                /** @default false */
                openAiPreProcessingFn?: boolean;
                /** @default false */
                documentStorePreProcessingFn?: boolean;
                /** @default false */
                documentStorePreProcessingMap?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<SettingsQueryResponse, any>({
                path: `/v1/settings`,
                method: "GET",
                query: query,
                secure: true,
                ...params
            }),

        /**
         * @description Set setting values for specific keys
         *
         * @tags Settings
         * @name PostV1Settings
         * @summary Set setting values
         * @request POST:/v1/settings
         * @secure
         * @response `200` `Model12` Successful
         */
        postV1Settings: (body: Settings, params: RequestParams = {}) =>
            this.request<Model12, any>({
                path: `/v1/settings`,
                method: "POST",
                body: body,
                secure: true,
                type: ContentType.Json,
                ...params
            }),

        /**
         * @description Show queue status and current state
         *
         * @tags Settings
         * @name GetV1SettingsQueueQueue
         * @summary Show queue information
         * @request GET:/v1/settings/queue/{queue}
         * @secure
         * @response `200` `SettingsQueueResponse` Successful
         */
        getV1SettingsQueueQueue: (queue: "notify" | "submit" | "documents", params: RequestParams = {}) =>
            this.request<SettingsQueueResponse, any>({
                path: `/v1/settings/queue/${queue}`,
                method: "GET",
                secure: true,
                ...params
            }),

        /**
         * @description Set queue settings
         *
         * @tags Settings
         * @name PutV1SettingsQueueQueue
         * @summary Set queue settings
         * @request PUT:/v1/settings/queue/{queue}
         * @secure
         * @response `200` `SettingsPutQueueResponse` Successful
         */
        putV1SettingsQueueQueue: (queue: "notify" | "submit" | "documents", body: SettingsPutQueuePayload, params: RequestParams = {}) =>
            this.request<SettingsPutQueueResponse, any>({
                path: `/v1/settings/queue/${queue}`,
                method: "PUT",
                body: body,
                secure: true,
                type: ContentType.Json,
                ...params
            })
    };
    stats = {
        /**
         * No description
         *
         * @tags Stats
         * @name GetV1Stats
         * @summary Return server stats
         * @request GET:/v1/stats
         * @secure
         * @response `200` `SettingsResponse` Successful
         */
        getV1Stats: (
            query?: {
                /**
                 * Duration for counters
                 * @min 0
                 * @max 604800
                 * @default 3600
                 */
                seconds?: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<SettingsResponse, any>({
                path: `/v1/stats`,
                method: "GET",
                query: query,
                secure: true,
                ...params
            })
    };
    templates = {
        /**
         * @description Lists stored templates for the account
         *
         * @tags Templates
         * @name GetV1Templates
         * @summary List account templates
         * @request GET:/v1/templates
         * @secure
         * @response `200` `AccountTemplatesResponse` Successful
         */
        getV1Templates: (
            query?: {
                /**
                 * Account ID to list the templates for
                 * @maxLength 256
                 */
                account?: string;
                /**
                 * Page number (zero indexed, so use 0 for first page)
                 * @min 0
                 * @max 1048576
                 * @default 0
                 */
                page?: number;
                /**
                 * How many entries per page
                 * @min 1
                 * @max 1000
                 * @default 20
                 */
                pageSize?: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<AccountTemplatesResponse, any>({
                path: `/v1/templates`,
                method: "GET",
                query: query,
                secure: true,
                ...params
            }),

        /**
         * @description Retrieve template content and information
         *
         * @tags Templates
         * @name GetV1TemplatesTemplateTemplate
         * @summary Get template information
         * @request GET:/v1/templates/template/{template}
         * @secure
         * @response `200` `AccountTemplateResponse` Successful
         */
        getV1TemplatesTemplateTemplate: (template: string, params: RequestParams = {}) =>
            this.request<AccountTemplateResponse, any>({
                path: `/v1/templates/template/${template}`,
                method: "GET",
                secure: true,
                ...params
            }),

        /**
         * @description Delete a stored template
         *
         * @tags Templates
         * @name DeleteV1TemplatesTemplateTemplate
         * @summary Remove a template
         * @request DELETE:/v1/templates/template/{template}
         * @secure
         * @response `200` `Model30` Successful
         */
        deleteV1TemplatesTemplateTemplate: (template: string, params: RequestParams = {}) =>
            this.request<Model30, any>({
                path: `/v1/templates/template/${template}`,
                method: "DELETE",
                secure: true,
                ...params
            }),

        /**
         * @description Update a stored template.
         *
         * @tags Templates
         * @name PutV1TemplatesTemplateTemplate
         * @summary Update a template
         * @request PUT:/v1/templates/template/{template}
         * @secure
         * @response `200` `UpdateTemplateResponse` Successful
         */
        putV1TemplatesTemplateTemplate: (template: string, body: UpdateTemplate, params: RequestParams = {}) =>
            this.request<UpdateTemplateResponse, any>({
                path: `/v1/templates/template/${template}`,
                method: "PUT",
                body: body,
                secure: true,
                type: ContentType.Json,
                ...params
            }),

        /**
         * @description Create a new stored template. Templates can be used when sending emails as the content of the message.
         *
         * @tags Templates
         * @name PostV1TemplatesTemplate
         * @summary Create template
         * @request POST:/v1/templates/template
         * @secure
         * @response `200` `CreateTemplateResponse` Successful
         */
        postV1TemplatesTemplate: (body: CreateTemplate, params: RequestParams = {}) =>
            this.request<CreateTemplateResponse, any>({
                path: `/v1/templates/template`,
                method: "POST",
                body: body,
                secure: true,
                type: ContentType.Json,
                ...params
            }),

        /**
         * @description Delete all stored templates for an account
         *
         * @tags Templates
         * @name DeleteV1TemplatesAccountAccount
         * @summary Flush templates
         * @request DELETE:/v1/templates/account/{account}
         * @secure
         * @response `200` `DeleteTemplateRequestResponse` Successful
         */
        deleteV1TemplatesAccountAccount: (
            account: string,
            query?: {
                /**
                 * Must be true in order to flush templates
                 * @default false
                 */
                force?: true;
            },
            params: RequestParams = {}
        ) =>
            this.request<DeleteTemplateRequestResponse, any>({
                path: `/v1/templates/account/${account}`,
                method: "DELETE",
                query: query,
                secure: true,
                ...params
            })
    };
    tokens = {
        /**
         * @description Lists access tokens registered for root access
         *
         * @tags Access Tokens
         * @name GetV1Tokens
         * @summary List root tokens
         * @request GET:/v1/tokens
         * @secure
         * @response `200` `RootTokensResponse` Successful
         */
        getV1Tokens: (params: RequestParams = {}) =>
            this.request<RootTokensResponse, any>({
                path: `/v1/tokens`,
                method: "GET",
                secure: true,
                ...params
            }),

        /**
         * @description Lists access tokens registered for an account
         *
         * @tags Access Tokens
         * @name GetV1TokensAccountAccount
         * @summary List account tokens
         * @request GET:/v1/tokens/account/{account}
         * @secure
         * @response `200` `AccountsTokensResponse` Successful
         */
        getV1TokensAccountAccount: (account: string, params: RequestParams = {}) =>
            this.request<AccountsTokensResponse, any>({
                path: `/v1/tokens/account/${account}`,
                method: "GET",
                secure: true,
                ...params
            })
    };
    webhookRoutes = {
        /**
         * @description List custom webhook routes
         *
         * @tags Webhooks
         * @name GetV1Webhookroutes
         * @summary List webhook routes
         * @request GET:/v1/webhookRoutes
         * @secure
         * @response `200` `WebhookRoutesListResponse` Successful
         */
        getV1Webhookroutes: (
            query?: {
                /**
                 * Page number (zero indexed, so use 0 for first page)
                 * @min 0
                 * @max 1048576
                 * @default 0
                 */
                page?: number;
                /**
                 * How many entries per page
                 * @min 1
                 * @max 1000
                 * @default 20
                 */
                pageSize?: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<WebhookRoutesListResponse, any>({
                path: `/v1/webhookRoutes`,
                method: "GET",
                query: query,
                secure: true,
                ...params
            }),

        /**
         * @description Retrieve webhook route content and information
         *
         * @tags Webhooks
         * @name GetV1WebhookroutesWebhookrouteWebhookroute
         * @summary Get webhook route information
         * @request GET:/v1/webhookRoutes/webhookRoute/{webhookRoute}
         * @secure
         * @response `200` `WebhookRouteResponse` Successful
         */
        getV1WebhookroutesWebhookrouteWebhookroute: (webhookRoute: string, params: RequestParams = {}) =>
            this.request<WebhookRouteResponse, any>({
                path: `/v1/webhookRoutes/webhookRoute/${webhookRoute}`,
                method: "GET",
                secure: true,
                ...params
            })
    };
    account = {
        /**
         * @description Returns stored information about the account. Passwords are not included.
         *
         * @tags Account
         * @name GetV1AccountAccount
         * @summary Get account info
         * @request GET:/v1/account/{account}
         * @secure
         * @response `200` `AccountResponse` Successful
         */
        getV1AccountAccount: (account: string, params: RequestParams = {}) =>
            this.request<AccountResponse, any>({
                path: `/v1/account/${account}`,
                method: "GET",
                secure: true,
                ...params
            }),

        /**
         * @description Stop syncing IMAP account and delete cached values
         *
         * @tags Account
         * @name DeleteV1AccountAccount
         * @summary Remove synced account
         * @request DELETE:/v1/account/{account}
         * @secure
         * @response `200` `DeleteRequestResponse` Successful
         */
        deleteV1AccountAccount: (account: string, params: RequestParams = {}) =>
            this.request<DeleteRequestResponse, any>({
                path: `/v1/account/${account}`,
                method: "DELETE",
                secure: true,
                ...params
            }),

        /**
         * @description Updates account information
         *
         * @tags Account
         * @name PutV1AccountAccount
         * @summary Update account info
         * @request PUT:/v1/account/{account}
         * @secure
         * @response `200` `Model33` Successful
         */
        putV1AccountAccount: (account: string, body: UpdateAccount, params: RequestParams = {}) =>
            this.request<Model33, any>({
                path: `/v1/account/${account}`,
                method: "PUT",
                body: body,
                secure: true,
                type: ContentType.Json,
                ...params
            }),

        /**
         * @description Get the active OAuth2 access token for an account. NB! This endpoint is disabled by default and needs activation on the Service configuration page.
         *
         * @tags Account
         * @name GetV1AccountAccountOauthtoken
         * @summary Get OAuth2 access token
         * @request GET:/v1/account/{account}/oauth-token
         * @secure
         * @response `200` `AccountTokenResponse` Successful
         */
        getV1AccountAccountOauthtoken: (account: string, params: RequestParams = {}) =>
            this.request<AccountTokenResponse, any>({
                path: `/v1/account/${account}/oauth-token`,
                method: "GET",
                secure: true,
                ...params
            }),

        /**
         * @description Lists messages in a mailbox folder
         *
         * @tags Message
         * @name GetV1AccountAccountMessages
         * @summary List messages in a folder
         * @request GET:/v1/account/{account}/messages
         * @secure
         * @response `200` `MessageList` Successful
         */
        getV1AccountAccountMessages: (
            account: string,
            query: {
                /** Mailbox folder path */
                path: string;
                /**
                 * Page number (zero indexed, so use 0 for first page)
                 * @min 0
                 * @max 1048576
                 * @default 0
                 */
                page?: number;
                /**
                 * How many entries per page
                 * @min 1
                 * @max 1000
                 * @default 20
                 */
                pageSize?: number;
                /**
                 * If enabled then fetch the data from the Document Store instead of IMAP
                 * @default false
                 */
                documentStore?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<MessageList, any>({
                path: `/v1/account/${account}/messages`,
                method: "GET",
                query: query,
                secure: true,
                ...params
            }),

        /**
         * @description Update message information for matching emails
         *
         * @tags Multi Message Actions
         * @name PutV1AccountAccountMessages
         * @summary Update messages
         * @request PUT:/v1/account/{account}/messages
         * @secure
         * @response `200` `MessageUpdateResponse` Successful
         */
        putV1AccountAccountMessages: (
            account: string,
            query: {
                /** Mailbox folder path */
                path: string;
            },
            body: MessagesUpdateRequest,
            params: RequestParams = {}
        ) =>
            this.request<MessageUpdateResponse, any>({
                path: `/v1/account/${account}/messages`,
                method: "PUT",
                query: query,
                body: body,
                secure: true,
                type: ContentType.Json,
                ...params
            }),

        /**
         * @description Lists all available mailboxes
         *
         * @tags Mailbox
         * @name GetV1AccountAccountMailboxes
         * @summary List mailboxes
         * @request GET:/v1/account/{account}/mailboxes
         * @secure
         * @response `200` `MailboxesFilterResponse` Successful
         */
        getV1AccountAccountMailboxes: (
            account: string,
            query?: {
                /**
                 * If true, then includes message counters in the response
                 * @default false
                 */
                counters?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<MailboxesFilterResponse, any>({
                path: `/v1/account/${account}/mailboxes`,
                method: "GET",
                query: query,
                secure: true,
                ...params
            }),

        /**
         * @description Returns details of a specific message. By default text content is not included, use textType value to force retrieving text
         *
         * @tags Message
         * @name GetV1AccountAccountMessageMessage
         * @summary Get message information
         * @request GET:/v1/account/{account}/message/{message}
         * @secure
         * @response `200` `Model8` Successful
         */
        getV1AccountAccountMessageMessage: (
            account: string,
            message: string,
            query?: {
                /**
                 * Max length of text content. This setting is ignored if `documentStore` is `true`.
                 * @min 0
                 * @max 1073741824
                 */
                maxBytes?: number;
                /** Which text content to return, use * for all. By default text content is not returned. */
                textType?: "html" | "plain" | "*";
                /**
                 * Shorthand option to fetch and preprocess HTML and inlined images. Overrides `textType`, `preProcessHtml`, and `preProcessHtml` options.
                 * @default false
                 */
                webSafeHtml?: boolean;
                /**
                 * If true, then fetches attached images and embeds these in the HTML as data URIs
                 * @default false
                 */
                embedAttachedImages?: boolean;
                /**
                 * If true, then pre-processes HTML for compatibility
                 * @default false
                 */
                preProcessHtml?: boolean;
                /**
                 * If true, then marks unseen email as seen while returning the message
                 * @default false
                 */
                markAsSeen?: boolean;
                /**
                 * If enabled then fetch the data from the Document Store instead of IMAP
                 * @default false
                 */
                documentStore?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<Model8, any>({
                path: `/v1/account/${account}/message/${message}`,
                method: "GET",
                query: query,
                secure: true,
                ...params
            }),

        /**
         * @description Move message to Trash or delete it if already in Trash
         *
         * @tags Message
         * @name DeleteV1AccountAccountMessageMessage
         * @summary Delete message
         * @request DELETE:/v1/account/{account}/message/{message}
         * @secure
         * @response `200` `MessageDeleteResponse` Successful
         */
        deleteV1AccountAccountMessageMessage: (
            account: string,
            message: string,
            query?: {
                /**
                 * Delete message even if not in Trash
                 * @default false
                 */
                force?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<MessageDeleteResponse, any>({
                path: `/v1/account/${account}/message/${message}`,
                method: "DELETE",
                query: query,
                secure: true,
                ...params
            }),

        /**
         * @description Update message information. Mainly this means changing message flag values
         *
         * @tags Message
         * @name PutV1AccountAccountMessageMessage
         * @summary Update message
         * @request PUT:/v1/account/{account}/message/{message}
         * @secure
         * @response `200` `MessageUpdateResponse` Successful
         */
        putV1AccountAccountMessageMessage: (account: string, message: string, body: MessageUpdate, params: RequestParams = {}) =>
            this.request<MessageUpdateResponse, any>({
                path: `/v1/account/${account}/message/${message}`,
                method: "PUT",
                body: body,
                secure: true,
                type: ContentType.Json,
                ...params
            }),

        /**
         * @description Fetches attachment file as a binary stream
         *
         * @tags Message
         * @name GetV1AccountAccountAttachmentAttachment
         * @summary Download attachment
         * @request GET:/v1/account/{account}/attachment/{attachment}
         * @secure
         * @response `default` `string` Successful
         */
        getV1AccountAccountAttachmentAttachment: (account: string, attachment: string, params: RequestParams = {}) =>
            this.request<any, string>({
                path: `/v1/account/${account}/attachment/${attachment}`,
                method: "GET",
                secure: true,
                ...params
            }),

        /**
         * @description Retrieves message text
         *
         * @tags Message
         * @name GetV1AccountAccountTextText
         * @summary Retrieve message text
         * @request GET:/v1/account/{account}/text/{text}
         * @secure
         * @response `200` `TextResponse` Successful
         */
        getV1AccountAccountTextText: (
            account: string,
            text: string,
            query?: {
                /**
                 * Max length of text content. This setting is ignored if `documentStore` is `true`.
                 * @min 0
                 * @max 1073741824
                 */
                maxBytes?: number;
                /**
                 * Which text content to return, use * for all. By default all contents are returned.
                 * @default "*"
                 */
                textType?: "html" | "plain" | "*";
                /**
                 * If enabled then fetch the data from the Document Store instead of IMAP
                 * @default false
                 */
                documentStore?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<TextResponse, any>({
                path: `/v1/account/${account}/text/${text}`,
                method: "GET",
                query: query,
                secure: true,
                ...params
            }),

        /**
         * @description Fetches raw message as a stream
         *
         * @tags Message
         * @name GetV1AccountAccountMessageMessageSource
         * @summary Download raw message
         * @request GET:/v1/account/{account}/message/{message}/source
         * @secure
         * @response `default` `string` Successful
         */
        getV1AccountAccountMessageMessageSource: (account: string, message: string, params: RequestParams = {}) =>
            this.request<any, string>({
                path: `/v1/account/${account}/message/${message}/source`,
                method: "GET",
                secure: true,
                ...params
            }),

        /**
         * @description Registers new IMAP account to be synced
         *
         * @tags Account
         * @name PostV1Account
         * @summary Register new account
         * @request POST:/v1/account
         * @secure
         * @response `200` `CreateAccountResponse` Successful
         */
        postV1Account: (body: CreateAccount, params: RequestParams = {}) =>
            this.request<CreateAccountResponse, any>({
                path: `/v1/account`,
                method: "POST",
                body: body,
                secure: true,
                type: ContentType.Json,
                ...params
            }),

        /**
         * @description Upload a message structure, compile it into an EML file and store it into selected mailbox.
         *
         * @tags Message
         * @name PostV1AccountAccountMessage
         * @summary Upload message
         * @request POST:/v1/account/{account}/message
         * @secure
         * @response `200` `MessageUploadResponse` Successful
         */
        postV1AccountAccountMessage: (account: string, body: MessageUpload, params: RequestParams = {}) =>
            this.request<MessageUploadResponse, any>({
                path: `/v1/account/${account}/message`,
                method: "POST",
                body: body,
                secure: true,
                type: ContentType.Json,
                ...params
            }),

        /**
         * @description Create new mailbox folder
         *
         * @tags Mailbox
         * @name PostV1AccountAccountMailbox
         * @summary Create mailbox
         * @request POST:/v1/account/{account}/mailbox
         * @secure
         * @response `200` `CreateMailboxResponse` Successful
         */
        postV1AccountAccountMailbox: (account: string, body: CreateMailbox, params: RequestParams = {}) =>
            this.request<CreateMailboxResponse, any>({
                path: `/v1/account/${account}/mailbox`,
                method: "POST",
                body: body,
                secure: true,
                type: ContentType.Json,
                ...params
            }),

        /**
         * @description Delete existing mailbox folder
         *
         * @tags Mailbox
         * @name DeleteV1AccountAccountMailbox
         * @summary Delete mailbox
         * @request DELETE:/v1/account/{account}/mailbox
         * @secure
         * @response `200` `DeleteMailboxResponse` Successful
         */
        deleteV1AccountAccountMailbox: (
            account: string,
            query: {
                /** Mailbox folder path to delete */
                path: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<DeleteMailboxResponse, any>({
                path: `/v1/account/${account}/mailbox`,
                method: "DELETE",
                query: query,
                secure: true,
                ...params
            }),

        /**
         * @description Rename an existing mailbox folder
         *
         * @tags Mailbox
         * @name PutV1AccountAccountMailbox
         * @summary Rename mailbox
         * @request PUT:/v1/account/{account}/mailbox
         * @secure
         * @response `200` `RenameMailboxResponse` Successful
         */
        putV1AccountAccountMailbox: (account: string, body: RenameMailbox, params: RequestParams = {}) =>
            this.request<RenameMailboxResponse, any>({
                path: `/v1/account/${account}/mailbox`,
                method: "PUT",
                body: body,
                secure: true,
                type: ContentType.Json,
                ...params
            }),

        /**
         * @description Filter messages from a mailbox folder by search options. Search is performed against a specific folder and not for the entire account.
         *
         * @tags Message
         * @name PostV1AccountAccountSearch
         * @summary Search for messages
         * @request POST:/v1/account/{account}/search
         * @secure
         * @response `200` `MessageList` Successful
         */
        postV1AccountAccountSearch: (
            account: string,
            body: Model24,
            query?: {
                /** Mailbox folder path. Not required if `documentStore` is `true` */
                path?: string;
                /**
                 * Page number (zero indexed, so use 0 for first page)
                 * @min 0
                 * @max 1048576
                 * @default 0
                 */
                page?: number;
                /**
                 * How many entries per page
                 * @min 1
                 * @max 1000
                 * @default 20
                 */
                pageSize?: number;
                /**
                 * If enabled then fetch the data from the Document Store instead of IMAP
                 * @default false
                 */
                documentStore?: boolean;
                /** If enabled then returns the ElasticSearch query for debugging as part of the response */
                exposeQuery?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<MessageList, any>({
                path: `/v1/account/${account}/search`,
                method: "POST",
                query: query,
                body: body,
                secure: true,
                type: ContentType.Json,
                ...params
            }),

        /**
         * @description Submit message for delivery. If reference message ID is provided then EmailEngine adds all headers and flags required for a reply/forward automatically.
         *
         * @tags Submit
         * @name PostV1AccountAccountSubmit
         * @summary Submit message for delivery
         * @request POST:/v1/account/{account}/submit
         * @secure
         * @response `200` `SubmitMessageResponse` Successful
         */
        postV1AccountAccountSubmit: (account: string, body: SubmitMessage, params: RequestParams = {}) =>
            this.request<SubmitMessageResponse, any>({
                path: `/v1/account/${account}/submit`,
                method: "POST",
                body: body,
                secure: true,
                type: ContentType.Json,
                ...params
            }),

        /**
         * @description Deletes all email indexes from Redis and ElasticSearch and re-creates the index for that account. You can only run a single flush operation at a time, so you must wait until the previous flush has finished before initiating a new one.
         *
         * @tags Account
         * @name PutV1AccountAccountFlush
         * @summary Request account flush
         * @request PUT:/v1/account/{account}/flush
         * @secure
         * @response `200` `RequestFlushResponse` Successful
         */
        putV1AccountAccountFlush: (account: string, body: RequestFlush, params: RequestParams = {}) =>
            this.request<RequestFlushResponse, any>({
                path: `/v1/account/${account}/flush`,
                method: "PUT",
                body: body,
                secure: true,
                type: ContentType.Json,
                ...params
            }),

        /**
         * @description Requests connection to be reconnected
         *
         * @tags Account
         * @name PutV1AccountAccountReconnect
         * @summary Request reconnect
         * @request PUT:/v1/account/{account}/reconnect
         * @secure
         * @response `200` `RequestReconnectResponse` Successful
         */
        putV1AccountAccountReconnect: (account: string, body: RequestReconnect, params: RequestParams = {}) =>
            this.request<RequestReconnectResponse, any>({
                path: `/v1/account/${account}/reconnect`,
                method: "PUT",
                body: body,
                secure: true,
                type: ContentType.Json,
                ...params
            }),

        /**
         * @description Requests account syncing to be run immediatelly
         *
         * @tags Account
         * @name PutV1AccountAccountSync
         * @summary Request syncing
         * @request PUT:/v1/account/{account}/sync
         * @secure
         * @response `200` `RequestSyncResponse` Successful
         */
        putV1AccountAccountSync: (account: string, body: RequestSync, params: RequestParams = {}) =>
            this.request<RequestSyncResponse, any>({
                path: `/v1/account/${account}/sync`,
                method: "PUT",
                body: body,
                secure: true,
                type: ContentType.Json,
                ...params
            }),

        /**
         * @description Move messages to Trash or delete these if already in Trash
         *
         * @tags Multi Message Actions
         * @name PutV1AccountAccountMessagesDelete
         * @summary Delete messages
         * @request PUT:/v1/account/{account}/messages/delete
         * @secure
         * @response `200` `MessagesDeleteResponse` Successful
         */
        putV1AccountAccountMessagesDelete: (
            account: string,
            query: {
                /** Mailbox folder path */
                path: string;
                /**
                 * Delete messages even if not in Trash
                 * @default false
                 */
                force?: boolean;
            },
            body: MessagesDeleteRequest,
            params: RequestParams = {}
        ) =>
            this.request<MessagesDeleteResponse, any>({
                path: `/v1/account/${account}/messages/delete`,
                method: "PUT",
                query: query,
                body: body,
                secure: true,
                type: ContentType.Json,
                ...params
            }),

        /**
         * @description Move messages matching to a search query to another folder
         *
         * @tags Multi Message Actions
         * @name PutV1AccountAccountMessagesMove
         * @summary Move messages
         * @request PUT:/v1/account/{account}/messages/move
         * @secure
         * @response `200` `MessagesMoveResponse` Successful
         */
        putV1AccountAccountMessagesMove: (
            account: string,
            query: {
                /** Source mailbox folder path */
                path: string;
            },
            body: MessagesMoveRequest,
            params: RequestParams = {}
        ) =>
            this.request<MessagesMoveResponse, any>({
                path: `/v1/account/${account}/messages/move`,
                method: "PUT",
                query: query,
                body: body,
                secure: true,
                type: ContentType.Json,
                ...params
            }),

        /**
         * @description Move message to another folder
         *
         * @tags Message
         * @name PutV1AccountAccountMessageMessageMove
         * @summary Move message
         * @request PUT:/v1/account/{account}/message/{message}/move
         * @secure
         * @response `200` `MessageMoveResponse` Successful
         */
        putV1AccountAccountMessageMessageMove: (account: string, message: string, body: MessageMove, params: RequestParams = {}) =>
            this.request<MessageMoveResponse, any>({
                path: `/v1/account/${account}/message/${message}/move`,
                method: "PUT",
                body: body,
                secure: true,
                type: ContentType.Json,
                ...params
            })
    };
    blocklist = {
        /**
         * @description List blocked addresses for a list
         *
         * @tags Blocklists
         * @name GetV1BlocklistListid
         * @summary List blocklist entries
         * @request GET:/v1/blocklist/{listId}
         * @secure
         * @response `200` `BlocklistListResponse` Successful
         */
        getV1BlocklistListid: (
            listId: string,
            query?: {
                /**
                 * Page number (zero indexed, so use 0 for first page)
                 * @min 0
                 * @max 1048576
                 * @default 0
                 */
                page?: number;
                /**
                 * How many entries per page
                 * @min 1
                 * @max 1000
                 * @default 20
                 */
                pageSize?: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<BlocklistListResponse, any>({
                path: `/v1/blocklist/${listId}`,
                method: "GET",
                query: query,
                secure: true,
                ...params
            }),

        /**
         * @description Add an email address to a blocklist
         *
         * @tags Blocklists
         * @name PostV1BlocklistListid
         * @summary Add to blocklist
         * @request POST:/v1/blocklist/{listId}
         * @secure
         * @response `200` `BlocklistListAddResponse` Successful
         */
        postV1BlocklistListid: (listId: string, body: BlocklistListAddPayload, params: RequestParams = {}) =>
            this.request<BlocklistListAddResponse, any>({
                path: `/v1/blocklist/${listId}`,
                method: "POST",
                body: body,
                secure: true,
                type: ContentType.Json,
                ...params
            }),

        /**
         * @description Delete a blocked email address from a list
         *
         * @tags Blocklists
         * @name DeleteV1BlocklistListid
         * @summary Remove from blocklist
         * @request DELETE:/v1/blocklist/{listId}
         * @secure
         * @response `200` `DeleteBlocklistResponse` Successful
         */
        deleteV1BlocklistListid: (
            listId: string,
            query: {
                /** Email address to remove from the list */
                recipient: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<DeleteBlocklistResponse, any>({
                path: `/v1/blocklist/${listId}`,
                method: "DELETE",
                query: query,
                secure: true,
                ...params
            })
    };
    gateway = {
        /**
         * @description Returns stored information about the gateway. Passwords are not included.
         *
         * @tags SMTP Gateway
         * @name GetV1GatewayGateway
         * @summary Get gateway info
         * @request GET:/v1/gateway/{gateway}
         * @secure
         * @response `200` `GatewayResponse` Successful
         */
        getV1GatewayGateway: (gateway: string, params: RequestParams = {}) =>
            this.request<GatewayResponse, any>({
                path: `/v1/gateway/${gateway}`,
                method: "GET",
                secure: true,
                ...params
            }),

        /**
         * @description Delete SMTP gateway data
         *
         * @tags SMTP Gateway
         * @name DeleteV1GatewayGateway
         * @summary Remove SMTP gateway
         * @request DELETE:/v1/gateway/{gateway}
         * @secure
         * @response `200` `Model29` Successful
         */
        deleteV1GatewayGateway: (gateway: string, params: RequestParams = {}) =>
            this.request<Model29, any>({
                path: `/v1/gateway/${gateway}`,
                method: "DELETE",
                secure: true,
                ...params
            }),

        /**
         * @description Registers a new SMP gateway
         *
         * @tags SMTP Gateway
         * @name PostV1Gateway
         * @summary Register new gateway
         * @request POST:/v1/gateway
         * @secure
         * @response `200` `CreateGatewayResponse` Successful
         */
        postV1Gateway: (body: CreateGateway, params: RequestParams = {}) =>
            this.request<CreateGatewayResponse, any>({
                path: `/v1/gateway`,
                method: "POST",
                body: body,
                secure: true,
                type: ContentType.Json,
                ...params
            }),

        /**
         * @description Updates gateway information
         *
         * @tags SMTP Gateway
         * @name PutV1GatewayEditGateway
         * @summary Update gateway info
         * @request PUT:/v1/gateway/edit/{gateway}
         * @secure
         * @response `200` `UpdateGatewayResponse` Successful
         */
        putV1GatewayEditGateway: (gateway: string, body: Model36, params: RequestParams = {}) =>
            this.request<UpdateGatewayResponse, any>({
                path: `/v1/gateway/edit/${gateway}`,
                method: "PUT",
                body: body,
                secure: true,
                type: ContentType.Json,
                ...params
            })
    };
    logs = {
        /**
         * @description Output is a downloadable text file
         *
         * @tags Logs
         * @name GetV1LogsAccount
         * @summary Return IMAP logs for an account
         * @request GET:/v1/logs/{account}
         * @secure
         * @response `default` `string` Successful
         */
        getV1LogsAccount: (account: string, params: RequestParams = {}) =>
            this.request<any, string>({
                path: `/v1/logs/${account}`,
                method: "GET",
                secure: true,
                ...params
            })
    };
    deliveryTest = {
        /**
         * @description Check delivery test status
         *
         * @tags Delivery Test
         * @name GetV1DeliverytestCheckDeliverytest
         * @summary Check test status
         * @request GET:/v1/delivery-test/check/{deliveryTest}
         * @secure
         * @response `200` `DeliveryCheckResponse` Successful
         */
        getV1DeliverytestCheckDeliverytest: (deliveryTest: string, params: RequestParams = {}) =>
            this.request<DeliveryCheckResponse, any>({
                path: `/v1/delivery-test/check/${deliveryTest}`,
                method: "GET",
                secure: true,
                ...params
            }),

        /**
         * @description Initiate a delivery test
         *
         * @tags Delivery Test
         * @name PostV1DeliverytestAccountAccount
         * @summary Create delivery test
         * @request POST:/v1/delivery-test/account/{account}
         * @secure
         * @response `200` `DeliveryStartResponse` Successful
         */
        postV1DeliverytestAccountAccount: (account: string, body: DeliveryStartRequest, params: RequestParams = {}) =>
            this.request<DeliveryStartResponse, any>({
                path: `/v1/delivery-test/account/${account}`,
                method: "POST",
                body: body,
                secure: true,
                type: ContentType.Json,
                ...params
            })
    };
    token = {
        /**
         * @description Provisions a new access token for an account
         *
         * @tags Access Tokens
         * @name PostV1Token
         * @summary Provision an access token
         * @request POST:/v1/token
         * @secure
         * @response `200` `CreateTokenResponse` Successful
         */
        postV1Token: (body: CreateToken, params: RequestParams = {}) =>
            this.request<CreateTokenResponse, any>({
                path: `/v1/token`,
                method: "POST",
                body: body,
                secure: true,
                type: ContentType.Json,
                ...params
            }),

        /**
         * @description Delete an access token
         *
         * @tags Access Tokens
         * @name DeleteV1TokenToken
         * @summary Remove a token
         * @request DELETE:/v1/token/{token}
         * @secure
         * @response `200` `DeleteTokenRequestResponse` Successful
         */
        deleteV1TokenToken: (token: string, params: RequestParams = {}) =>
            this.request<DeleteTokenRequestResponse, any>({
                path: `/v1/token/${token}`,
                method: "DELETE",
                secure: true,
                ...params
            })
    };
    verifyAccount = {
        /**
         * @description Checks if can connect and authenticate using provided account info
         *
         * @tags Account
         * @name PostV1Verifyaccount
         * @summary Verify IMAP and SMTP settings
         * @request POST:/v1/verifyAccount
         * @secure
         * @response `200` `VerifyAccountResponse` Successful
         */
        postV1Verifyaccount: (body: VerifyAccount, params: RequestParams = {}) =>
            this.request<VerifyAccountResponse, any>({
                path: `/v1/verifyAccount`,
                method: "POST",
                body: body,
                secure: true,
                type: ContentType.Json,
                ...params
            })
    };
    authentication = {
        /**
         * @description Generates a redirect link to the hosted authentication form
         *
         * @tags Account
         * @name PostV1AuthenticationForm
         * @summary Generate authentication link
         * @request POST:/v1/authentication/form
         * @secure
         * @response `200` `RequestAuthFormResponse` Successful
         */
        postV1AuthenticationForm: (body: RequestAuthForm, params: RequestParams = {}) =>
            this.request<RequestAuthFormResponse, any>({
                path: `/v1/authentication/form`,
                method: "POST",
                body: body,
                secure: true,
                type: ContentType.Json,
                ...params
            })
    };
    chat = {
        /**
         * @description Use OpenAI API and embeddings stored in the Document Store to "chat" with account emails. Requires Document Store indexing and the "Chat with emails" feature to be enabled.
         *
         * @tags Chat
         * @name PostV1ChatAccount
         * @summary Chat with emails
         * @request POST:/v1/chat/{account}
         * @secure
         * @response `200` `ReturnChatResponse` Successful
         */
        postV1ChatAccount: (account: string, body: RequestChat, params: RequestParams = {}) =>
            this.request<ReturnChatResponse, any>({
                path: `/v1/chat/${account}`,
                method: "POST",
                body: body,
                secure: true,
                type: ContentType.Json,
                ...params
            })
    };
    unified = {
        /**
         * @description Filter messages from the Document Store for multiple accounts or paths. Document Store must be enabled for the unified search to work.
         *
         * @tags Message
         * @name PostV1UnifiedSearch
         * @summary Unified search for messages
         * @request POST:/v1/unified/search
         * @secure
         * @response `200` `MessageList` Successful
         */
        postV1UnifiedSearch: (
            body: UnifiedSearchQuery,
            query?: {
                /**
                 * Page number (zero indexed, so use 0 for first page)
                 * @min 0
                 * @max 1048576
                 * @default 0
                 */
                page?: number;
                /**
                 * How many entries per page
                 * @min 1
                 * @max 1000
                 * @default 20
                 */
                pageSize?: number;
                /** If enabled then returns the ElasticSearch query for debugging as part of the response */
                exposeQuery?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<MessageList, any>({
                path: `/v1/unified/search`,
                method: "POST",
                query: query,
                body: body,
                secure: true,
                type: ContentType.Json,
                ...params
            })
    };
}
