// PF pricing plans
const STRIPE_PRODUCT_PF_MONTHLY_12 = process.env.NEXT_PUBLIC_STRIPE_PRODUCT_PF_MONTHLY_12;
const STRIPE_PRODUCT_PF_YEARLY = process.env.NEXT_PUBLIC_STRIPE_PRODUCT_PF_YEARLY;
const STRIPE_PRODUCT_PF_MONTHLY_C21 = process.env.NEXT_PUBLIC_STRIPE_PRODUCT_PF_MONTHLY_C21;
const STRIPE_PRODUCT_PF_YEARLY_C21 = process.env.NEXT_PUBLIC_STRIPE_PRODUCT_PF_YEARLY_C21;

// Basic pricing plans
const STRIPE_PRODUCT_BASIC_MONTHLY = process.env.NEXT_PUBLIC_STRIPE_PRODUCT_BASIC_MONTHLY;
const STRIPE_PRODUCT_BASIC_MONTHLY_12 = process.env.NEXT_PUBLIC_STRIPE_PRODUCT_BASIC_MONTHLY_12;
const STRIPE_PRODUCT_BASIC_YEARLY = process.env.NEXT_PUBLIC_STRIPE_PRODUCT_BASIC_YEARLY;
const STRIPE_PRODUCT_BASIC_YEARLY_2_YEARS = process.env.NEXT_PUBLIC_STRIPE_PRODUCT_BASIC_YEARLY_2_YEARS;
const STRIPE_PRODUCT_BASIC_YEARLY_3_YEARS = process.env.NEXT_PUBLIC_STRIPE_PRODUCT_BASIC_YEARLY_3_YEARS;
const STRIPE_PRODUCT_BASIC_WEBINAR_YEARLY = process.env.NEXT_PUBLIC_STRIPE_PRODUCT_BASIC_WEBINAR_YEARLY;
// currently active plans
const STRIPE_PRODUCT_BASIC_MONTHLY_23Q4 = process.env.NEXT_PUBLIC_STRIPE_PRODUCT_BASIC_MONTHLY_23Q4;
const STRIPE_PRODUCT_BASIC_YEARLY_23Q4 = process.env.NEXT_PUBLIC_STRIPE_PRODUCT_BASIC_YEARLY_23Q4;
const STRIPE_PRODUCT_BASIC_YEARLY_2_YEARS_23Q4 = process.env.NEXT_PUBLIC_STRIPE_PRODUCT_BASIC_YEARLY_2_YEARS_23Q4;
const STRIPE_PRODUCT_BASIC_MONTHLY_C21 = process.env.NEXT_PUBLIC_STRIPE_PRODUCT_BASIC_MONTHLY_C21;
const STRIPE_PRODUCT_BASIC_YEARLY_C21 = process.env.NEXT_PUBLIC_STRIPE_PRODUCT_BASIC_YEARLY_C21;

// Pro pricing plans
const STRIPE_PRODUCT_PRO_MONTHLY = process.env.NEXT_PUBLIC_STRIPE_PRODUCT_PRO_MONTHLY;
const STRIPE_PRODUCT_PRO_MONTHLY_12 = process.env.NEXT_PUBLIC_STRIPE_PRODUCT_PRO_MONTHLY_12;
const STRIPE_PRODUCT_PRO_YEARLY = process.env.NEXT_PUBLIC_STRIPE_PRODUCT_PRO_YEARLY;
const STRIPE_PRODUCT_PRO_YEARLY_2_YEARS = process.env.NEXT_PUBLIC_STRIPE_PRODUCT_PRO_YEARLY_2_YEARS;
const STRIPE_PRODUCT_PRO_YEARLY_3_YEARS = process.env.NEXT_PUBLIC_STRIPE_PRODUCT_PRO_YEARLY_3_YEARS;
// currently active plans
const STRIPE_PRODUCT_PRO_MONTHLY_23Q4 = process.env.NEXT_PUBLIC_STRIPE_PRODUCT_PRO_MONTHLY_23Q4;
const STRIPE_PRODUCT_PRO_YEARLY_23Q4 = process.env.NEXT_PUBLIC_STRIPE_PRODUCT_PRO_YEARLY_23Q4;
const STRIPE_PRODUCT_PRO_HALF_YEARLY_23Q4 = process.env.NEXT_PUBLIC_STRIPE_PRODUCT_PRO_HALF_YEARLY_23Q4;
const STRIPE_PRODUCT_PRO_YEARLY_2_YEARS_23Q4 = process.env.NEXT_PUBLIC_STRIPE_PRODUCT_PRO_YEARLY_2_YEARS_23Q4;
const STRIPE_PRODUCT_PRO_WEBINAR_24Q1 = process.env.NEXT_PUBLIC_STRIPE_PRODUCT_PRO_WEBINAR_24Q1;

// Elite pricing plans
const STRIPE_PRODUCT_ELITE_MONTHLY = process.env.NEXT_PUBLIC_STRIPE_PRODUCT_ELITE_MONTHLY;
const STRIPE_PRODUCT_ELITE_MONTHLY_12 = process.env.NEXT_PUBLIC_STRIPE_PRODUCT_ELITE_MONTHLY_12;
const STRIPE_PRODUCT_ELITE_YEARLY = process.env.NEXT_PUBLIC_STRIPE_PRODUCT_ELITE_YEARLY;
const STRIPE_PRODUCT_ELITE_YEARLY_2_YEARS = process.env.NEXT_PUBLIC_STRIPE_PRODUCT_ELITE_YEARLY_2_YEARS;
const STRIPE_PRODUCT_ELITE_YEARLY_3_YEARS = process.env.NEXT_PUBLIC_STRIPE_PRODUCT_ELITE_YEARLY_3_YEARS;
// Pause Price
export const STRIPE_PRODUCT_PAUSE = process.env.NEXT_PUBLIC_STRIPE_PRODUCT_PAUSE;
// Content Prices
const STRIPE_PRODUCT_CONTENTS_SELLER = process.env.NEXT_PUBLIC_STRIPE_PRODUCT_CONTENTS_SELLER;
const STRIPE_PRODUCT_CONTENTS_BUYER = process.env.NEXT_PUBLIC_STRIPE_PRODUCT_CONTENTS_BUYER;
const STRIPE_PRODUCT_CONTENTS_BRAND = process.env.NEXT_PUBLIC_STRIPE_PRODUCT_CONTENTS_BRAND;
const STRIPE_PRODUCT_CONTENTS_COMPLETE_LP = process.env.NEXT_PUBLIC_STRIPE_PRODUCT_CONTENTS_COMPLETE_LP;
const STRIPE_PRODUCT_CONTENTS_COMPLETE_WEBSITE = process.env.NEXT_PUBLIC_STRIPE_PRODUCT_CONTENTS_COMPLETE_WEBSITE;

// Stripe Product definitions
export const StripeProduct = {
    PF: {
        MONTHLY_12: STRIPE_PRODUCT_PF_MONTHLY_12,
        YEARLY: STRIPE_PRODUCT_PF_YEARLY,
        MONTHLY_C21: STRIPE_PRODUCT_PF_MONTHLY_C21,
        YEARLY_C21: STRIPE_PRODUCT_PF_YEARLY_C21
    },
    BASIC: {
        MONTHLY: STRIPE_PRODUCT_BASIC_MONTHLY,
        MONTHLY_12: STRIPE_PRODUCT_BASIC_MONTHLY_12,
        YEARLY: STRIPE_PRODUCT_BASIC_YEARLY,
        YEARLY_2_YEARS: STRIPE_PRODUCT_BASIC_YEARLY_2_YEARS,
        YEARLY_3_YEARS: STRIPE_PRODUCT_BASIC_YEARLY_3_YEARS,
        WEBINAR_YEARLY: STRIPE_PRODUCT_BASIC_WEBINAR_YEARLY,
        // currently active
        MONTHLY_23Q4: STRIPE_PRODUCT_BASIC_MONTHLY_23Q4,
        YEARLY_23Q4: STRIPE_PRODUCT_BASIC_YEARLY_23Q4,
        YEARLY_2_YEARS_23Q4: STRIPE_PRODUCT_BASIC_YEARLY_2_YEARS_23Q4,
        MONTHLY_C21: STRIPE_PRODUCT_BASIC_MONTHLY_C21,
        YEARLY_C21: STRIPE_PRODUCT_BASIC_YEARLY_C21
    },
    PRO: {
        MONTHLY: STRIPE_PRODUCT_PRO_MONTHLY,
        MONTHLY_12: STRIPE_PRODUCT_PRO_MONTHLY_12,
        YEARLY: STRIPE_PRODUCT_PRO_YEARLY,
        YEARLY_2_YEARS: STRIPE_PRODUCT_PRO_YEARLY_2_YEARS,
        YEARLY_3_YEARS: STRIPE_PRODUCT_PRO_YEARLY_3_YEARS,
        // currently active
        MONTHLY_23Q4: STRIPE_PRODUCT_PRO_MONTHLY_23Q4,
        HALF_YEARLY_23Q4: STRIPE_PRODUCT_PRO_HALF_YEARLY_23Q4,
        YEARLY_23Q4: STRIPE_PRODUCT_PRO_YEARLY_23Q4,
        YEARLY_2_YEARS_23Q4: STRIPE_PRODUCT_PRO_YEARLY_2_YEARS_23Q4,
        WEBINAR_24Q1: STRIPE_PRODUCT_PRO_WEBINAR_24Q1
    },
    ELITE: {
        MONTHLY: STRIPE_PRODUCT_ELITE_MONTHLY,
        MONTHLY_12: STRIPE_PRODUCT_ELITE_MONTHLY_12,
        YEARLY: STRIPE_PRODUCT_ELITE_YEARLY,
        YEARLY_2_YEARS: STRIPE_PRODUCT_ELITE_YEARLY_2_YEARS,
        YEARLY_3_YEARS: STRIPE_PRODUCT_ELITE_YEARLY_3_YEARS
    },
    CONTENT_BUNDLE: {
        SELLER: STRIPE_PRODUCT_CONTENTS_SELLER,
        BUYER: STRIPE_PRODUCT_CONTENTS_BUYER,
        BRAND: STRIPE_PRODUCT_CONTENTS_BRAND,
        COMPLETE_LP: STRIPE_PRODUCT_CONTENTS_COMPLETE_LP,
        COMPLETE_WEBSITE: STRIPE_PRODUCT_CONTENTS_COMPLETE_WEBSITE
    }
};

export const intervalIsTwoYears = [
    STRIPE_PRODUCT_BASIC_YEARLY_2_YEARS,
    STRIPE_PRODUCT_BASIC_YEARLY_2_YEARS_23Q4,
    STRIPE_PRODUCT_PRO_YEARLY_2_YEARS,
    STRIPE_PRODUCT_PRO_YEARLY_2_YEARS_23Q4,
    STRIPE_PRODUCT_ELITE_YEARLY_2_YEARS
];
export const intervalIsThreeYears = [STRIPE_PRODUCT_BASIC_YEARLY_3_YEARS, STRIPE_PRODUCT_PRO_YEARLY_3_YEARS, STRIPE_PRODUCT_ELITE_YEARLY_3_YEARS];

export enum StripePlanTier {
    PF = "PF",
    BASIC = "BASIC",
    BASIC_23Q4 = "BASIC_23Q4",
    PRO = "PRO",
    PRO_23Q4 = "PRO_23Q4",
    ELITE = "ELITE"
}

export const priceToLicense = {
    // PF
    [STRIPE_PRODUCT_PF_MONTHLY_12]: StripePlanTier.PF,
    [STRIPE_PRODUCT_PF_YEARLY]: StripePlanTier.PF,
    [STRIPE_PRODUCT_PF_MONTHLY_C21]: StripePlanTier.PF,
    [STRIPE_PRODUCT_PF_YEARLY_C21]: StripePlanTier.PF,
    // Basic
    [STRIPE_PRODUCT_BASIC_MONTHLY as string]: StripePlanTier.BASIC,
    [STRIPE_PRODUCT_BASIC_MONTHLY_12 as string]: StripePlanTier.BASIC,
    [STRIPE_PRODUCT_BASIC_YEARLY as string]: StripePlanTier.BASIC,
    [STRIPE_PRODUCT_BASIC_YEARLY_2_YEARS as string]: StripePlanTier.BASIC,
    [STRIPE_PRODUCT_BASIC_YEARLY_3_YEARS as string]: StripePlanTier.BASIC,
    [STRIPE_PRODUCT_BASIC_WEBINAR_YEARLY as string]: StripePlanTier.BASIC,
    [STRIPE_PRODUCT_BASIC_MONTHLY_C21 as string]: StripePlanTier.BASIC,
    [STRIPE_PRODUCT_BASIC_YEARLY_C21 as string]: StripePlanTier.BASIC,
    // currently active
    [STRIPE_PRODUCT_BASIC_MONTHLY_23Q4 as string]: StripePlanTier.BASIC_23Q4,
    [STRIPE_PRODUCT_BASIC_YEARLY_23Q4 as string]: StripePlanTier.BASIC_23Q4,
    [STRIPE_PRODUCT_BASIC_YEARLY_2_YEARS_23Q4 as string]: StripePlanTier.BASIC_23Q4,
    [STRIPE_PRODUCT_BASIC_MONTHLY_C21 as string]: StripePlanTier.BASIC_23Q4,
    [STRIPE_PRODUCT_BASIC_YEARLY_C21 as string]: StripePlanTier.BASIC_23Q4,
    // Pro
    [STRIPE_PRODUCT_PRO_MONTHLY as string]: StripePlanTier.PRO,
    [STRIPE_PRODUCT_PRO_MONTHLY_12 as string]: StripePlanTier.PRO,
    [STRIPE_PRODUCT_PRO_YEARLY as string]: StripePlanTier.PRO,
    [STRIPE_PRODUCT_PRO_YEARLY_2_YEARS as string]: StripePlanTier.PRO,
    [STRIPE_PRODUCT_PRO_YEARLY_3_YEARS as string]: StripePlanTier.PRO,
    // currently active
    [STRIPE_PRODUCT_PRO_MONTHLY_23Q4 as string]: StripePlanTier.PRO_23Q4,
    [STRIPE_PRODUCT_PRO_YEARLY_23Q4 as string]: StripePlanTier.PRO_23Q4,
    [STRIPE_PRODUCT_PRO_HALF_YEARLY_23Q4 as string]: StripePlanTier.PRO_23Q4,
    [STRIPE_PRODUCT_PRO_YEARLY_2_YEARS_23Q4 as string]: StripePlanTier.PRO_23Q4,
    [STRIPE_PRODUCT_PRO_WEBINAR_24Q1 as string]: StripePlanTier.PRO_23Q4,
    // Elite
    [STRIPE_PRODUCT_ELITE_MONTHLY as string]: StripePlanTier.ELITE,
    [STRIPE_PRODUCT_ELITE_MONTHLY_12 as string]: StripePlanTier.ELITE,
    [STRIPE_PRODUCT_ELITE_YEARLY as string]: StripePlanTier.ELITE,
    [STRIPE_PRODUCT_ELITE_YEARLY_2_YEARS as string]: StripePlanTier.ELITE,
    [STRIPE_PRODUCT_ELITE_YEARLY_3_YEARS as string]: StripePlanTier.ELITE
    // // Content Bundle
    // [STRIPE_PRODUCT_CONTENTS_SELLER as string]: StripeProduct.CONTENT_BUNDLE.SELLER,
    // [STRIPE_PRODUCT_CONTENTS_BUYER as string]: StripeProduct.CONTENT_BUNDLE.BUYER,
    // [STRIPE_PRODUCT_CONTENTS_BRAND as string]: StripeProduct.CONTENT_BUNDLE.BRAND,
    // [STRIPE_PRODUCT_CONTENTS_COMPLETE_LP as string]: StripeProduct.CONTENT_BUNDLE.COMPLETE_LP,
    // [STRIPE_PRODUCT_CONTENTS_COMPLETE_WEBSITE as string]: StripeProduct.CONTENT_BUNDLE.COMPLETE_WEBSITE
};

export const priceToTermDuration = {
    // PF
    [STRIPE_PRODUCT_PF_MONTHLY_12]: { duration: 12, unit: "month" },
    [STRIPE_PRODUCT_PF_YEARLY]: { duration: 1, unit: "year" },
    [STRIPE_PRODUCT_PF_MONTHLY_C21]: { duration: 12, unit: "month" },
    [STRIPE_PRODUCT_PF_YEARLY_C21]: { duration: 1, unit: "year" },
    // Basic
    [STRIPE_PRODUCT_BASIC_MONTHLY as string]: { duration: 6, unit: "month" },
    [STRIPE_PRODUCT_BASIC_MONTHLY_12 as string]: { duration: 12, unit: "month" },
    [STRIPE_PRODUCT_BASIC_MONTHLY_23Q4 as string]: { duration: 12, unit: "month" },
    [STRIPE_PRODUCT_BASIC_YEARLY as string]: { duration: 1, unit: "year" },
    [STRIPE_PRODUCT_BASIC_YEARLY_23Q4 as string]: { duration: 1, unit: "year" },
    [STRIPE_PRODUCT_BASIC_WEBINAR_YEARLY as string]: { duration: 1, unit: "year" },
    [STRIPE_PRODUCT_BASIC_YEARLY_2_YEARS as string]: { duration: 2, unit: "year" },
    [STRIPE_PRODUCT_BASIC_YEARLY_2_YEARS_23Q4 as string]: { duration: 2, unit: "year" },
    [STRIPE_PRODUCT_BASIC_YEARLY_3_YEARS as string]: { duration: 3, unit: "year" },
    [STRIPE_PRODUCT_BASIC_MONTHLY_C21 as string]: { duration: 12, unit: "month" },
    [STRIPE_PRODUCT_BASIC_YEARLY_C21 as string]: { duration: 1, unit: "year" },
    // Pro
    [STRIPE_PRODUCT_PRO_MONTHLY as string]: { duration: 6, unit: "month" },
    [STRIPE_PRODUCT_PRO_MONTHLY_12 as string]: { duration: 12, unit: "month" },
    [STRIPE_PRODUCT_PRO_MONTHLY_23Q4 as string]: { duration: 12, unit: "month" },
    [STRIPE_PRODUCT_PRO_YEARLY as string]: { duration: 1, unit: "year" },
    [STRIPE_PRODUCT_PRO_YEARLY_23Q4 as string]: { duration: 1, unit: "year" },
    [STRIPE_PRODUCT_PRO_HALF_YEARLY_23Q4 as string]: { duration: 12, unit: "month" },
    [STRIPE_PRODUCT_PRO_WEBINAR_24Q1 as string]: { duration: 1, unit: "year" },
    [STRIPE_PRODUCT_PRO_YEARLY_2_YEARS as string]: { duration: 2, unit: "year" },
    [STRIPE_PRODUCT_PRO_YEARLY_2_YEARS_23Q4 as string]: { duration: 2, unit: "year" },
    [STRIPE_PRODUCT_PRO_YEARLY_3_YEARS as string]: { duration: 3, unit: "year" },
    // Elite
    [STRIPE_PRODUCT_ELITE_MONTHLY as string]: { duration: 6, unit: "month" },
    [STRIPE_PRODUCT_ELITE_MONTHLY_12 as string]: { duration: 12, unit: "month" },
    [STRIPE_PRODUCT_ELITE_YEARLY as string]: { duration: 1, unit: "year" },
    [STRIPE_PRODUCT_ELITE_YEARLY_2_YEARS as string]: { duration: 2, unit: "year" },
    [STRIPE_PRODUCT_ELITE_YEARLY_3_YEARS as string]: { duration: 3, unit: "year" }
};

const priceLabel = (price: number, whenMonthly?: number) => ({
    price,
    yearlyToMonthly: whenMonthly ? Math.round((price / 12) * 100) / 100 : 0,
    saved: whenMonthly ? whenMonthly * 12 - price : 0
});

export const priceToPriceLabel = {
    // PF
    [STRIPE_PRODUCT_PF_MONTHLY_12]: priceLabel(67),
    [STRIPE_PRODUCT_PF_YEARLY]: priceLabel(724, 67),
    [STRIPE_PRODUCT_PF_MONTHLY_C21]: priceLabel(47),
    [STRIPE_PRODUCT_PF_YEARLY_C21]: priceLabel(516, 47),
    // Basic
    // [STRIPE_PRODUCT_BASIC_MONTHLY as string]: priceLabel(97),
    // [STRIPE_PRODUCT_BASIC_MONTHLY_12 as string]: priceLabel(97),
    // [STRIPE_PRODUCT_BASIC_YEARLY as string]: priceLabel(1048, 87),
    // [STRIPE_PRODUCT_BASIC_YEARLY_2_YEARS as string]: priceLabel(77),
    // [STRIPE_PRODUCT_BASIC_YEARLY_3_YEARS as string]: priceLabel(77),
    // [STRIPE_PRODUCT_BASIC_WEBINAR_YEARLY as string]: priceLabel(77),
    // currently active
    [STRIPE_PRODUCT_BASIC_MONTHLY_23Q4 as string]: priceLabel(97),
    [STRIPE_PRODUCT_BASIC_YEARLY_23Q4 as string]: priceLabel(1048, 97),
    [STRIPE_PRODUCT_BASIC_YEARLY_2_YEARS_23Q4 as string]: priceLabel(1048, 97),
    [STRIPE_PRODUCT_BASIC_MONTHLY_C21 as string]: priceLabel(89),
    [STRIPE_PRODUCT_BASIC_YEARLY_C21 as string]: priceLabel(972, 89),
    // Pro
    // [STRIPE_PRODUCT_PRO_MONTHLY as string]: StripePlanTier.PRO,
    // [STRIPE_PRODUCT_PRO_MONTHLY_12 as string]: StripePlanTier.PRO,
    // [STRIPE_PRODUCT_PRO_YEARLY as string]: StripePlanTier.PRO,
    // [STRIPE_PRODUCT_PRO_YEARLY_2_YEARS as string]: StripePlanTier.PRO,
    // [STRIPE_PRODUCT_PRO_YEARLY_3_YEARS as string]: StripePlanTier.PRO,
    // currently active
    [STRIPE_PRODUCT_PRO_MONTHLY_23Q4 as string]: priceLabel(147),
    [STRIPE_PRODUCT_PRO_YEARLY_23Q4 as string]: priceLabel(1587, 147),
    [STRIPE_PRODUCT_PRO_HALF_YEARLY_23Q4 as string]: priceLabel(793.5, 147),
    [STRIPE_PRODUCT_PRO_YEARLY_2_YEARS_23Q4 as string]: priceLabel(1587, 147),
    [STRIPE_PRODUCT_PRO_WEBINAR_24Q1 as string]: priceLabel(997, 147)
    // Elite
    // [STRIPE_PRODUCT_ELITE_MONTHLY as string]: StripePlanTier.ELITE,
    // [STRIPE_PRODUCT_ELITE_MONTHLY_12 as string]: StripePlanTier.ELITE,
    // [STRIPE_PRODUCT_ELITE_YEARLY as string]: StripePlanTier.ELITE,
    // [STRIPE_PRODUCT_ELITE_YEARLY_2_YEARS as string]: StripePlanTier.ELITE,
    // [STRIPE_PRODUCT_ELITE_YEARLY_3_YEARS as string]: StripePlanTier.ELITE
};

export type SusbcriptionPrice = keyof typeof priceToLicense;
