import { Address } from "@onpreo/database";
import { loadable, Loadable } from "@onpreo/slices";
import type { ListTreeResponse, FetchMessageObject } from "imapflow";
import type { ParsedMail } from "mailparser";

export type Mailboxes = ListTreeResponse;
export type Envelope = Pick<FetchMessageObject, "envelope" | "uid" | "seq"> & { flags: string[] };
export type Message = { parsed: ParsedMail; flags: string[]; reference?: { _id: string; address: Address } } & Pick<FetchMessageObject, "uid" | "envelope">;
export type Mail = Partial<Envelope> & { reference?: Message["reference"]; uid: number; parsed?: Loadable<ParsedMail> };
export type { ParsedMail };

// NOTE: Hardcopied from immo-runner/mailing/secret
export type MailSecret = {
    email: string;
    password: string;
    imap: {
        port: string;
        server: string;
    };
    smtp: {
        port: string;
        server: string;
    };
};

// NOTE: Hardcopied from runner/mailing/controllers
export type BulkOperationBody =
    | {
          mailbox: string;
          ids: number[];
      }
    | {
          mailbox: string;
          uids: string[];
      };

// NOTE: This has to be kept in sync to the definition in immo-runner/ controllers by "hand"
export type Attachment = {
    filename: string;
    content: Buffer;
};
export type SendMail = {
    to: string[];
    subject: string;
    html: string;
    text: string;
    attachments: Attachment[];
    reply?: string;
    forward?: string;
};

export type Envelopes = Envelope[];

export type MailStore = {
    [mailbox: string]: {
        total: Loadable<number>;
        mails: { [uid: string]: Mail };
        pages: {
            [index: number]: Loadable<{
                order: number[];
                start: number;
            }>;
        };
    };
};

export const defaultMailbox: MailStore[string] = {
    total: loadable.uninitialized,
    mails: {},
    pages: {}
};

export type MailState = {
    token?: string;
    boxes: Loadable<Mailboxes>;
    store: MailStore;
};

export const defaultMailState = {
    boxes: loadable.uninitialized,
    store: {}
};
