import { onpreoClient } from "@onpreo/upsy-daisy/client";

const fetchAppointments = async () => {
    const url = "/api/appointments/";
    return await onpreoClient.get(url);
};

const createNewAppointment = async (appointment: any) => {
    const url = "/api/appointments/";
    return await onpreoClient.post(url, { appointment });
};

const updateAppointment = async (appointmentId: string, appointment: any) => {
    const url = `/api/appointments/${appointmentId}`;
    return await onpreoClient.put(url, { appointment });
};

const deleteAppointment = async (appointmentId: string) => {
    const url = `/api/appointments/${appointmentId}`;
    return await onpreoClient.delete(url);
};

const appointmentService = {
    fetchAppointments,
    createNewAppointment,
    updateAppointment,
    deleteAppointment
};

export default appointmentService;
