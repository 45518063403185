import { Range } from "@onpreo/components";

export type MarketingCampaignState = {
    total: number;
    activeRange: any;
    activeQuery: {
        start?: string;
        end?: string;
        ids?: string[]; // campaigns
        tags?: string[];
        locations?: string[];
        categories?: string[];
        constructions?: string[];
        steps?: string[];
    };
    wasChanged: "created" | "deleted" | "updated" | undefined;
};

export const initialMarketingCampaignState = {
    total: -1,
    activeRange: [0, 10] as Range,
    activeQuery: {
        start: "",
        end: "",
        ids: undefined,
        tags: [],
        locations: undefined,
        categories: undefined,
        constructions: undefined,
        steps: undefined
    },
    wasChanged: undefined as "created" | "deleted" | "updated" | undefined
};
