import { Userpilot } from "userpilot";
import { USERPILOT_ACTIVE_TRACKING, USERPILOT_KEY } from "../secrets";
import axios from "axios";

Userpilot.initialize(USERPILOT_KEY);

export const UserPilotEvents = {
    PRICE_FINDER: {
        FIRST_OWN_REAL_ESTATE: "Price Finder - First own real estate"
    },
    ACQUISITION_PIPELINE: {
        CREATED_OWN_DEAL_IN_PIPELINE: "Acquisition Pipeline - Created own deal in the pipeline",
        MOVED_DEAL_FROM_ONE_STAGE_TO_ANOTHER: "Acquisition Pipeline - Moved a deal from one stage to another",
        MOVED_DEAL_FROM_SETTING_PIPELINE_TO_CLOSING: "Acquisition Pipeline - Moved a deal from Setting Pipeline to Closing Pipeline",
        ACTIVITY_MANUALLY_CREATED_AND_COMPLETED: "Acquisition Pipeline - An activity manually created & completed",
        EDITED_REAL_ESTATE_INFORMATION: "Acquisition Pipeline - Edited real estate information",
        ENABLE_SETTING_PIPELINES_EMAILS: "Acquisition Pipeline - Enable Setting Pipelines Emails",
        ACTIVATE_SEGMENTATION_EMAILS: "Acquisition Pipeline - Activate segmentation emails",
        DEACTIVATE_SEGMENTATION_EMAIL_FOR_A_USER: "Acquisition Pipeline - Deactivate segmentation email for a user"
    },
    ONE_CLICK_DEAL: {
        SEND_DEAL_MAIL: "One Click Deal - Send Deal Email", // add to userpilot, tracked
        FIRST_ORDER: "One Click Deal - First real order or won with CS in call"
    },
    GUEST_DASHBOARD: {
        SEND_INVITE_MAIL: "Guest Dashboard - Send invite to guest dashboard", // add to userpilot, tracked
        SETUP_BROKER_PROFILE: "Guest Dashboard - Set up broker profile",
        FIRST_ORDER: "Guest Dashboard - First real order or won with CS in call"
    }
};

const UserPilotActivationPoint = {
    // Aha Moment
    [UserPilotEvents.PRICE_FINDER.FIRST_OWN_REAL_ESTATE]: 1, //tracked
    // Key Feature Activation Acquisition
    [UserPilotEvents.ACQUISITION_PIPELINE.CREATED_OWN_DEAL_IN_PIPELINE]: 1, // tracked
    [UserPilotEvents.ACQUISITION_PIPELINE.MOVED_DEAL_FROM_ONE_STAGE_TO_ANOTHER]: 1, // tracked
    [UserPilotEvents.ACQUISITION_PIPELINE.MOVED_DEAL_FROM_SETTING_PIPELINE_TO_CLOSING]: 1, // tracked
    [UserPilotEvents.ACQUISITION_PIPELINE.ACTIVITY_MANUALLY_CREATED_AND_COMPLETED]: 1, // tracked
    [UserPilotEvents.ACQUISITION_PIPELINE.EDITED_REAL_ESTATE_INFORMATION]: 1, // tracked
    [UserPilotEvents.ACQUISITION_PIPELINE.ENABLE_SETTING_PIPELINES_EMAILS]: 1, // tracked
    [UserPilotEvents.ACQUISITION_PIPELINE.ACTIVATE_SEGMENTATION_EMAILS]: 1, // tracked
    [UserPilotEvents.ACQUISITION_PIPELINE.DEACTIVATE_SEGMENTATION_EMAIL_FOR_A_USER]: 1, // tracked
    // Key Feature Activation One Click Deal
    [UserPilotEvents.ONE_CLICK_DEAL.SEND_DEAL_MAIL]: 1, // tracked
    [UserPilotEvents.ONE_CLICK_DEAL.FIRST_ORDER]: 1,
    // Key Feature Activation Guest Dashboard
    [UserPilotEvents.GUEST_DASHBOARD.SEND_INVITE_MAIL]: 1, // tracked
    [UserPilotEvents.GUEST_DASHBOARD.SETUP_BROKER_PROFILE]: 1, // tracked
    [UserPilotEvents.GUEST_DASHBOARD.FIRST_ORDER]: 1
};

export type UserPilotEvent = string;

export const trackUsage = async (event: string) => {
    const response = await axios.post(`/api/user/${"0"}/usage`, { event });
    return response.data.eventStats;
};

export const trackUserPilotActivation = async (event: UserPilotEvent) => {
    try {
        // save occurence in user doc for convenience and checks
        const eventStats = await trackUsage(event);
        // check if activation point threshold is reached
        // if (eventStats.occurences === UserPilotActivationPoint[event]) {
        // send event to user pilot
        if (USERPILOT_ACTIVE_TRACKING) Userpilot.track(event);
        // track event in pipedrive
        // tbd
        // }
    } catch (err) {
        // do not interrupt whatever is running outside of this. We will rather skip tracking than compromise the app
        console.log(err);
    }
};

// if (currentContact.mailSubscription?.segmentation === true && updatedContact.mailSubscription?.segmentation === false)
//     await trackUserPilotActivation(user, UserPilotEvents.ACQUISITION_PIPELINE.DEACTIVATE_SEGMENTATION_EMAIL_FOR_A_USER);
