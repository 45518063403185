import { createApi } from "@reduxjs/toolkit/query/react";
import { onpreoClientBaseQuery } from "../utils";

const marketingMailsApi = createApi({
    reducerPath: "marketingMails",
    baseQuery: onpreoClientBaseQuery({ baseUrl: `/api/contacts/` }),
    tagTypes: ["Done", "NotDone", "NoMessages"],
    endpoints: build => ({
        getMarketingMails: build.query({
            query: ({ id }) =>
                id
                    ? {
                          url: `${id}/marketing/messages`,
                          method: "GET"
                      }
                    : undefined
            // providesTags: result => (result?.type === "all" ? ["Done"] : [result.type])
        })
    })
});

export const { useGetMarketingMailsQuery } = marketingMailsApi;
export default marketingMailsApi;
