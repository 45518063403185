import { Address } from "@onpreo/database";
import { loadable, Loadable } from "@onpreo/slices";
import { Mailboxes, MessageListEntry, Model8, Model4, PageMessages, SubmitMessage } from "src/utils/email-engine/api";

// TODO: we should probably not depend on these types (rather have them from the email engine api directly)
import type { ListTreeResponse, FetchMessageObject } from "imapflow";
import type { ParsedMail } from "mailparser";
import { MailingAccount } from "src/api/mailing/types";

// export type Envelope = Pick<FetchMessageObject, "envelope" | "uid" | "seq"> & { flags: string[] };
// export type Message = { parsed: ParsedMail; flags: string[]; reference?: { _id: string; address: Address } } & Pick<FetchMessageObject, "uid" | "envelope">;
// export type Mail = Partial<Envelope> & { reference?: Message["reference"]; uid: number; parsed?: Loadable<ParsedMail> };

// export type { ParsedMail };
export type Attachment = Model4;
export type Envelope = MessageListEntry;
export type Mail = Model8;
export type SendMail = SubmitMessage;

// NOTE: Hardcopied from runner/mailing/controllers
export type BulkOperationBody =
    | {
          mailbox: string;
          ids: number[];
      }
    | {
          mailbox: string;
          uids: string[];
      };

export type MailStore = {
    mails: { [id: string]: Loadable<Mail> };
    envelopes: { [id: string]: Envelope };
    references: { [id: string]: Loadable<{ _id: string; address: Address }> };
    pages: {
        [mailbox: string]: {
            [index: number]: Loadable<{
                order: string[];
                page: number;
            }>;
        };
    };
};

export const defaultMailbox: MailStore["pages"][string] = {};

export type MailingState = {
    account: Loadable<MailingAccount>;
    boxes: Loadable<Mailboxes>;
    store: MailStore;
};

export const defaultMailingState = {
    account: loadable.uninitialized,
    boxes: loadable.uninitialized,
    store: {
        envelopes: {},
        mails: {},
        pages: {},
        references: {}
    }
};
