import axios from "axios";
import { LoadOptions } from "@onpreo/components";

const fetchPriceAssistants = async () => {
    const url = `/api/price-assistant`;
    return await axios.get(url);
};

const loadPriceAssistantsByRange = async (load: LoadOptions) => {
    const url = "/api/price-assistant/by-range";
    return await axios.post(url, load);
};

const getPriceAssistantById = async (id: string | number) => {
    const url = `/api/price-assistant/${id}`;
    return await axios.get(url);
};

const createNewPriceAssistant = async (load: LoadOptions, priceAssistant) => {
    const url = "/api/price-assistant";
    return await axios.post(url, { load, priceAssistant });
};

const updatePriceAssistant = async (id: string | number, priceAssistant: any) => {
    const url = `/api/price-assistant/${id}`;
    return await axios.put(url, { updated: priceAssistant });
};

const deletePriceAssistant = async (paId: string, load: LoadOptions) => {
    const url = `/api/price-assistant/${paId}`;
    return await axios.delete(url, { data: { load } });
};

const updateRealEstatesInPA = async (id: string | number, reId: string | number) => {
    const url = `/api/price-assistant/${id}/${reId}`;
    return await axios.patch(url);
};

const priceAssistantService = {
    fetchPriceAssistants,
    loadPriceAssistantsByRange,
    getPriceAssistantById,
    createNewPriceAssistant,
    updatePriceAssistant,
    deletePriceAssistant,
    updateRealEstatesInPA
};

export default priceAssistantService;
