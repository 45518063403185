import { PipelineDocument } from "@onpreo/database";

export type PipelinesState = {
    pipelines: PipelineDocument[];
    selectList: any[];
    accessed: string[];
    names: string[];
};
export const initialPipelinesState = {
    pipelines: [],
    selectList: [],
    accessed: [],
    names: []
};
