import { ActivityDocument } from "@onpreo/database";
import { Range } from "@onpreo/components";

export type ActivitiesArray = ActivityDocument[];

export type ActivitiesState = {
    activitiesByRange: ActivitiesArray;
    activitiesByPeriod: ActivitiesArray;
    type: "loaded" | "loading" | "error";
    total: Number;
    activeRange: any;
    activeSort: any;
    period: { start: string; end: string };
    activeQuery: {
        responsibleUser?: any;
        start?: string;
        end?: string;
        ownerType?: string;
        types?: Array<string>;
        done?: boolean;
        notDone?: boolean;
        overdue?: boolean;
        startDoneDate?: string;
        endDoneDate?: string;
    };
};
export const initialActivitiesState = {
    activitiesByRange: [] as ActivitiesArray,
    activitiesByPeriod: [] as ActivitiesArray,
    type: "loading" as "loaded" | "loading" | "error",
    total: -1,
    activeRange: undefined as Range | undefined,
    activeSort: null,
    period: null,
    activeQuery: {
        responsibleUser: null,
        start: "",
        end: "",
        ownerType: "alle",
        types: ["call", "meeting", "task", "note"],
        done: false,
        notDone: true,
        overdue: true,
        startDoneDate: "",
        endDoneDate: ""
    }
};
