//! Client side logic of email credentials

export type BufferJSON = ReturnType<Buffer["toJSON"]>;
export type MailKey = { key: JsonWebKey; iv: BufferJSON; uuid: string; email: string };
export const mailLocalStorageKey = (userId: string) => "mail-config-" + userId;

export const mailCredentialsClient = {
    retrieveKey: (userId): string => {
        const item = localStorage.getItem(mailLocalStorageKey(userId));
        return item;
    },

    /**
     * Returns encrypted payload together with the key as a string and an identifying UUID
     *
     * The whole implementation is loosely based on https://github.com/mdn/dom-examples/blob/main/web-crypto/encrypt-decrypt/aes-gcm.js
     *
     * @returns [encrypted payload, key, iv, uuid]
     */
    async encrypt(payload: any): Promise<[BufferJSON, JsonWebKey, BufferJSON, string]> {
        if (typeof crypto === "undefined") throw new Error("[credentials] unsupported browser");

        const uuid = crypto.randomUUID();
        const enc = encode(payload);

        const key = await generateKey();
        const iv = crypto.getRandomValues(new Uint8Array(12));

        const encrypted = await crypto.subtle?.encrypt({ name: "AES-GCM", iv }, key, enc);

        // prepare the key for export
        // https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/exportKey
        const exportable = await crypto.subtle?.exportKey("jwk", key);
        return [Buffer.from(encrypted).toJSON(), exportable, Buffer.from(iv).toJSON(), uuid];
    }
};

// UTILITY Functions here

const generateKey = async () => window.crypto.subtle?.generateKey({ name: "AES-GCM", length: 256 }, true, ["encrypt", "decrypt"]);

const encode = (value: any) => {
    const stringified = JSON.stringify(value);
    const enc = new TextEncoder();
    return enc.encode(stringified);
};
