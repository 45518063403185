import { Address } from "@onpreo/database";
import { isNone, isSome } from "@onpreo/upsy-daisy";
import { onpreoClient } from "@onpreo/upsy-daisy/client";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { useHasToReauthenticate } from "pages/mailing/create";
import { MailingAccount } from "src/api/mailing/types";
import { EmailEngineApi } from "src/utils/email-engine/api";
import { PROTOCOL, EE_URL } from "src/utils/secrets";

// this is a rough matching to the email engine api

const BASE_URL = `${PROTOCOL}${EE_URL}`;
const storageKey = "email-engine-token";

type SecurityData = { token: string };
type Api = EmailEngineApi<SecurityData>;

const auth = async () => onpreoClient.get<MailingAccount>(`/api/email-engine/auth`, {});

// const access = async (): Promise<{ token: string; account: string }> => {
// 	const lookup = localStorage.getItem(storageKey);
// 	if (isSome(lookup)) {
// 		return JSON.parse(lookup);
// 	} else {
// 		const { data } = await auth();
// 		localStorage.setItem(storageKey, JSON.stringify(data));
// 		return data;
// 	}
// };

export const emailClient = new EmailEngineApi<SecurityData>({
    baseURL: BASE_URL,
    securityWorker: data => {
        if (isSome(data)) return { headers: { Authorization: `Bearer ${data.token}` } };
    }
});
export type EmailClient = Api;

export const mailingService = {
    auth,
    outlook: {
        get: () => onpreoClient.get<{ url: string }>(`/api/email-engine/outlook`),
        post: (body: any) => onpreoClient.post(`/api/email-engine/outlook`, body),
        delete: () => onpreoClient.delete<MailingAccount>(`/api/email-engine/outlook`)
    },
    google: {
        get: () => onpreoClient.get<{ url: string }>(`/api/email-engine/google`),
        post: (body: any) => onpreoClient.post(`/api/email-engine/google`, body),
        delete: () => onpreoClient.delete<MailingAccount>(`/api/email-engine/google`)
    },
    imap: {
        post: (body: any) => onpreoClient.post(`/api/email-engine/imap`, body),
        delete: () => onpreoClient.delete<MailingAccount>(`/api/email-engine/imap`)
    },
    migration: {
        post: (headers: any) => onpreoClient.post(`/api/email-engine/migration`, {}, { headers })
    },
    references: {
        get: (message: string) =>
            onpreoClient.get<{ _id: string; address: Address } | undefined>(`/api/email-engine/references?message=${encodeURIComponent(message)}`)
    },
    ai: {
        post: (mail: string, variant: string) => onpreoClient.post(`/api/email-engine/ai`, { mail, variant }),
        put: (mail: string) => onpreoClient.put(`/api/email-engine/ai`, { mail })
    }
};

// const request = async <T, R = AxiosResponse<T>, D = any>(config: ((account: string) => AxiosRequestConfig<D>) | AxiosRequestConfig<D>) => {
// 	const { token, account } = await access();
// 	const accessHeaders = { Authorization: `Bearer ${token}` };

// 	const baseConfig = typeof config === "function" ? config(account) : config;
// 	const response = await axios.request<T, R, D>({ ...baseConfig, headers: { ...baseConfig.headers, ...accessHeaders }, baseURL: BASE_URL });
// 	// TODO: Handle invalid token
// 	return response;
// };

// const mailingService = {
// 	auth,
// 	mailboxes: {
// 		async get() {
// 			const api = new EmailEngineApi();

// 			return await request<{ mailboxes: any[] }>(account => ({
// 				method: "GET",
// 				url: `/account/${account}/mailboxes`
// 			}));
// 		}
// 	}
// };

// export { mailingService };
