import { createAsyncThunk } from "@reduxjs/toolkit";
import pipelinesService from "../services/pipeline.service";
import { setPipelines, setSelectList, setNewPipeline, removePipeline, setAccessed, setPipelineNames } from "../slices/pipeline.slice";
import { enqueueSnackbar, createNotification } from "@onpreo/slices";

export const $getWorkspacePipelines = createAsyncThunk("pipeline/$getWorkspacePipelines", async (_, thunkAPI) => {
    const response = await pipelinesService.getWorkspacePipelines();
    thunkAPI.dispatch(setPipelines(response.data.pipelines));
    thunkAPI.dispatch(setSelectList(response.data.selectList));
    thunkAPI.dispatch(setAccessed(response.data.accessed));
    thunkAPI.dispatch(setPipelineNames(response.data.pipelineNames));
    return response.data;
});

export const $createPipeline = createAsyncThunk(
    "pipeline/$createPipeline",
    async ({ newPipeline, subcategories }: { newPipeline: any; subcategories?: [] | undefined }, thunkAPI) => {
        const response = await pipelinesService.createPipeline({ newPipeline, subcategories });
        thunkAPI.dispatch(setNewPipeline(response.data.pipeline));
        thunkAPI.dispatch(setAccessed(response.data.accessed));
        thunkAPI.dispatch(setSelectList(response.data.selectList));
        thunkAPI.dispatch(setPipelineNames(response.data.pipelineNames));
        thunkAPI.dispatch(enqueueSnackbar(createNotification("Daten erfolgreich gespeichert", "success")));
        return response.data;
    }
);

export const $updatePipeline = createAsyncThunk(
    "pipeline/$updatePipeline",
    async ({ id, pipeline, sync = false, stepsToMove = undefined }: { id: string; pipeline: any; sync: boolean; stepsToMove?: any }, thunkAPI) => {
        const response = await pipelinesService.updatePipeline({ id, pipeline, sync, stepsToMove });
        thunkAPI.dispatch(setNewPipeline(response.data.pipeline));
        thunkAPI.dispatch(enqueueSnackbar(createNotification("Daten erfolgreich gespeichert", "success")));
        return response.data;
    }
);

export const $updateShowPipeline = createAsyncThunk("pipeline/$updateShowPipeline", async ({ id }: { id: string }, thunkAPI) => {
    const response = await pipelinesService.updateShowPipeline(id);
    thunkAPI.dispatch(setNewPipeline(response.data.pipeline));
    thunkAPI.dispatch(enqueueSnackbar(createNotification("Daten erfolgreich gespeichert", "success")));
    return response.data;
});

export const $deletePipeline = createAsyncThunk("pipeline/$deletePipeline", async ({ id, stepsToMove }: { id: string; stepsToMove: any }, thunkAPI) => {
    const response = await pipelinesService.deletePipeline({ id, stepsToMove });
    thunkAPI.dispatch(removePipeline(id));
    thunkAPI.dispatch(setAccessed(response.data.accessed));
    thunkAPI.dispatch(setSelectList(response.data.selectList));
    thunkAPI.dispatch(setPipelineNames(response.data.pipelineNames));
    thunkAPI.dispatch(enqueueSnackbar(createNotification("Daten erfolgreich gespeichert", "success")));
    return response.data;
});

export const $updateStepName = createAsyncThunk(
    "pipeline/$updateStepName",
    async ({ id, progress, stepValue }: { id: string; progress: string; stepValue: string }, thunkAPI) => {
        const response = await pipelinesService.updateStepName({ id, progress, stepValue });
        thunkAPI.dispatch(setNewPipeline(response.data.pipeline));
        thunkAPI.dispatch(enqueueSnackbar(createNotification("Daten erfolgreich gespeichert", "success")));
        return response.data;
    }
);
