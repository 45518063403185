import { onpreoClient } from "@onpreo/upsy-daisy/client";

const getWorkspacePipelines = async () => {
    const url = "/api/pipeline";
    return await onpreoClient.get(url);
};

const createPipeline = async ({ newPipeline, subcategories }) => {
    const url = `/api/pipeline`;
    return await onpreoClient.post(url, { newPipeline, subcategories });
};

const updatePipeline = async ({ id, pipeline, sync, stepsToMove }) => {
    const url = `/api/pipeline/${id}`;
    return await onpreoClient.put(url, { pipeline, sync, stepsToMove });
};

const updateShowPipeline = async id => {
    const url = `/api/pipeline/${id}`;
    return await onpreoClient.patch(url);
};

const deletePipeline = async ({ id, stepsToMove }) => {
    const url = `/api/pipeline/${id}`;
    return await onpreoClient.post(url, { stepsToMove });
};

const updateStepName = async ({ id, progress, stepValue }) => {
    const url = `/api/pipeline/${id}/step-name`;
    return await onpreoClient.patch(url, { progress, stepValue });
};

const pipelinesService = {
    getWorkspacePipelines,
    createPipeline,
    updatePipeline,
    updateShowPipeline,
    deletePipeline,
    updateStepName
};

export default pipelinesService;
