import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dispatch } from "redux";
import priceAssistantService from "../services/price-assistant.service";
import {
    setType,
    setTotal,
    setActiveSort,
    setCurrentPriceAssistant,
    setPriceAssistantByRange,
    setPriceAssistantsByWorkspace,
    setPriceAssistantById
} from "../slices/price-assistant.slice";
import { enqueueSnackbar, createFailureNotification, createNotification } from "@onpreo/slices";
import { LoadOptions, SaveFormThunkArgs } from "@onpreo/components";
import { PriceAssistantDocument } from "@onpreo/database";

export const $fetchPriceAssistants = createAsyncThunk("price-assistant/$fetchPriceAssistants", async (dispatch: Dispatch<any>, thunkAPI) => {
    try {
        const response = await priceAssistantService.fetchPriceAssistants();
        dispatch(setPriceAssistantsByWorkspace(response.data.priceAssistants));
        return response.data;
    } catch (err) {
        console.log(err);
        thunkAPI.dispatch(enqueueSnackbar(createFailureNotification()));
    }
});

export const $loadPriceAssistants = createAsyncThunk("price-assistant/$loadPriceAssistants", async (load: LoadOptions, thunkAPI) => {
    thunkAPI.dispatch(setType("loading"));
    try {
        const response = await priceAssistantService.loadPriceAssistantsByRange(load);
        const { total, priceAssistants } = response.data;

        thunkAPI.dispatch(setCurrentPriceAssistant(null));
        thunkAPI.dispatch(setPriceAssistantByRange({ range: load.range, priceAssistantByRange: priceAssistants }));
        thunkAPI.dispatch(setTotal(total));
        thunkAPI.dispatch(setActiveSort(load.sort));
    } catch (err) {
        thunkAPI.dispatch(setType("error"));
    }
});

export const $getPriceAssistantById = createAsyncThunk("price-assistant/$getPriceAssistantById", async (id: string | number, thunkAPI) => {
    try {
        const response = await priceAssistantService.getPriceAssistantById(id);
        const { priceAssistant } = response.data;
        thunkAPI.dispatch(setCurrentPriceAssistant(priceAssistant));

        return response.data;
    } catch (err) {
        console.log(err);
        thunkAPI.dispatch(enqueueSnackbar(createFailureNotification()));
    }
});

interface createNewPriceAssistantThunk {
    load: LoadOptions;
    priceAssistant: PriceAssistantDocument;
}

export const $createNewPA = createAsyncThunk("price-assistant/$createNewPA", async ({ load, priceAssistant }: createNewPriceAssistantThunk, thunkAPI) => {
    try {
        const response = await priceAssistantService.createNewPriceAssistant(load, priceAssistant);

        const { total, priceAssistants } = response.data;

        thunkAPI.dispatch(setPriceAssistantByRange({ range: load.range, priceAssistantByRange: priceAssistants }));
        thunkAPI.dispatch(setTotal(total));

        return response.data;
    } catch (err) {
        console.log(err);
        thunkAPI.dispatch(enqueueSnackbar(createFailureNotification()));
    }
});

interface UpdatePriceAssistantThunk {
    id: string | number;
    value: any;
}

export const $updatePriceAssistant = createAsyncThunk(
    "price-assistant/$updatePriceAssistant",
    async ({ id, value }: UpdatePriceAssistantThunk, thunkAPI) => {
        try {
            const response = await priceAssistantService.updatePriceAssistant(id, value);
            const { priceAssistant } = response.data;
            thunkAPI.dispatch(setCurrentPriceAssistant(priceAssistant));
            thunkAPI.dispatch(setPriceAssistantById({ id, value: priceAssistant }));

            return response.data;
        } catch (err) {
            console.log(err);
            thunkAPI.dispatch(enqueueSnackbar(createFailureNotification()));
        }
    }
);

interface deletePriceAssistantThunk {
    paId: string;
    load: LoadOptions;
}

export const $deletePriceAssistant = createAsyncThunk(
    "price-assistant/$deletePriceAssistant",
    async ({ paId, load }: deletePriceAssistantThunk, thunkAPI) => {
        try {
            const response = await priceAssistantService.deletePriceAssistant(paId, load);

            const { total, priceAssistants } = response.data;
            thunkAPI.dispatch(setPriceAssistantByRange({ range: load.range, priceAssistantByRange: priceAssistants }));
            thunkAPI.dispatch(setTotal(total));

            return response.data;
        } catch (err) {
            console.log(err);
            thunkAPI.dispatch(enqueueSnackbar(createFailureNotification()));
        }
    }
);

interface UpdateRealEstatesInPAThunk {
    id: string | number;
    reId: string | number;
}

export const $updateRealEstatesInPA = createAsyncThunk(
    "price-assistant/$updateRealEstatesInPA",
    async ({ id, reId }: UpdateRealEstatesInPAThunk, thunkAPI) => {
        try {
            const response = await priceAssistantService.updateRealEstatesInPA(id, reId);
            return response.data;
        } catch (err) {
            console.log(err);
            thunkAPI.dispatch(enqueueSnackbar(createFailureNotification()));
        }
    }
);
