import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialReduxState } from "../models";
import { BrokerAppointmentState } from "../models/appointment.state";

export const appointmentSlice = createSlice({
    name: "appointment",
    initialState: initialReduxState.appointment,
    reducers: {
        setBrokerAppointment: (state, action: PayloadAction<BrokerAppointmentState>) => {
            // @ts-ignore
            state = action.payload;
            return state;
        }
    }
});

export const { setBrokerAppointment } = appointmentSlice.actions;

export default appointmentSlice.reducer;
