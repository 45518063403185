import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialReduxState } from "../models";
import { WorkspaceState } from "../models/workspace.state";
import { StripeBilling } from "@onpreo/database";
import { Invoice, PaymentMethod, SubscriptionInfo } from "../../api/util/stripe/interfaces";

export const workspaceSlice = createSlice({
    name: "workspace",
    initialState: initialReduxState.workspace,
    reducers: {
        setWorkspace: (state, action: PayloadAction<WorkspaceState>) => {
            // @ts-ignore
            state = { ...state, ...action.payload };
            return state;
        },
        setBilling: (state, action: PayloadAction<{ billing: StripeBilling }>) => {
            state.billing = action.payload.billing;
            return state;
        },
        setSubscription: (state, action: PayloadAction<{ sub: SubscriptionInfo }>) => {
            state.billing.subscriptionInfo = action.payload.sub;
            return state;
        },
        setDefaultPaymentMethod: (state, action: PayloadAction<{ card: string }>) => {
            state.billing.defaultPaymentMethod = action.payload.card;
            return state;
        },
        setCards: (state, action: PayloadAction<{ cards: PaymentMethod[] }>) => {
            state.billing.paymentMethods = action.payload.cards;
            return state;
        },
        setInvoices: (state, action: PayloadAction<{ invoices: Invoice[] }>) => {
            state.billing.invoices = action.payload.invoices;
            return state;
        },
        setCompanyInfoData: (state, action) => {
            state.companyInfo = { ...state.companyInfo, ...action.payload };
            if (action.payload.profilePhone) {
                state.adminUser.meta.phone = action.payload.profilePhone;
            }
            if (action.payload.userName) {
                state.adminUser.meta.name = action.payload.userName;
            }
            if (action.payload.provisionRate) {
                state.agentSettings = action.payload.provisionRate;
            }
            return state;
        },
        setWorkspaceValuationCount: (state, action: PayloadAction<{ count: number }>) => {
            state.valuation.count = action.payload.count;
            return state;
        },
        setWorkspaceLeadConnect: (state, action: PayloadAction<{ leadConnect: WorkspaceState["integrations"]["leadConnect"] }>) => {
            state.integrations.leadConnect = { ...state.integrations.leadConnect, ...action.payload };
            return state;
        }
    }
});

export const {
    setWorkspaceValuationCount,
    setWorkspace,
    setBilling,
    setSubscription,
    setDefaultPaymentMethod,
    setCards,
    setInvoices,
    setCompanyInfoData,
    setWorkspaceLeadConnect
} = workspaceSlice.actions;

export default workspaceSlice.reducer;
