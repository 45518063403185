import { AsyncThunk, AsyncThunkOptions, AsyncThunkPayloadCreator, configureStore, createAsyncThunk } from "@reduxjs/toolkit";
import workspaceReducer from "./slices/workspace.slice";
import userReducer from "./slices/user.slice";
import realEstateReducer from "./slices/real-estate.slice";
import { initialReduxState } from "./models";
import contactReducer from "./slices/contact.slice";
import noteReducer from "./slices/note.slice";
import appointmentReducer from "./slices/appointment.slice";
import taskReducer from "./slices/task.slice";
import mailingReducer from "./slices/mailing.slice";
import priceAssistantReducer from "./slices/price-assistant.slice";
import { useDispatch } from "react-redux";
import { sharedReducer, snackbarReducer } from "@onpreo/slices";
import { injectStore } from "@onpreo/upsy-daisy/client";
import activityApi from "./slices/activity.slice";
import { locationApi } from "./slices/location.slice";
import marketingMailsApi from "./slices/mail-messages.slice";
import activitiesReducer from "./slices/activities.slice";
import buyerPreferenceReducer from "./slices/buyer-preference.slice";
import mailTemplateReducer from "./slices/mail-template.slice";
import locationsReducer from "./slices/locations.slice";
import activityTemplateReducer from "./slices/activity-template.slice";
import pipelineReducer from "./slices/pipeline.slice";
import marketingCampaignApi from "./slices/marketing-campaign.slice";
import marketingCampaignReducer from "./slices/marketing-campaign-actions.slice";
import automationReducer from "./slices/automation.slice";
import salesStatisticsApi from "./slices/sales-statistics.slice";

let store: ReturnType<typeof createStore>;

const createStore = (preloadedState: any) => {
    return configureStore({
        preloadedState: preloadedState ?? initialReduxState,
        reducer: {
            user: userReducer,
            workspace: workspaceReducer,
            realEstate: realEstateReducer,
            contact: contactReducer,
            // TODO: deprecated? --> start
            appointment: appointmentReducer,
            note: noteReducer,
            task: taskReducer,
            // <--- end
            [locationApi.reducerPath]: locationApi.reducer,
            // mail: mailReducer,
            mailing: mailingReducer,
            priceAssistant: priceAssistantReducer,
            [activityApi.reducerPath]: activityApi.reducer,
            [marketingCampaignApi.reducerPath]: marketingCampaignApi.reducer,
            activities: activitiesReducer,
            marketingCampaigns: marketingCampaignReducer,
            buyerPreference: buyerPreferenceReducer,
            mailTemplate: mailTemplateReducer,
            locations: locationsReducer,
            activityTemplate: activityTemplateReducer,
            pipelines: pipelineReducer,
            automation: automationReducer,
            [marketingMailsApi.reducerPath]: marketingMailsApi.reducer,
            [salesStatisticsApi.reducerPath]: salesStatisticsApi.reducer,
            ...sharedReducer
        },
        middleware: getDefaultMiddleware =>
            getDefaultMiddleware().concat(activityApi.middleware).concat(locationApi.middleware).concat(marketingMailsApi.middleware)
    });
};

export const initStore = (preloadedState?: any) => {
    let _store = store ?? createStore(preloadedState);
    if (preloadedState && store) {
        _store = createStore({
            ...store.getState(),
            ...preloadedState
        });
        store = undefined;
    }

    // create a new store for SSG and SSR
    if (typeof window === "undefined") return _store;
    // Create the store once in the client
    if (!store) {
        store = _store;
        injectStore(store);
    }

    return _store;
};

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export type AsyncThunkConfig = {
    state: RootState;
    dispatch: AppDispatch;
};
// Using this we get correctly typed thunks
export const createAppAsyncThunk = <Returned extends any, ThunkArg = void>(
    typePrefix: string,
    payloadCreator: AsyncThunkPayloadCreator<Returned, ThunkArg, AsyncThunkConfig>,
    options?: AsyncThunkOptions<ThunkArg, AsyncThunkConfig>
): AsyncThunk<Returned, ThunkArg, AsyncThunkConfig> => createAsyncThunk<Returned, ThunkArg>(typePrefix, payloadCreator, options);
