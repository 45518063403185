import { useEffect } from "react";
import { loadIntercom, shutdownIntercom } from "next-intercom";
import { INTERCOM_APP_ID } from "../../utils/secrets";
import { useReduxSelector } from "../../store/selectors";
import { getUserEmail, getUserName } from "../../store/selectors/user.selectors";

const IntercomHelper = () => {
    const userName = useReduxSelector(getUserName);
    const userEmail = useReduxSelector(getUserEmail);

    useEffect(() => {
        (async () =>
            await loadIntercom({
                appId: INTERCOM_APP_ID,
                email: userEmail, //default: ''
                name: userName, //default: RandomName
                ssr: false, // default: false
                initWindow: true, // default: true
                delay: 0 // default: 0  - usefull for mobile devices to prevent blocking the main thread
            }))();
        // return () => shutdownIntercom();
    }, [userName, userEmail]);
    return <div id={"intercom-helper"} />;
};

export default IntercomHelper;
